import React, { useEffect, useState } from "react";
import Complogo from "../assets/images/GoalsStrategyLogo.svg";
import TopNav from "../components/common/topnav.component";
import { ScrollPanel } from "primereact/scrollpanel";
import ReactEcharts from "echarts-for-react";
import {
  fetchDistrict_Size,
  // fetchOverall_Score,
  fetchNearby_District,
  fetchDemographic, fetchFunding, fetchCost_Per_Student
} from "../redux/slices/statebenchmarking";
import { useDispatch, useSelector } from "react-redux";
import LoaderContainer from "../components/loaderContainer";

export default function StateBenchMarking(props) {
  const dispatch = useDispatch();

  const district_sizedata = useSelector(
    (state) => state.statebenchmarking.District_Size
  );
 
  // const [Overall_Scoredata] = useSelector(
  //   (state) => state.statebenchmarking.Overall_Score
  // ); 
  

  const {Nearby_District,Nearby_Districtloading} = useSelector(state => state.statebenchmarking);
  const {Demographic,Demographicloading} = useSelector(state => state.statebenchmarking);
  const {Funding,Fundingloading} = useSelector(state => state.statebenchmarking);
  const {Cost_Per_Student,Cost_Per_Studentloading} = useSelector(state => state.statebenchmarking);





  const [District_Names,setDistrict_Names] = useState({});
  function extractDistrictNames(state){
    const data = state.reduce((acc,item)=> ({...acc,...item}),{})
    const {DISTRICT1_ENTITY_NAME,DISTRICT2_ENTITY_NAME,DISTRICT3_ENTITY_NAME,DISTRICT4_ENTITY_NAME,DISTRICT5_ENTITY_NAME} = data

    const names = {DISTRICT1_ENTITY_NAME,DISTRICT2_ENTITY_NAME,DISTRICT3_ENTITY_NAME,DISTRICT4_ENTITY_NAME,DISTRICT5_ENTITY_NAME}
    for (let key in names){
      if(names[key]){
        names[key] = names[key].replace(/\b(CSD|SD|UFSD)\b/gi, //select the words matching CSD, SD or UFSD using regx
          (word)=> word.toUpperCase() //word to uppercase
        )
      }
    }

    setDistrict_Names(names)
  }

  useEffect(()=>{
    extractDistrictNames(district_sizedata)
  },[district_sizedata])

  useEffect(()=>{
    extractDistrictNames(Nearby_District)
  },[Nearby_District])

  useEffect(()=>{
    extractDistrictNames(Demographic)
  },[Demographic])

  useEffect(()=>{
    extractDistrictNames(Funding)
  },[Funding])

  useEffect(()=>{
    extractDistrictNames(Cost_Per_Student)
  },[Cost_Per_Student])


  const [activeTab, setActiveTab] = useState(0);
  const [activeTab1, setActiveTab1] = useState(0);

  const handleOuterTabChange = (index) => {
    setActiveTab(index); // Change outer tab
    setActiveTab1(0); // Reset inner tab to 0 when outer tab changes
  };

  // useEffect(() => {
  //     dispatch(
  //       fetchOverall_Score({
  //         elasticQueryName: "Overall Score",
  //         filters: [],
  //         dynamicColumns: [],
  //         userEmail: "Test.PBI@redingtongroup.com",
  //       })
  //     );
  //   },[])

  useEffect(() => {
    if (activeTab == 0) {
      dispatch(
        fetchDistrict_Size({
          elasticQueryName: "DISTRICT_SIZE",
          filters: [],
          dynamicColumns: [],
          userEmail: "Test.PBI@redingtongroup.com",
        })
      );
    } else if (activeTab == 1) {
      dispatch(fetchNearby_District({
        elasticQueryName: "",
        filters: [],
        dynamicColumns: [],
      }))
    } else if (activeTab == 2) {
      dispatch(fetchDemographic({
        elasticQueryName: "",
        filters: [],
        dynamicColumns: [],
      }))
    } else if (activeTab == 3) {
      dispatch(fetchFunding({
        elasticQueryName: "",
        filters: [],
        dynamicColumns: [],
      }))
    } else if (activeTab == 4) {
      dispatch(fetchCost_Per_Student({
        elasticQueryName: "",
        filters: [],
        dynamicColumns: [],
      }))
    } 
  }, [activeTab]);

  // const option = {
  //   xAxis: {
  //     max: 100,
  //     show: false,
  //   },
  //   yAxis: {
  //     type: "category",
  //     show: false,
  //   },
  //   grid: {
  //     height: 30,
  //     left: 0,
  //     right: 0,
  //     top: "30%",
  //   },
  //   series: [
  //     {
  //       type: "bar",
  //       data: [Overall_Scoredata?.PERCENTAGE],
  //       showBackground: true,
  //       backgroundStyle: {
  //         color: "#3e4b68",
  //         borderRadius: 100,
  //       },
  //       itemStyle: {
  //         color: "#657cd7",
  //         borderRadius: 100,
  //       },
  //       barWidth: "100%",
  //       label: {
  //         show: true,
  //         position: "insideleft",
  //         padding: [8, 0, 0, 15],
  //         formatter: "{c}%",
  //         color: "#fff",
  //         fontSize: 16,
  //         // fontWeight: 'bold',
  //       },
  //     },
  //   ],
  // };

  // const combineDatabasedOnIndex = (keys, ...datas) => {
  //   const combinedData = []
   
  //   datas.forEach((data,colno) => {
  //     data.forEach((item,i) => {
  //         keys.forEach(key => {
  //           if(item[key]){
  //             combinedData[i] = {...combinedData[i],[`column_${colno}_${key}`]: item[key]}
  //           }
  //       })
  //     })
  //   })
 
  //   return combinedData;
  // }

  const StatusIndicator = (MetricGroup, cyValue, py_value) =>{
    if (MetricGroup.includes("Chronic Absenteeism %")) {
      if (py_value < cyValue){
        return <div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full my-auto"></div>
      }
  
      return <div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full my-auto"></div>
    }

    if (py_value > cyValue){
      return <div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full my-auto"></div>
    }

    return <div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full my-auto"></div>
  }

  return (
    <>
      <div className="body">
        <TopNav
          pagename="State Benchmarking"
          pagenamedescription=""
          setUserData={props.setUserData}
        />
        
        <div className="px-[30px] 3xl:px-[1.563vw] py-[12px] 2xl:py-[18px] 3xl:py-[0.938vw] relative">
          <ScrollPanel className="h-[calc(100vh-10.5rem)] 2xl:h-[calc(100vh-11.5rem)] benchmarkScroll ">
            {/* <div className=" grid grid-cols-12  gap-[16px] xl:gap-[16px] 3xl:gap-[0.833vw]">
              <div className=" col-span-4  p-[16px] xl:p-[16px] 3xl:p-[0.833vw]  bg-[#192D5C] rounded-[8px]">
                <div className="flex justify-between items-center">
                  <div>
                    <div class="text-[#fff] text-[18px] xl:text-[18px] 3xl:text-[0.938vw] font-medium">
                      Overall Score
                    </div>
                    <div class="text-[#fff] text-[30px] xl:text-[32px] 3xl:text-[1.667vw]">
                      {Overall_Scoredata?.VALUE}
                    </div>
                  </div>
                  <div class="h-[60px] w-[250px] xl:w-[250px] 2xl:w-[20.833vw] 3xl:w-[20.833vw]">
                    <ReactEcharts
                      option={option}
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "60px",
                        padding: "0px",
                        margin: "0px",
                        borderWidth: "0px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div> */}
              <div className="flex w-full justify-between mt-[20px] 2xl:mt-[24px] 3xl:mt-[1.25vw]">
                <div className="flex items-center gap-[16px] 3xl:gap-[0.833vw]">
                  <button
                    className={` ${
                      activeTab === 0
                        ? "bg-[#FFF] text-black"
                        : " bg-[#1C2732] text-[#FFFFFF]"
                    } text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`}
                    onClick={() => handleOuterTabChange(0)}
                  >
                    District Size
                  </button>

                  <button
                    className={` ${
                      activeTab === 1
                        ? "bg-[#FFF] text-black"
                        : " bg-[#1C2732] text-[#FFFFFF]"
                    } text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`}
                    onClick={() => handleOuterTabChange(1)}
                  >
                    Neighboring districts
                  </button>

                  <button
                    className={` ${
                      activeTab === 2
                        ? "bg-[#FFF] text-black"
                        : " bg-[#1C2732] text-[#FFFFFF]"
                    } text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`}
                    onClick={() => handleOuterTabChange(2)}
                  >
                    Demographic
                  </button>

                  <button
                    className={` ${
                      activeTab === 3
                        ? "bg-[#FFF] text-black"
                        : " bg-[#1C2732] text-[#FFFFFF]"
                    } text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`}
                    onClick={() => handleOuterTabChange(3)}
                  >
                    Funding Level
                  </button>

                  <button
                    className={` ${
                      activeTab === 4
                        ? "bg-[#FFF] text-black"
                        : " bg-[#1C2732] text-[#FFFFFF]"
                    } text-[14px] 3xl:text-[0.833vw] font-normal rounded-full px-[14px] 3xl:px-[0.833vw] py-[10px] 3xl:py-[0.625vw]`}
                    onClick={() => handleOuterTabChange(4)}
                  >
                    Cost per Student
                  </button>
                  
                </div>
              </div>
         
            {activeTab == 0 ? (
              <div className=" mt-[25px] 3xl:mt-[1.263vw] mb-5 select-none">
                <div className=" rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[rgba(0,35,64,0.70)] p-[20px] 3xl:p-[1.042vw]">
                  <div className=" space-y-[6px]">
                    <div className="grid grid-cols-8 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[4px] ">
                      <div className=" col-span-1"></div>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          Greece CSD
                        </div>
                      </div>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          State score
                        </div>
                      </div>
                      {Object.entries(District_Names).map(([key,val],i)=>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px] truncate" title={val ? val : null}>
                          {val ? val : `District ${i}`}
                        </div>
                      </div>
                      )}
                      {/* <div class="col-span-1">
                        <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          District 1
                        </div>
                      </div>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          District 2
                        </div>
                      </div>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          District 3
                        </div>
                      </div>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          District 4
                        </div>
                      </div>
                      <div class="col-span-1">
                        <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          District 5
                        </div>
                      </div> */}
                    </div>
                    {district_sizedata?.map((item, index) => (
                      <div className="grid grid-cols-8 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[14px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">
                            {item?.METRIC_GROUP}
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.GREECE_SCORE, item?.GREECE_SCORE_PY)}{item?.GREECE_SCORE}%
                            </div>
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            Rank : {item?.GREECE_RANK}
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            PY Rank : {item?.GREECE_RANK_PY || "-"}
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.STATE_SCORE, item?.STATE_SCORE_PY)}{item?.STATE_SCORE}%
                            </div>
                          </div>
                          {/* <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            PY Rank : {item.PY_VALUE ? "-" : "-"}
                          </div> */}
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT1_SCORE, item?.DISTRICT1_SCORE_PY)}{item?.DISTRICT1_SCORE}%
                            </div>
                          </div>
                          {/* <div
                            title={item?.DISTRICT1_ENTITY_NAME}
                            class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer"
                          >
                            {item?.DISTRICT1_ENTITY_NAME}
                          </div> */}
                          <div className="w-full flex justify-between items-center">
                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                              Rank : &nbsp;{item?.DISTRICT1_RANK || '-'}
                            </div>
                          </div>
                          <div className="w-full flex justify-between items-center">
                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item?.DISTRICT1_RANK_PY || "-"}
                            </div>
                          </div>
                          
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT2_SCORE, item?.DISTRICT2_SCORE_PY)}{item?.DISTRICT2_SCORE}%
                            </div>
                          </div>
                            {/* <div title={item?.DISTRICT2_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT2_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT2_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                    PY Rank : {item?.DISTRICT2_RANK_PY || "-"}
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT3_SCORE, item?.DISTRICT3_SCORE_PY)}{item?.DISTRICT3_SCORE}%
                            </div>
                          </div>
                            {/* <div title={item?.DISTRICT3_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT3_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT3_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                    PY Rank : {item?.DISTRICT3_RANK_PY || "-"}
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT4_SCORE, item?.DISTRICT4_SCORE_PY)}{item?.DISTRICT4_SCORE}%
                            </div>
                          </div>
                            {/* <div title={item?.DISTRICT4_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT4_ENTITY_NAME}
                            </div> */}
                          <div className="w-full flex justify-between items-center">
                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                              Rank : &nbsp;{item?.DISTRICT4_RANK || '-'}
                            </div>
                          </div>
                          <div className="w-full flex justify-between items-center">
                                <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                    PY Rank : {item?.DISTRICT4_RANK_PY || "-"}
                                </div>
                            </div>
                        </div>
                        <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT5_SCORE, item?.DISTRICT5_SCORE_PY)}{item?.DISTRICT5_SCORE}%
                            </div>
                          </div>
                            {/* <div title={item?.DISTRICT5_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT5_ENTITY_NAME}
                            </div> */}
                          <div className="w-full flex justify-between items-center">
                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                              Rank : &nbsp;{item?.DISTRICT5_RANK || '-'}
                            </div>
                          </div>
                          <div className="w-full flex justify-between items-center">
                                <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                    PY Rank : {item?.DISTRICT5_RANK_PY || "-"}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-span-2 relative">
                                            <div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div className="absolute top-0 right-0 cursor-pointer">
                                               <i className="pi pi-chevron-circle-down text-white"></i>
                                            </div>
                                        </div> */}
                      </div>
                    ))}
                    {/* <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Student Growth</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Learing game in ELA and Math performw</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div className=" col-span-2 relative">
                                            <div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">5</div></div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div>
                                            <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div className=" absolute top-0 right-0 cursor-pointer">
                                            <i className="pi pi-chevron-circle-down text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Graduation</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">A graduas on rate of 80% is benchmark of sta</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">English Proficiency</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Florice targeting 70% proñcience</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#069564] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">8</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div>
                                            <div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Attendance</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Reduction of Chrome absenteeism to less than 10%</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#D74210] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">6</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div><div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div>
                                    <div className=" grid grid-cols-12 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                                        <div class="col-span-2"><div class="text-[#fff] text-[18px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">Advanced Coursework</div><div class="text-[#C9D0DB] text-[14px] 3xl:text-[0.833vw] font-normal leading-[140%]">Encourage more students to take AP, IB tests</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div></div>
                                        <div class="col-span-2 relative"><div class="flex items-center gap-[6px]"><div class="bg-[#F2990E] h-[12px] 3xl:h-[0.625vw] w-[12px] 3xl:w-[0.625vw] rounded-full mt-[2px]"></div><div class="text-[#fff] text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] ">7</div></div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">Rank : 14</div><div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">PY : 7</div><div class="absolute top-0 right-0 cursor-pointer"><i className="pi pi-chevron-circle-down text-white"></i></div></div>
                                    </div> */}
                  </div>
                </div>
              </div>
            ) : 
              activeTab == 1 ? (
                <LoaderContainer loading={Nearby_Districtloading}>
                <div className=" mt-[25px] 3xl:mt-[1.263vw] mb-5 select-none">
                  <div className=" rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[rgba(0,35,64,0.70)] p-[20px] 3xl:p-[1.042vw]">
                    <div className=" space-y-[6px]">
                      <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[4px] ">
                        <div className=" col-span-1"></div>
                        <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          Greece CSD
                        </div>
                      </div>
                        {Object.entries(District_Names).map(([key,val],i)=>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px] truncate" title={val ? val : null}>
                            {val ? val : `District ${i}`}
                          </div>
                        </div>
                        )}
                        {/* <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 1
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 2
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 3
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 4
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 5
                          </div>
                        </div> */}
                      </div>
                      {Nearby_District?.map((item, index) => (
                        <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                          <div class="col-span-1">
                            <div class="text-[#fff] text-[14px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">
                              {item?.METRIC_GROUP}
                            </div>
                          </div>
                          <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.GREECE_SCORE, item?.GREECE_SCORE_PY)}{item?.GREECE_SCORE}%
                            </div>
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            Rank : {item?.GREECE_RANK}
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            PY Rank : {item?.GREECE_RANK_PY || "-"}
                          </div>
                        </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT1_SCORE, item?.DISTRICT1_SCORE_PY)}{item?.DISTRICT1_SCORE}%
                              </div>
                            </div>
                            {/* <div
                              title={item?.DISTRICT1_ENTITY_NAME}
                              class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer"
                            >
                              {item?.DISTRICT1_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT1_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item?.DISTRICT1_RANK_PY || '-'}
                              </div>
                            </div>

                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT2_SCORE, item?.DISTRICT2_SCORE_PY)}{item?.DISTRICT2_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT2_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT2_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT2_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item?.DISTRICT2_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT3_SCORE, item?.DISTRICT3_SCORE_PY)}{item?.DISTRICT3_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT3_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT3_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT3_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item?.DISTRICT3_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT4_SCORE, item?.DISTRICT4_SCORE_PY)}{item?.DISTRICT4_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT4_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT4_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT4_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item?.DISTRICT4_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT5_SCORE, item?.DISTRICT5_SCORE_PY)}{item?.DISTRICT5_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT5_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT5_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT5_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item?.DISTRICT5_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
                </LoaderContainer>
              ) : 
              activeTab == 2 ? (
                <LoaderContainer loading={Demographicloading}>
                <div className=" mt-[25px] 3xl:mt-[1.263vw] mb-5 select-none">
                  <div className=" rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[rgba(0,35,64,0.70)] p-[20px] 3xl:p-[1.042vw]">
                    <div className=" space-y-[6px]">
                      <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[4px] ">
                        <div className=" col-span-1"></div>
                        <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          Greece CSD
                        </div>
                      </div>
                        {Object.entries(District_Names).map(([key,val],i)=>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px] truncate" title={val ? val : null}>
                            {val ? val : `District ${i}`}
                          </div>
                        </div>
                        )}
                        {/* <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 1
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 2
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 3
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 4
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 5
                          </div>
                        </div> */}
                      </div>
                      {Demographic?.map((item, index) => (
                        <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                          <div class="col-span-1">
                            <div class="text-[#fff] text-[14px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">
                              {item?.METRIC_GROUP}
                            </div>
                          </div>
                          <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.GREECE_SCORE, item?.GREECE_SCORE_PY)}{item?.GREECE_SCORE}%
                            </div>
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            Rank : {item?.GREECE_RANK}
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            PY Rank : {item.GREECE_RANK_PY || "-"}
                          </div>
                        </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT1_SCORE, item?.DISTRICT1_SCORE_PY)}{item?.DISTRICT1_SCORE}%
                              </div>
                            </div>
                            {/* <div
                              title={item?.DISTRICT1_ENTITY_NAME}
                              class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer"
                            >
                              {item?.DISTRICT1_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT1_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT1_RANK_PY || "-"}
                              </div>
                            </div>

                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT2_SCORE, item?.DISTRICT2_SCORE_PY)}{item?.DISTRICT2_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT2_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT2_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT2_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT2_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT3_SCORE, item?.DISTRICT3_SCORE_PY)}{item?.DISTRICT3_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT3_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT3_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT3_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT3_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT4_SCORE, item?.DISTRICT4_SCORE_PY)}{item?.DISTRICT4_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT4_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT4_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT4_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT4_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT5_SCORE, item?.DISTRICT5_SCORE_PY)}{item?.DISTRICT5_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT5_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT5_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT5_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT5_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
                </LoaderContainer>
              ) :
              activeTab == 3 ? (
                <LoaderContainer loading={Fundingloading}>
                <div className=" mt-[25px] 3xl:mt-[1.263vw] mb-5 select-none">
                  <div className=" rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[rgba(0,35,64,0.70)] p-[20px] 3xl:p-[1.042vw]">
                    <div className=" space-y-[6px]">
                      <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[4px] ">
                        <div className=" col-span-1"></div>
                        <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          Greece CSD
                        </div>
                      </div>
                        {Object.entries(District_Names).map(([key,val],i)=>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px] truncate" title={val ? val : null}>
                            {val ? val : `District ${i}`}
                          </div>
                        </div>
                        )}
                        {/* <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 1
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 2
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 3
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 4
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 5
                          </div>
                        </div> */}
                      </div>
                      {Funding?.map((item, index) => (
                        <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                          <div class="col-span-1">
                            <div class="text-[#fff] text-[14px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">
                              {item?.METRIC_GROUP}
                            </div>
                          </div>
                          <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.GREECE_SCORE, item?.GREECE_SCORE_PY)}{item?.GREECE_SCORE}%
                            </div>
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            Rank : {item?.GREECE_RANK}
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            PY Rank : {item.GREECE_RANK_PY || "-"}
                          </div>
                        </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT1_SCORE, item?.DISTRICT1_SCORE_PY)}{item?.DISTRICT1_SCORE}%
                              </div>
                            </div>
                            {/* <div
                              title={item?.DISTRICT1_ENTITY_NAME}
                              class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer"
                            >
                              {item?.DISTRICT1_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT1_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT1_RANK_PY || "-"}
                              </div>
                            </div>

                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT2_SCORE, item?.DISTRICT2_SCORE_PY)}{item?.DISTRICT2_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT2_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT2_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT2_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT2_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT3_SCORE, item?.DISTRICT3_SCORE_PY)}{item?.DISTRICT3_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT3_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT3_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT3_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT3_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT4_SCORE, item?.DISTRICT4_SCORE_PY)}{item?.DISTRICT4_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT4_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT4_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT4_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT4_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT5_SCORE, item?.DISTRICT5_SCORE_PY)}{item?.DISTRICT5_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT5_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT5_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT5_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT5_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
                </LoaderContainer>
              ) :
              activeTab == 4 ? (
                <LoaderContainer loading={Cost_Per_Studentloading}>
                <div className=" mt-[25px] 3xl:mt-[1.263vw] mb-5 select-none">
                  <div className=" rounded-[8px] border border-[rgba(255,255,255,0.10)] bg-[rgba(0,35,64,0.70)] p-[20px] 3xl:p-[1.042vw]">
                    <div className=" space-y-[6px]">
                      <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[4px] ">
                        <div className=" col-span-1"></div>
                        <div class="col-span-1">
                        <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                          Greece CSD
                        </div>
                      </div>
                        {Object.entries(District_Names).map(([key,val],i)=>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[14px] 2xl:text-[18px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px] truncate" title={val ? val : null}>
                            {val ? val : `District ${i}`}
                          </div>
                        </div>
                        )}
                        {/* <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 1
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 2
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 3
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 4
                          </div>
                        </div>
                        <div class="col-span-1">
                          <div class="text-[#fff] text-[20px] 3xl:text-[1.042vw] font-medium leading-[140%] mb-[4px]">
                            District 5
                          </div>
                        </div> */}
                      </div>
                      {Cost_Per_Student?.map((item, index) => (
                        <div className="grid grid-cols-7 gap-[18px] 2xl:gap-[56px] 3xl:gap-[2.917vw] px-[22px] 3xl:px-[1.25vw] py-[12px] 3xl:py-[0.625vw] bg-[#0C1A26]">
                          <div class="col-span-1">
                            <div class="text-[#fff] text-[14px] 3xl:text-[0.938vw] font-medium leading-[140%] mb-[4px]">
                              {item?.METRIC_GROUP}
                            </div>
                          </div>
                          <div class="col-span-1">
                          <div class="flex items-center gap-[6px]">
                            <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                            {StatusIndicator(item?.METRIC_GROUP, item?.GREECE_SCORE, item?.GREECE_SCORE_PY)}{item?.GREECE_SCORE}%
                            </div>
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            Rank : {item?.GREECE_RANK}
                          </div>
                          <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                            PY Rank : {item.GREECE_RANK_PY || "-"}
                          </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT1_SCORE, item?.DISTRICT1_SCORE_PY)}{item?.DISTRICT1_SCORE}%
                              </div>
                            </div>
                            {/* <div
                              title={item?.DISTRICT1_ENTITY_NAME}
                              class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer"
                            >
                              {item?.DISTRICT1_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT1_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT1_RANK_PY || "-"}
                              </div>
                            </div>

                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT2_SCORE, item?.DISTRICT2_SCORE_PY)}{item?.DISTRICT2_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT2_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT2_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT2_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT2_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT3_SCORE, item?.DISTRICT3_SCORE_PY)}{item?.DISTRICT3_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT3_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT3_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT3_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT3_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT4_SCORE, item?.DISTRICT4_SCORE_PY)}{item?.DISTRICT4_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT4_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT4_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT4_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT4_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-1">
                            <div class="flex items-center gap-[6px]">
                              <div class="text-[#fff] text-[14px] 3xl:text-[1.042vw] font-medium leading-[140%] flex justify-around gap-1">
                              {StatusIndicator(item?.METRIC_GROUP, item?.DISTRICT5_SCORE, item?.DISTRICT5_SCORE_PY)}{item?.DISTRICT5_SCORE}%
                              </div>
                            </div>
                            {/* <div title={item?.DISTRICT5_ENTITY_NAME} class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%] truncate cursor-pointer">
                              {item?.DISTRICT5_ENTITY_NAME}
                            </div> */}
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                Rank : &nbsp;{item?.DISTRICT5_RANK || '-'}
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <div class="text-[#C9D0DB] text-[12px] 3xl:text-[0.729vw] font-normal leading-[140%]">
                                PY Rank : {item.DISTRICT5_RANK_PY || "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
                </LoaderContainer>
              ) :
            <></>}
           
          </ScrollPanel>

          <div class="footer flex item-center justify-between p-0 mt-0">
            <div class="flex mt-2 text-white text-[12px] items-center">
              <p><span className="font-bold text-[14px] tracking-wide">Data Source:</span>&nbsp; NYSED, 2022-2023</p>
            </div>
            <div class="mr-3">
              <img
                src={Complogo}
                height="50px"
                width="130px"
                alt="logo"
                className="mr-[2vw]"
              />
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
}
