
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import {useSelector } from "react-redux";
export default function StachedBarChart(props) {
    const DarkMode = useSelector((state) => state.global.DarkMode);
    const CollectionTrend = {
        title: {
            textStyle: {
                fontSize: 12,
                fontWeight: "normal"
            },
        },
        tooltip: {
            trigger: 'axis',
            animation:false,
            backgroundColor: 'rgba(50,50,50,0.7)',
            borderColor: "#333",
            textStyle: {
                fontSize: 12,
                fontWeight: "normal",
                color: '#fff',
            },
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            left: '5%',
            top: '0%',
            show: false
        },
        // toolbox: {
        //     feature: {
        //       dataZoom: {
        //         yAxisIndex: 'none'
        //       },
        //       restore: {},
        //       saveAsImage: {}
        //     }
        //   },
        dataZoom: [
            {
              type: 'slider',
              xAxisIndex: [0, 1],
              realtime: false,
              start: 100,
              end: 60,
              top: 0,
              height: 15,
              handleIcon:
                'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
              handleSize: '80%'
            },
            {
              type: 'inside',
              xAxisIndex: [0, 1],
              start: 0,
              end: 30,
              top: 10,
              height: 15
            }
          ],
        grid: {
            left: '5%',
            right: '5%',
            bottom: '10%',
            top: "15%",
            containLabel: true
        },
        yAxis: [
            {
                type: 'value',
                animation:false,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: DarkMode ? '#fff' : 'black'
                    }
                },
                // min: 0,
                // max: 120,
                // interval: 20,
                axisLabel: {
                    //   formatter: '${value}',
                    show: true,
                    color: DarkMode ? '#fff' : 'black'
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: 'transparent'
                    },
                },
            },

        ],
        xAxis: {
            type: 'category',
            animation:false,
            data: props?.finalData1?.map(itr => itr?.overallvalue),

            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: DarkMode ? '#fff' : 'black'
                }
            },
            axisLabel: {
                color: DarkMode ? '#fff' : 'black'
            },
        },
        series: props.data?.map(item => {
            return {...item,tooltip: {valueFormatter: value=> props.tooltipformatter ? props.tooltipformatter(value) : value}}
        }),
    };

    return (
        <div className="pb-10">    
        <ReactEcharts option={CollectionTrend} />         
        </div>
    )
}