
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import ReactEcharts from "echarts-for-react";
import { Dropdown } from 'primereact/dropdown';
import { Carousel } from 'primereact/carousel';
import { ProductService } from '../service/ProductService';
import 'primeicons/primeicons.css';
import ReactEcharts from "echarts-for-react";
import { Chip } from 'primereact/chip';
import TopNav from "../components/common/topnav.component";
import TreadingarrowDown from '../assets/images/treading-arrow-down.png';
import TreadingarrowUp from '../assets/images/treading-arrow-up.png';
import Complogo from '../assets/images/GoalsStrategyLogo.svg';
import { useDispatch, useSelector } from "react-redux";
import {
    fetchPerformance_Summary_By_Dimensions, fetchstudent_performance_tile_report, fetchstudent_dimension_table_report, fetchstudent_dimension_report
} from '../redux/slices/studentperformance';
import { useMemo } from 'react';
import StachedBarChart from "../components/Charts/stachedbarchart";
import LoaderContainer from "../components/loaderContainer";

export default function StrengthWeakness(props) {
    const DarkMode = useSelector((state) => state.global.DarkMode);
    const dispatch = useDispatch();
    const Performance_Summary_By_Dimensionsdata = useSelector(state => state.studentperformance.Performance_Summary_By_Dimensions);
    const student_performance_tile_reportdata = useSelector(state => state.studentperformance.student_performance_tile_report);
    const student_performance_tile_reportdataloading = useSelector(state => state.studentperformance.student_performance_tile_reportloading);
    const student_dimension_table_reportdata = useSelector(state => state.studentperformance.student_dimension_table_report);
    const student_dimension_table_reportdataloading = useSelector(state => state.studentperformance.student_dimension_table_reportloading);
    const student_dimension_reportdata = useSelector(state => state.studentperformance.student_dimension_report);
    const student_dimension_reportdataloading = useSelector(state => state.studentperformance.student_dimension_reportloading);
    console.log(student_dimension_reportdata, 'student_dimension_reportdata')
    // console.log("student_performance_tile_reportdata",student_performance_tile_reportdata)

    let student_performance_tile_reportdatanew = JSON.parse(JSON.stringify(student_performance_tile_reportdata));
    if (student_performance_tile_reportdatanew) {
        student_performance_tile_reportdatanew = student_performance_tile_reportdatanew.slice().sort((a, b) => {
            return a.METRIC_DISPLAY_ORDER - b.METRIC_DISPLAY_ORDER
        })
    }

    const [performance, setPerformance] = useState("student_ethnicity");
    sessionStorage.setItem("performancesummary", performance);
    const [page, setPage] = useState(0);
    const options = [
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'Grade', value: 'student_grade' },
        { name: 'English Learners', value: 'student_ell' },
        { name: 'Hispanic', value: 'student_hispanic' },
        // { name: 'Homelessness', value: 'student_homeless' },
        // { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        // { name: 'Language Classification', value: 'student_language_classification' },
        // { name: 'ELL', value: 'student_ell' },
        // { name: 'Special Ability', value: 'student_special_ability' }
    ];
    const [selectedmetric, setSelectedmetric] = useState(10003);
    sessionStorage.setItem("selectedmetric", selectedmetric);
    const [selectedmetricname, setSelectedmetricname] = useState("Attendance Rate");
    // console.log("selectedmetric",selectedmetric)
    useEffect(() => {
        dispatch(fetchstudent_performance_tile_report({
            "elasticQueryName": "student_performance_tile_report",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2024"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
        }));

        // dispatch(fetchstudent_dimension_table_report({
        //     "elasticQueryName": "student_dimension_table_report",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension}", columnValue: ["student_ethnicity"]}],
        // }));

        // dispatch(fetchstudent_dimension_report({
        //     "elasticQueryName": "student_dimension_report",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension}", columnValue: ["student_ethnicity"]}],
        // }));

    }, [])

    useEffect(() => {
        const handleBeforeUnload = () => {
            window.sessionStorage.removeItem('allfilter');
            window.sessionStorage.removeItem('selectedmetric');
            window.sessionStorage.removeItem('performancesummary');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    var defaultallfilter = JSON.parse(sessionStorage.getItem("allfilter"));


    useEffect(() => {
        // const body = {
        //     "elasticQueryName": "",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
        //     "pivotConfig": [],

        // };

        let body;
        if (defaultallfilter) {
            body = {
                "elasticQueryName": "",
                "filters": defaultallfilter.filters.concat([{ "columnName": "metric_id", "columnValue": [selectedmetric] }]),
                "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
            };
        } else {
            body = {
                "elasticQueryName": "",
                "filters": [{
                    "columnName": "SCHOOL_YEAR",
                    "columnValue": ["2024"],
                    "excludeKeyword": false
                }, { "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
                "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance] }],
            };
        }
        handleperformance(body)
        dispatch(fetchstudent_dimension_report({
            "elasticQueryName": "student_dimension_report",
            "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
            "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performance]}],
        }));
    }, [selectedmetric, performance]);

    const handleperformance = (body) => {
        // dispatch(fetchPerformance_Summary_By_Dimensions(body));
        dispatch(fetchstudent_dimension_table_report(body));
        // dispatch(fetchstudent_dimension_report(body));
    }

    var tpivot = (data, groupBy, key, value) => {

        let pivotObj = {};
        let grouped = [];
        data.forEach(a => {
            if (!pivotObj[a[groupBy]]) {

                pivotObj[a[groupBy]] = { [groupBy]: a[groupBy] };
                grouped.push(pivotObj[a[groupBy]]);
            }
            let obj = { [a[key]]: a[value] };
            Object.assign(pivotObj[a[groupBy]], obj, a);
        }, Object.create(null));


        return grouped;
    };
    let finalData;
    let finalDatatile;
    if (student_dimension_table_reportdata && student_dimension_table_reportdata.length) {
        finalData = tpivot(student_dimension_table_reportdata, "DIMENSION", "METRIC_NAME", "GROUPBY_DISTRICT_PERFORMANCE");
        //  finalDatatile = tpivot(student_dimension_reportdata, "DIMENSION", "TARGET_TOTAL", "ACTUAL_TOTAL");
        console.log("finalData", finalData)
    }

    finalData = finalData?.filter(itr => itr['DIMENSION'] != null); 

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;
    const items = finalData
    const totalPages = Math.ceil(items?.length / itemsPerPage);
    const handleNextClick = () => {
        setCurrentPage(currentPage === totalPages ? 1 : currentPage + 1);
    };
    const handlePrevClick = () => {
        setCurrentPage(currentPage === 1 ? totalPages : currentPage - 1);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = items?.slice(startIndex, endIndex);

    console.log("currentItems", currentItems)

    const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 5,
            numScroll: 5
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    // useEffect(() => {
    //     ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    // }, []);

    const handleTopTileFilterClick = (tile) => {
        setSelectedmetric(tile?.METRIC_ID)
        setSelectedmetricname(tile?.METRIC_NAME)
    }

    const productTemplates = (finalData) => {
        return (
            <div className='cohortbox-carousel pt-0'>
                <div className='product-item'>
                    <div className='legendbox'>
                        <div className='legend-header text-xs tileTitleColor'><p>{finalData?.DIMENSION}</p></div>
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-start items-center'>
                                <span className="text-[13px] font-[400] mr-2 tileTitleColor" >{finalData?.METRIC_TYPE == "percentage" ? finalData?.GROUPBY_DISTRICT_PERFORMANCE.toFixed(1)+"%" : parseInt(finalData?.GROUPBY_DISTRICT_PERFORMANCE.toFixed(1))?.toLocaleString()} </span>
                                {finalData.LAST_PERIOD != 0?
                                    finalData?.LAST_PERIOD_VARIANCE > 0 ? <Chip label={finalData?.LAST_PERIOD_VARIANCE?.toFixed(1) + "%"} icon="pi pi-arrow-up-right" style={{ background: "#1C6130", fontSize: 11, height: 20, color: '#fff' }} /> :
                                        finalData?.LAST_PERIOD_VARIANCE == 0 ? <Chip label={finalData?.LAST_PERIOD_VARIANCE?.toFixed(1) + "%"} icon="pi pi-minus" style={{ background: "#808080", fontSize: 11, height: 20, color: '#fff' }} /> :
                                            <Chip label={finalData?.LAST_PERIOD_VARIANCE?.toFixed(1) + "%"} icon="pi pi-arrow-down-right" style={{ background: "#6A1D25", fontSize: 11, height: 20, color: '#fff' }} />
                                :<Chip label="-" icon="pi pi-arrow-up-right" style={{ background: "#1C6130", fontSize: 11, height: 20, color: '#fff' }} />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const columnsMapping = (tempObj, a, column, columnsRestored) => {
        Object.keys(a).forEach((keys) => {
            if (column.includes(keys)) {
                tempObj[keys + "_" + a.METRIC_BAND_ORDER] = a[keys];
            }
            if (columnsRestored.includes(keys)) {
                tempObj[keys] = a[keys];
            }
        });
    };

    const groupByTimeSeries = (data, row, column, columnsRestored) => {
        let tempObj = {};
        let finalData1 = [];
        let dimensions = [];
        if (data) {
            data.forEach((item) => {
                if (!dimensions.includes(item[row])) {
                    dimensions.push(item[row]);
                }
            });
            dimensions.forEach((m) => {
                let flag = false;
                tempObj = {};
                data.forEach((a, index) => {
                    if (!flag) {
                        tempObj[row] = a[row];
                    }
                    if (m == a[row]) {
                        columnsMapping(tempObj, a, column, columnsRestored);
                        flag = true;
                    }
                });
                if (flag) {
                    finalData1.push(tempObj);
                }
            });
        }

        return finalData1;

    };

    let student_dimension_reportdatanew = JSON.parse(JSON.stringify(student_dimension_reportdata));

    student_dimension_reportdatanew.map(itr => {
        if (itr) {
            if (itr["SCHOOL_MONTH"] > 0) {
                itr["overallvalue"] = itr["SCHOOL_YEAR"] + "/" + itr["SCHOOL_MONTH"]
            } else {
                itr["overallvalue"] = itr["SCHOOL_YEAR"]
            }
            itr["TARGET_TOTAL"] = Number(itr["TARGET_TOTAL"])?.toFixed(1)
            itr["ACTUAL_TOTAL"] = Number(itr["ACTUAL_TOTAL"])?.toFixed(1)
        }
    })

    if (performance == "student_ethnicity") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Hispanic/Latino") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "White") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            } else if (itr["DIMENSION"] == "Black/African-American") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 3
            }
            else if (itr["DIMENSION"] == "Asian") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 4
            }
            else if (itr["DIMENSION"] == "American Indian/Alaska Native") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 5
            }
            else if (itr["DIMENSION"] == "Pacific Islander/Hawaiian Native") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 6
            }
            else if (itr["DIMENSION"] == "NULL") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 7
            }
            return itr;
        })
    }
    else if (performance == "student_gender") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Female") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "Male") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            }
            else if (itr["DIMENSION"] == "Non-Binary") {
                itr["METRIC_BAND_COLOR"] = "#E6DE32";
                itr["METRIC_BAND_ORDER"] = 3
            }
            return itr;
        })
    }
    else if (performance == "student_ell") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Yes") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            }

            return itr;
        })
    }
    else if (performance == "student_hispanic") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "Yes") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            }
            return itr;
        })
    }
    // else if (performance == "student_ell") {
    //     student_dimension_reportdatanew?.map(itr => {
    //         if (itr["DIMENSION"] == "Not Reported") {
    //             itr["METRIC_BAND_COLOR"] = "#019049";
    //             itr["METRIC_BAND_ORDER"] = 1
    //         } else if (itr["DIMENSION"] == "No") {
    //             itr["METRIC_BAND_COLOR"] = "#2DE78B";
    //             itr["METRIC_BAND_ORDER"] = 2
    //         } else if (itr["DIMENSION"] == "Yes") {
    //             itr["METRIC_BAND_COLOR"] = "#2DE78B";
    //             itr["METRIC_BAND_ORDER"] = 3
    //         }
    //         return itr;
    //     })
    // }
    //  else if (performance == "student_special_ability") {
    //     student_dimension_reportdatanew?.map(itr => {
    //         if (itr["DIMENSION"] == "Yes") {
    //             itr["METRIC_BAND_COLOR"] = "#019049";
    //             itr["METRIC_BAND_ORDER"] = 1
    //         } else if (itr["DIMENSION"] == "No") {
    //             itr["METRIC_BAND_COLOR"] = "#2DE78B";
    //             itr["METRIC_BAND_ORDER"] = 2
    //         }
    //         return itr;
    //     })
    // }
    else if (performance == "student_grade") {
        student_dimension_reportdatanew?.map(itr => {
            if (itr["DIMENSION"] == "GRADE PK") {
                itr["METRIC_BAND_COLOR"] = "#019049";
                itr["METRIC_BAND_ORDER"] = 1
            } else if (itr["DIMENSION"] == "GRADE PKAM") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 2
            } else if (itr["DIMENSION"] == "GRADE 1") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 3
            } else if (itr["DIMENSION"] == "GRADE 2") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 4
            } else if (itr["DIMENSION"] == "GRADE 3") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 5
            } else if (itr["DIMENSION"] == "GRADE 4") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 6
            } else if (itr["DIMENSION"] == "GRADE 5") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 7
            } else if (itr["DIMENSION"] == "GRADE 6") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 8
            } else if (itr["DIMENSION"] == "GRADE 7") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 9
            } else if (itr["DIMENSION"] == "GRADE 8") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 10
            } else if (itr["DIMENSION"] == "GRADE 9") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 11
            } else if (itr["DIMENSION"] == "GRADE 10") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 12
            } else if (itr["DIMENSION"] == "GRADE 11") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 13
            } else if (itr["DIMENSION"] == "GRADE 12") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 14
            } else if (itr["DIMENSION"] == "GRADE PKPM") {
                itr["METRIC_BAND_COLOR"] = "#2DE78B";
                itr["METRIC_BAND_ORDER"] = 15
            }
            return itr;
        })
    }

    let columns = ["ACTUAL_TOTAL"];
    let columnsRestored = [
        "TARGET_TOTAL",
    ];
    let finalData1 = groupByTimeSeries(
        student_dimension_reportdatanew,
        "overallvalue",
        columns,
        columnsRestored
    ).sort((a,b) => a.overallvalue - b.overallvalue);
    console.log("finalData1", finalData1)

    let data;
    if (performance == "student_ethnicity") {
        data = [
            {
                name: 'Hispanic/Latino',
                type: 'bar',
                stack: 'Hispanic/Latino',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'White',
                type: 'bar',
                stack: 'White',
                barWidth: "10%",
                color: '#2b3d82',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Hispanic',
                type: 'bar',
                stack: 'Hispanic',
                barWidth: "10%",
                color: '#802a64',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Black/African-American',
                type: 'bar',
                stack: 'Black/African-American',
                barWidth: "10%",
                color: '#13917c',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_4
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Multi-Racial',
                type: 'bar',
                stack: 'Multi-Racial',
                barWidth: "10%",
                color: '#cac2f9',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_5
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Asian',
                type: 'bar',
                stack: 'Asian',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_6
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'American Indian/Alaska Native',
                type: 'bar',
                stack: 'American Indian/Alaska Native',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_7
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Pacific Islander/Hawaiian Native',
                type: 'bar',
                stack: 'Pacific Islander/Hawaiian Native',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_7
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: DarkMode ? '#fff' : 'black',
                symbolSize: 10,
                symbol: 'circle',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },

        ]
    } else if (performance == "student_gender") {
        data = [
            {
                name: 'M',
                type: 'bar',
                stack: 'M',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'F',
                type: 'bar',
                stack: 'F',
                barWidth: "10%",
                color: '#2b3d82',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'X',
                type: 'bar',
                stack: 'X',
                barWidth: "10%",
                color: '#802a64',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },

            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_ell") {
        data = [
            {
                name: 'English Learners',
                type: 'bar',
                stack: 'English Learners',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_hispanic") {
        data = [
            {
                name: 'Hispanic',
                type: 'bar',
                stack: 'Hispanic',
                barWidth: "10%",
                color: '#2b79b3',
                animation: false,
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },

            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_ell") {
        data = [
            {
                name: 'Not Reported',
                type: 'bar',
                stack: 'Not Reported',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'No',
                type: 'bar',
                stack: 'No',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Yes',
                type: 'bar',
                stack: 'Yes',
                barWidth: "10%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_special_ability") {
        data = [
            {
                name: 'Yes',
                type: 'bar',
                stack: 'Yes',
                barWidth: "10%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'No',
                type: 'bar',
                stack: 'No',
                barWidth: "10%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    } else if (performance == "student_grade") {
        data = [
            {
                name: 'Preschool/Prekindergarten',
                type: 'bar',
                stack: 'Preschool/Prekindergarten',
                barWidth: "5%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_1
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'KinderGarten',
                type: 'bar',
                stack: 'KinderGarten',
                barWidth: "5%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_2
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'First Grade',
                type: 'bar',
                stack: 'First Grade',
                barWidth: "5%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_3
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Second Grade',
                type: 'bar',
                stack: 'Second Grade',
                barWidth: "5%",
                color: '#13917c',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_4
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Third Grade',
                type: 'bar',
                stack: 'Third Grade',
                barWidth: "5%",
                color: '#cac2f9',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_5
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Fourth Grade',
                type: 'bar',
                stack: 'Fourth Grade',
                barWidth: "5%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_6
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Fifth Grade',
                type: 'bar',
                stack: 'Fifth Grade',
                barWidth: "5%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_7
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Sixth Grade',
                type: 'bar',
                stack: 'Sixth Grade',
                barWidth: "5%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_8
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Seventh Grade',
                type: 'bar',
                stack: 'Seventh Grade',
                barWidth: "5%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_9
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Eighth Grade',
                type: 'bar',
                stack: 'Eighth Grade',
                barWidth: "5%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_10
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Ninth Grade',
                type: 'bar',
                stack: 'Ninth Grade',
                barWidth: "5%",
                color: '#13917c',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_11
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Tenth Grade',
                type: 'bar',
                stack: 'Tenth Grade',
                barWidth: "5%",
                color: '#cac2f9',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_12
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Eleventh Grade',
                type: 'bar',
                stack: 'Eleventh Grade',
                barWidth: "5%",
                color: '#2b79b3',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_13
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Twelfth Grade',
                type: 'bar',
                stack: 'Twelfth Grade',
                barWidth: "5%",
                color: '#2b3d82',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_14
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Early Education',
                type: 'bar',
                stack: 'Early Education',
                barWidth: "5%",
                color: '#802a64',
                data: finalData1?.map(itr =>
                    itr?.ACTUAL_TOTAL_15
                ),
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 2, 2],
                    }
                }
            },
            {
                name: 'Actual',
                type: 'line',
                stack: 'Actual',
                color: '#fff',
                symbolSize: 10,
                symbol: 'circle',
                data: finalData1?.map(itr =>
                    itr?.TARGET_TOTAL
                ),
                lineStyle: {
                    width: 3,
                },
            },
        ]
    }

    const productTemplate = (student_performance_tile_reportdatanew) => {
        return (
            <LoaderContainer loading={student_performance_tile_reportdataloading}>
            <Top_tile_report clicker={student_performance_tile_reportdatanew} clickerFunc={handleTopTileFilterClick} METRIC_GROUP={student_performance_tile_reportdatanew?.METRIC_GROUP} METRIC_ID={student_performance_tile_reportdatanew?.METRIC_ID} METRIC_NAME={student_performance_tile_reportdatanew?.METRIC_NAME}
                CURRENT_PERIOD={student_performance_tile_reportdatanew?.CURRENT_PERIOD} TARGET={student_performance_tile_reportdatanew?.TARGET} METRIC_TYPE={student_performance_tile_reportdatanew?.METRIC_TYPE}
                TARGET_VARIANCE={student_performance_tile_reportdatanew?.TARGET_VARIANCE} LAST_PERIOD={student_performance_tile_reportdatanew?.LAST_PERIOD} LAST_PERIOD_VARIANCE={student_performance_tile_reportdatanew?.LAST_PERIOD_VARIANCE} METRIC_INDICATOR_TYPE={student_performance_tile_reportdatanew?.METRIC_INDICATOR_TYPE?.toLowerCase()}
            />
            </LoaderContainer>
        );
    };

    // const Top_tile_report = (props) => {  
    //     return (
    //       <>
    //       <div onClick={
    //         () => props.clickerFunc(props.clicker)
    //       }>
    //         {
    //             props?.METRIC_ID == selectedmetric?<div className='cohortbox-carousel1 pt-4 pb-3'>
    //             <div className='product-item'>
    //             <div className="heading-text">{props?.METRIC_GROUP}</div>
    //                 <div className='cohortbox2 text-white'>
    //                     <div className='slide-header'><p> <i className="pi pi-flag-fill text-[#d9685b]"></i>{props?.METRIC_NAME}</p></div>
    //                     <div className='grid grid-cols-12'>
    //                         <div className='col-span-4'>
    //                             <span className="text-xs font-[300] ">Current Period </span>
    //                             <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
    //                         </div>
    //                         <div className='col-span-8'>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Target </span>
    //                                 <p className="text-md"> {props?.TARGET?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.TARGET_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "82px"}}><img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "82px"}}><img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>
    //                                 }   
    //                             </div>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Last Period </span>
    //                                 <p className="text-md"> {props?.LAST_PERIOD?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.LAST_PERIOD_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "82px"}}><img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "82px"}}><img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>
    //                                 }   
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             </div>:
    //             <div className='cohortbox-carousel1 pt-4 pb-3'>
    //             <div className='product-item'>
    //                 <div className="heading-text">{props?.METRIC_GROUP}</div>
    //                 <div className='cohortbox1 text-white'>
    //                     <div className='slide-header'><p> <i className="pi pi-flag-fill text-[#d9685b]"></i>{props?.METRIC_NAME}</p></div>
    //                     <div className='grid grid-cols-12'>
    //                         <div className='col-span-4'>
    //                             <span className="text-xs font-[300] ">Current Period </span>
    //                             <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
    //                         </div>
    //                         <div className='col-span-8'>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Target </span>
    //                                 <p className="text-md"> {props?.TARGET?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.TARGET_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "82px"}}><img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "82px"}}><img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%</div>
    //                                 }  
    //                             </div>
    //                             <div className='flex justify-start items-center'>
    //                                 <span className="text-xs font-[300]  mx-2">Last Period </span>
    //                                 <p className="text-md"> {props?.LAST_PERIOD?.toFixed(1)}%</p>
    //                                 {
    //                                     props?.LAST_PERIOD_VARIANCE >=0? <div className='trendingup ml-2' style = {{width: "82px"}}><img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>:
    //                                     <div className='trendingdown ml-2' style = {{width: "82px"}}><img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%</div>
    //                                 }   
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             </div>
    //         }
    //         </div>

    //       </>
    //     );

    // };


    const Top_tile_report = (props) => {
        return (
            <>
                <div onClick={
                    () => props.clickerFunc(props.clicker)
                }>
                    {
                        props?.METRIC_ID == selectedmetric ? <div className='cohortbox-carousel1 pt-0 pb-2'>
                            <div className='product-item'>
                                <div style={{ fontWeight: 400 }} className="heading-text EnrollmentTitle">{props?.METRIC_GROUP}</div>
                                <div className='cohortbox2 text-white'>
                                    <div className='slide-header grid grid-cols-12'>
                                        <div className='col-span-2'> <p> <i className={`pi pi-flag-fill ${(props?.METRIC_INDICATOR_TYPE=='positive' === props?.TARGET < props?.CURRENT_PERIOD)?'text-[#5bd96c]':'text-[#d9685b]'}`}></i></p></div>
                                        <div className='col-span-7'> <p>{props?.METRIC_NAME}</p></div>
                                        <div className='col-span-3'> <p className="text-xl font-[600]" style={{ position: "relative", bottom: "4px", left: "10px" }}>{props?.METRIC_TYPE == "percentage" ? props?.CURRENT_PERIOD.toFixed(1)+"%" : (props?.CURRENT_PERIOD)?.toLocaleString()}</p></div>
                                    </div>
                                    <div className='grid grid-cols-12'>
                                        {/* <div className='col-span-4'>
                                <span className="text-xs font-[300] ">Current Period </span>
                                <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
                            </div> */}
                                        <div className='col-span-12'>
                                            <div className='flex justify-start items-center'>
                                                <span className="text-xs font-[300]  mx-2">Target </span>
                                                <p className="text-md"> {props?.METRIC_TYPE == "percentage" ? props?.TARGET.toFixed(1)+"%" : props?.TARGET ? props?.TARGET : "-"}</p>
                                                {
                                                    (props?.METRIC_TYPE == "percentage" || props?.TARGET) ? (
                                                        ["Chronic Absence Rate", "Recidivism Rate", "Suspension Rate", "In School Suspensions", "Out of School Suspensions", "Referrals", "# of Level 1 Incidents", "# of Non-Level 1 Incidents"].includes(props?.METRIC_NAME) ?
                                                            (
                                                                props?.TARGET_VARIANCE >= 0 ?  
                                                                    <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                    </div> :
                                                                    <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                    </div>
                                                            ) : (
                                                                props?.TARGET_VARIANCE >= 0 ?
                                                                    <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                    </div> :
                                                                    <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                    </div>
                                                            )
                                                    ) : null
                                                }
                                            </div>
                                            <div className='flex justify-start items-center'>
                                                <div className="text-xs font-[300]  mx-2">LY Var </div>
                                                <p className="text-md"> {props?.METRIC_TYPE == "percentage" ? props?.LAST_PERIOD.toFixed(1)+"%" : props?.LAST_PERIOD}</p>
                                                {
                                                    props.LAST_PERIOD != 0 ?
                                                        ["Chronic Absence Rate", "Recidivism Rate", "Suspension Rate", "In School Suspensions", "Out of School Suspensions", "Referrals", "# of Level 1 Incidents", "# of Non-Level 1 Incidents"].includes(props?.METRIC_NAME) ?
                                                            (
                                                                props?.LAST_PERIOD_VARIANCE >= 0 ?
                                                                    <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                    </div> :
                                                                    <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                    </div>
                                                            ) :
                                                            (
                                                                props?.LAST_PERIOD_VARIANCE >= 0 ?
                                                                    <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                    </div> :
                                                                    <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                        <img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                    </div>
                                                            )
                                                        : <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                            <img src={TreadingarrowUp} className="" />-%
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <div className='cohortbox-carousel1 pt-0 pb-2'>
                                <div className='product-item'>
                                    <div style={{ fontWeight: 400 }} className="heading-text EnrollmentTitle">{props?.METRIC_GROUP}</div>
                                    <div className='cohortbox1 text-white'>
                                        <div className='slide-header grid grid-cols-12'>
                                            <div className='col-span-2'> <p> <i className={`pi pi-flag-fill ${(props?.METRIC_INDICATOR_TYPE=='positive' === props?.TARGET < props?.CURRENT_PERIOD)?'text-[#5bd96c]':'text-[#d9685b]'}`}></i></p></div>
                                            <div className='col-span-7'> <p>{props?.METRIC_NAME}</p></div>
                                            <div className='col-span-3'> <p className="text-xl font-[600]" style={{ position: "relative", bottom: "4px", left: "10px" }}>{props?.METRIC_TYPE == "percentage" ? props?.CURRENT_PERIOD.toFixed(1)+"%" : (props?.CURRENT_PERIOD)?.toLocaleString()}</p></div>
                                        </div>
                                        <div className='grid grid-cols-12'>
                                            {/* <div className='col-span-4'>
                                <span className="text-xs font-[300] ">Current Period </span>
                                <p className="text-xl font-[600]">{props?.CURRENT_PERIOD?.toFixed(1)}%</p>
                            </div> */}
                                            <div className='col-span-12'>
                                                <div className='flex justify-start items-center'>
                                                    <span className="text-xs font-[300]  mx-2">Target </span>
                                                    <p className="text-md"> {props?.METRIC_TYPE == "percentage" ? props?.TARGET.toFixed(1)+"%" : props?.TARGET ? props?.TARGET : "-"}</p>
                                                    {
                                                        (props?.METRIC_TYPE == "percentage" || props?.TARGET) ? (
                                                            ["Chronic Absence Rate", "Recidivism Rate", "Suspension Rate", "In School Suspensions", "Out of School Suspensions", "Referrals", "# of Level 1 Incidents", "# of Non-Level 1 Incidents"].includes(props?.METRIC_NAME) ?
                                                                (
                                                                    props?.TARGET_VARIANCE >= 0 ?  
                                                                        <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                        </div> :
                                                                        <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                        </div>
                                                                ) : (
                                                                    props?.TARGET_VARIANCE >= 0 ?
                                                                        <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowUp} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                        </div> :
                                                                        <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowDown} className="" />{props?.TARGET_VARIANCE?.toFixed(1)}%
                                                                        </div>
                                                                )
                                                        ) : null
                                                    }
                                                </div>
                                                <div className='flex justify-start items-center'>
                                                    <span className="text-xs font-[300]  mx-2">LY Var </span>
                                                    <p className="text-md"> {props?.METRIC_TYPE == "percentage" ? props?.LAST_PERIOD.toFixed(1)+"%" : props?.LAST_PERIOD}</p>
                                                    {
                                                        props.LAST_PERIOD != 0 ?
                                                            ["Chronic Absence Rate", "Recidivism Rate", "Suspension Rate", "In School Suspensions", "Out of School Suspensions", "Referrals", "# of Level 1 Incidents", "# of Non-Level 1 Incidents"].includes(props?.METRIC_NAME) ?
                                                                (
                                                                    props?.LAST_PERIOD_VARIANCE >= 0 ?
                                                                        <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                        </div> :
                                                                        <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                        </div>
                                                                ) :
                                                                (
                                                                    props?.LAST_PERIOD_VARIANCE >= 0 ?
                                                                        <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowUp} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                        </div> :
                                                                        <div className='trendingdown ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                            <img src={TreadingarrowDown} className="" />{props?.LAST_PERIOD_VARIANCE?.toFixed(1)}%
                                                                        </div>
                                                                )
                                                            : <div className='trendingup ml-2' style={{ width: "55px", fontSize: "11px" }}>
                                                                <img src={TreadingarrowUp} className="" />-%
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>

            </>
        );

    };
    // Dummy Data Remove Later
    // const dummy_Data = [
    //     {
    //         "METRIC_ID": 0,
    //         "SCHOOL_YEAR": 0,
    //         "METRIC_NAME": "Senior expected on-time ",
    //         "METRIC_DESCRIPTION": " ",
    //         "METRIC_GROUP": "Graduation",
    //         "METRIC_GROUP_ORDER": 0,
    //         "METRIC_GROUP_DESCRIPTION": " ",
    //         "METRIC_GROUP_ICON_LOCATION": " ",
    //         "METRIC_DISPLAY_ORDER": 0,
    //         "METRIC_ICON_LOCATION": " ",
    //         "METRIC_MET_LABEL": " ",
    //         "METRIC_NOT_MET_LABEL": " ",
    //         "METRIC_TYPE": "number",
    //         "METRIC_SUFFIX": " ",
    //         "METRIC_PREFIX": " ",
    //         "METRIC_MASK": "##.0",
    //         "METRIC_PRECISION": 1,
    //         "METRIC_INDICATOR_TYPE": "positive",
    //         "METRIC_NUMERATOR": 0,
    //         "METRIC_DENOMINATOR": 0,
    //         "METRIC_NUMERATOR_PY1": 0,
    //         "METRIC_DENOMINATOR_PY1": 0,
    //         "TARGET_DENOMINATOR": 0,
    //         "TARGET_NUMERATOR": 0,
    //         "CURRENT_PERIOD": 0,
    //         "LAST_PERIOD": 0,
    //         "LAST_PERIOD_VARIANCE": 0,
    //         "TARGET": 0,
    //         "TARGET_VARIANCE": 0,
    //         "METRIC_GROUP_COLOR": "dimgray"
    //     },
    //     {
    //         "METRIC_ID": 0,
    //         "SCHOOL_YEAR": 0,
    //         "METRIC_NAME": "On-Time graduation rate",
    //         "METRIC_DESCRIPTION": " ",
    //         "METRIC_GROUP": "Graduation",
    //         "METRIC_GROUP_ORDER": 0,
    //         "METRIC_GROUP_DESCRIPTION": " ",
    //         "METRIC_GROUP_ICON_LOCATION": " ",
    //         "METRIC_DISPLAY_ORDER": 0,
    //         "METRIC_ICON_LOCATION": " ",
    //         "METRIC_MET_LABEL": " ",
    //         "METRIC_NOT_MET_LABEL": " ",
    //         "METRIC_TYPE": "number",
    //         "METRIC_SUFFIX": " ",
    //         "METRIC_PREFIX": " ",
    //         "METRIC_MASK": "##.0",
    //         "METRIC_PRECISION": 1,
    //         "METRIC_INDICATOR_TYPE": "positive",
    //         "METRIC_NUMERATOR": 0,
    //         "METRIC_DENOMINATOR": 0,
    //         "METRIC_NUMERATOR_PY1": 0,
    //         "METRIC_DENOMINATOR_PY1": 0,
    //         "TARGET_DENOMINATOR": 0,
    //         "TARGET_NUMERATOR": 0,
    //         "CURRENT_PERIOD": 0,
    //         "LAST_PERIOD": 0,
    //         "LAST_PERIOD_VARIANCE": 0,
    //         "TARGET": 0,
    //         "TARGET_VARIANCE": 0,
    //         "METRIC_GROUP_COLOR": "dimgray"
    //     },
    //     {
    //         "METRIC_ID": 0,
    //         "SCHOOL_YEAR": 0,
    //         "METRIC_NAME": "K-8 ELA iready assessment",
    //         "METRIC_DESCRIPTION": " ",
    //         "METRIC_GROUP": "Assessment",
    //         "METRIC_GROUP_ORDER": 0,
    //         "METRIC_GROUP_DESCRIPTION": " ",
    //         "METRIC_GROUP_ICON_LOCATION": " ",
    //         "METRIC_DISPLAY_ORDER": 0,
    //         "METRIC_ICON_LOCATION": " ",
    //         "METRIC_MET_LABEL": " ",
    //         "METRIC_NOT_MET_LABEL": " ",
    //         "METRIC_TYPE": "number",
    //         "METRIC_SUFFIX": " ",
    //         "METRIC_PREFIX": " ",
    //         "METRIC_MASK": "##.0",
    //         "METRIC_PRECISION": 1,
    //         "METRIC_INDICATOR_TYPE": "positive",
    //         "METRIC_NUMERATOR": 0,
    //         "METRIC_DENOMINATOR": 0,
    //         "METRIC_NUMERATOR_PY1": 0,
    //         "METRIC_DENOMINATOR_PY1": 0,
    //         "TARGET_DENOMINATOR": 0,
    //         "TARGET_NUMERATOR": 0,
    //         "CURRENT_PERIOD": 0,
    //         "LAST_PERIOD": 0,
    //         "LAST_PERIOD_VARIANCE": 0,
    //         "TARGET": 0,
    //         "TARGET_VARIANCE": 0,
    //         "METRIC_GROUP_COLOR": "dimgray"
    //     },
    //     {
    //         "METRIC_ID": 0,
    //         "SCHOOL_YEAR": 0,
    //         "METRIC_NAME": "K-8 Math iready assessment",
    //         "METRIC_DESCRIPTION": " ",
    //         "METRIC_GROUP": "Assessment",
    //         "METRIC_GROUP_ORDER": 0,
    //         "METRIC_GROUP_DESCRIPTION": " ",
    //         "METRIC_GROUP_ICON_LOCATION": " ",
    //         "METRIC_DISPLAY_ORDER": 0,
    //         "METRIC_ICON_LOCATION": " ",
    //         "METRIC_MET_LABEL": " ",
    //         "METRIC_NOT_MET_LABEL": " ",
    //         "METRIC_TYPE": "number",
    //         "METRIC_SUFFIX": " ",
    //         "METRIC_PREFIX": " ",
    //         "METRIC_MASK": "##.0",
    //         "METRIC_PRECISION": 1,
    //         "METRIC_INDICATOR_TYPE": "positive",
    //         "METRIC_NUMERATOR": 0,
    //         "METRIC_DENOMINATOR": 0,
    //         "METRIC_NUMERATOR_PY1": 0,
    //         "METRIC_DENOMINATOR_PY1": 0,
    //         "TARGET_DENOMINATOR": 0,
    //         "TARGET_NUMERATOR": 0,
    //         "CURRENT_PERIOD": 0,
    //         "LAST_PERIOD": 0,
    //         "LAST_PERIOD_VARIANCE": 0,
    //         "TARGET": 0,
    //         "TARGET_VARIANCE": 0,
    //         "METRIC_GROUP_COLOR": "dimgray"
    //     },
    //     {
    //         "METRIC_ID": 0,
    //         "SCHOOL_YEAR": 0,
    //         "METRIC_NAME": "9th Grade Credit Accrual",
    //         "METRIC_DESCRIPTION": " ",
    //         "METRIC_GROUP": "Academics",
    //         "METRIC_GROUP_ORDER": 0,
    //         "METRIC_GROUP_DESCRIPTION": " ",
    //         "METRIC_GROUP_ICON_LOCATION": " ",
    //         "METRIC_DISPLAY_ORDER": 0,
    //         "METRIC_ICON_LOCATION": " ",
    //         "METRIC_MET_LABEL": " ",
    //         "METRIC_NOT_MET_LABEL": " ",
    //         "METRIC_TYPE": "number",
    //         "METRIC_SUFFIX": " ",
    //         "METRIC_PREFIX": " ",
    //         "METRIC_MASK": "##.0",
    //         "METRIC_PRECISION": 1,
    //         "METRIC_INDICATOR_TYPE": "positive",
    //         "METRIC_NUMERATOR": 0,
    //         "METRIC_DENOMINATOR": 0,
    //         "METRIC_NUMERATOR_PY1": 0,
    //         "METRIC_DENOMINATOR_PY1": 0,
    //         "TARGET_DENOMINATOR": 0,
    //         "TARGET_NUMERATOR": 0,
    //         "CURRENT_PERIOD": 0,
    //         "LAST_PERIOD": 0,
    //         "LAST_PERIOD_VARIANCE": 0,
    //         "TARGET": 0,
    //         "TARGET_VARIANCE": 0,
    //         "METRIC_GROUP_COLOR": "dimgray"
    //     },
    //     {
    //         "METRIC_ID": 0,
    //         "SCHOOL_YEAR": 0,
    //         "METRIC_NAME": "Annual CCCR Index",
    //         "METRIC_DESCRIPTION": " ",
    //         "METRIC_GROUP": "Graduation",
    //         "METRIC_GROUP_ORDER": 0,
    //         "METRIC_GROUP_DESCRIPTION": " ",
    //         "METRIC_GROUP_ICON_LOCATION": " ",
    //         "METRIC_DISPLAY_ORDER": 0,
    //         "METRIC_ICON_LOCATION": " ",
    //         "METRIC_MET_LABEL": " ",
    //         "METRIC_NOT_MET_LABEL": " ",
    //         "METRIC_TYPE": "number",
    //         "METRIC_SUFFIX": " ",
    //         "METRIC_PREFIX": " ",
    //         "METRIC_MASK": "##.0",
    //         "METRIC_PRECISION": 1,
    //         "METRIC_INDICATOR_TYPE": "positive",
    //         "METRIC_NUMERATOR": 0,
    //         "METRIC_DENOMINATOR": 0,
    //         "METRIC_NUMERATOR_PY1": 0,
    //         "METRIC_DENOMINATOR_PY1": 0,
    //         "TARGET_DENOMINATOR": 0,
    //         "TARGET_NUMERATOR": 0,
    //         "CURRENT_PERIOD": 0,
    //         "LAST_PERIOD": 0,
    //         "LAST_PERIOD_VARIANCE": 0,
    //         "TARGET": 0,
    //         "TARGET_VARIANCE": 0,
    //         "METRIC_GROUP_COLOR": "dimgray"
    //     }
    // ]
    // const CustomOrder = ['Attendance Rate','Chronic Absence Rate','Suspension Rate',"Referrals","Recidivism rate",
    //     "In School Suspensions","Out of School Suspensions" , "Senior expected on-time"]
    // let student_performance_tile_reportdatanew1 = student_performance_tile_reportdatanew
    // .filter(x=>CustomOrder.includes(x.METRIC_NAME))
    // .sort((a,b)=>
    //     CustomOrder.indexOf(a.METRIC_NAME) - CustomOrder.indexOf(b.METRIC_NAME)
    // )
    // .concat(dummy_Data)
    // =================End----------------
    
    /* Dynamic tooltip formatter based on selected toptile */
    const selectedtoptile = useMemo(()=>student_performance_tile_reportdata?.find(item => item?.METRIC_ID == selectedmetric),[selectedmetric,student_performance_tile_reportdata])
    const tooltipformatter = value => {
        // Check if the value is null or undefined
        if (value == null || value == undefined) {
            return "-"; // Display a hyphen for null or undefined values
        }
        // Check if selectedtoptile is set to "percentage"
        if (selectedtoptile && selectedtoptile.METRIC_TYPE == "percentage") {
            return `${value}%`; // Append % if it's a percentage
        }
        return value; // Return the value as is for other cases
    };
    /*  */

    return (
        <div className=" body">
            <TopNav pagename="Student Performance & Progress" pagenamedescription="Key Facts about the District" setUserData={props.setUserData} />
            <div className="carousel-holder1">
                <Carousel value={student_performance_tile_reportdatanew} numScroll={1} numVisible={5} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
            </div>
            <div className="card wrapper-card text-white mx-5 mt-0 mb-1">
                <div className="flex justify-between">
                    <div style={{ position: "relative", top: "24px" }}>
                        <div className="title-text text-xl flex items-center EnrollmentTitle font-[400]">Student's Dimension Analysis</div>
                        {/* <div className="text-xs flex items-center">Selected Indicator: {selectedmetricname}</div> */}
                    </div>
                    <div className="flex justify-center" >
                        <div className="mx-1 enrollselect">
                            <label htmlFor="dd-city" className="labeltext EnrollmentTitle">Analyze by</label>
                            <Dropdown value={performance} onChange={(e) => setPerformance(e.value)} options={options} optionLabel="name"
                                placeholder="Ethnicity" className="w-full md:w-14rem" style={{ width: 250 }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-12 mx-5 mt-1 gap-3'>
               
                <div className='col-span-8'>
                    <div className='echart h-full relative wrap-component'>
                        <div className="title-text flex items-center text-white ml-5 py-2 EnrollmentTitle font-[400]">Time Trending View - {selectedmetricname}</div>
                        <LoaderContainer loading={student_dimension_table_reportdataloading}>
                        <div className="card timetreanding-carousel px-4 mb-4">
                            {
                                finalData && <Carousel value={finalData} numScroll={1} numVisible={5} indicators={false} responsiveOptions={responsiveOptions} itemTemplate={productTemplates} />
                            }
                        </div>
                        </LoaderContainer>
                        {/* {
                            performance == "student_ethnicity" ? <ReactEcharts option={CollectionTrend} />: performance == "student_gender"? <ReactEcharts option={CollectionTrendgender}/>:null
                        } */}
                        {/* <ReactEcharts option={CollectionTrend} />  */}
                        <LoaderContainer loading={student_dimension_reportdataloading}>
                        {
                            performance == "student_ethnicity" && <StachedBarChart data={data} finalData1={finalData1} tooltipformatter={tooltipformatter}/>
                        }
                        {
                            performance == "student_gender" && <StachedBarChart data={data} finalData1={finalData1} tooltipformatter={tooltipformatter}/>
                        }
                        {
                            performance == "student_ell" && <StachedBarChart data={data} finalData1={finalData1} tooltipformatter={tooltipformatter}/>
                        }
                        {
                            performance == "student_hispanic" && <StachedBarChart data={data} finalData1={finalData1} tooltipformatter={tooltipformatter}/>
                        }
                        {/* {
                            performance == "student_ell" && <StachedBarChart data={data} finalData1={finalData1} />
                        }
                        {
                            performance == "student_special_ability" && <StachedBarChart data={data} finalData1={finalData1} />
                        } */}
                        {
                            performance == "student_grade" && <StachedBarChart data={data} finalData1={finalData1} tooltipformatter={tooltipformatter}/>
                        }
                        </LoaderContainer>

                    </div>
                </div>
               
                <div className="card col-span-4 wrap-component">
                    <div className="title-text flex items-center text-white ml-2 py-2 EnrollmentTitle font-[400]">Performance Summary View</div>
                    <div className="px-2">
                        <div className='graduationtable relative'>
                            <div className='flex justify-around items-center '>
                                <div className='text-[#FFFFFF] text-[14px]'> </div>
                                <div className='text-[#FFFFFF] text-[14px]'> </div>
                                <div className='text-[#FFFFFF] text-[14px]'> </div>
                            </div>
                            <div className='collegejoindata'>
                                <LoaderContainer loading={student_performance_tile_reportdataloading}>
                                <table class='table-auto border-collapse'>
                                    <thead className='performance-summary h-[40px] border-t-2 border-white'>
                                        <tr>
                                            <th><Link>Student Group</Link></th>
                                            <th><Link>District Performance</Link></th>
                                            <th><Link>Target</Link></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems && currentItems ?.filter(itr => itr['DIMENSION'] != null)
                                            .map((itr, index) => {
                                                console.log("index", index % 2);
                                                let odd = index % 2;
                                                return (
                                                    <tr className={`bg-[#1a253b] ${odd === 0 ? 'summary' : ''}`} key={index}>
                                                        <td><p>{itr?.DIMENSION}</p></td>
                                                        <td><p>{itr?.METRIC_TYPE == "percentage" ? itr?.GROUPBY_DISTRICT_PERFORMANCE.toFixed(1)+"%" : (itr?.GROUPBY_DISTRICT_PERFORMANCE)?.toLocaleString()}</p></td>
                                                        <td><p>{itr?.METRIC_TYPE == "percentage" ? itr?.GROUPBY_TARGET.toFixed(1)+"%" : (itr?.GROUPBY_TARGET)?.toLocaleString()}</p></td>
                                                    </tr>
                                                );
                                            })}


                                    </tbody>
                                </table>
                                </LoaderContainer>
                                <div className='flex justify-around items-center  table-summary-footer h-[40px] border-t-4 border-white'>
                                    <button className={`paginationprevious ${currentPage === 1 ? 'disabled' : ''}`} style={{ fontSize: "12px", color: "#FFFFF" }} onClick={handlePrevClick} disabled={currentPage === 1}>Previous</button>
                                    <button className={`paginationnext ${currentPage === totalPages ? 'disabled' : ''}`} style={{ fontSize: "12px", color: "#FFFFF" }} onClick={handleNextClick} disabled={currentPage === totalPages}>Next</button>
                                    {/* text-[#898d95] text-[12px] */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer flex justify-between p-0 mt-0">
                <div class="flex mt-2">
                    {/* <div class="mx-5 mt-2">Conditions of Use</div>
                    <div class="mx-5 mt-2">Privacy Notice</div>
                    <div class="mx-5 mt-2">@2024 Greece | All right reserved</div> */}
                </div>
                <div class="mr-3">
                    <img src={Complogo} height="50px" width="130px" alt="logo" className="mr-[2vw]" />
                </div>
            </div>
        </div>
    )
}