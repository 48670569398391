

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import ReactEcharts from "echarts-for-react";

import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';

import SchoolDistBannerImg from "../../assets/images/school_dist_banner.jpeg"
import InterviewDark from "../../assets/images/interview.png"
import InterviewLightImg from "../../assets/images/InterviewLight.png"
// import HorizontalStackBars from "../components/common/horizontalstackbars";
import HorizontalStackBars from "../../components/common/horizontalstackbars/horizontalstackbar";
import { useSelector,useDispatch } from "react-redux";
import {
    fetchTeacherFTE,fetchTotalTeacherFTE,fetchStudentTeacherRatio
  } from '../../redux/slices/counter';
import LoaderContainer from "../../components/loaderContainer";



export default function StudentCharacteristics() {
    const dispatch = useDispatch();
    const[screenWidth, setScreenWidth] = useState(0);
    useEffect(() => {
    const handleResize = () => {
    if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth);
        console.log(screenWidth,"setScreenWidth");
        }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
    window.removeEventListener('resize', handleResize);
    };
    }, []); 

    // API calls
    useEffect(()=>{
        dispatch(fetchTeacherFTE({
            "elasticQueryName": "",
            "filters": [
                {
                    "columnName": "SCHOOL_YEAR",
                    "columnValue": ["2024"],
                    "excludeKeyword": false
                }
            ],
            "dynamicColumns": [],
        }));
        
        dispatch(fetchTotalTeacherFTE({
            "elasticQueryName": "",
            "filters": [
                {
                    "columnName": "SCHOOL_YEAR",
                    "columnValue": ["2024"],
                    "excludeKeyword": false
                }
            ],
            "dynamicColumns": [],
        }));

        dispatch(fetchStudentTeacherRatio({
            "elasticQueryName": "",
            "filters": [
                {
                    "columnName": "SCHOOL_YEAR",
                    "columnValue": ["2024"],
                    "excludeKeyword": false
                }
            ],
            "dynamicColumns": [],
        }));
    },[])
    // 

    // Redux State
    const DarkMode = useSelector((state) => state.global.DarkMode);
    const TeacherFTE = useSelector(state => state.netsales.TeacherFTE);
    const TeacherFTEloading = useSelector(state => state.netsales.TeacherFTEloading);
    const TotalTeacherFTE = useSelector(state => state.netsales.TotalTeacherFTE);
    const TotalTeacherFTEloading = useSelector(state => state.netsales.TotalTeacherFTEloading);
    const StudentTeacherRatio = useSelector(state => state.netsales.StudentTeacherRatio);
    const StudentTeacherRatioloading = useSelector(state => state.netsales.StudentTeacherRatioloading);
// 

    const InterviewImg = DarkMode ? InterviewDark : InterviewLightImg;
    useEffect(() => {

    }, []);
    const studentteacherratio = {
        yaxis: ["student"],
        value: [13],
        value1: [1]
    }

    //     const SchoolDistrictEmployees = {

    //         tooltip: {
    //             trigger: 'axis',
    //             backgroundColor: 'rgba(50,50,50,0.7)',
    //             borderColor: "#333",
    //             textStyle: {
    //                 fontSize: 12,
    //                 fontWeight: "normal",
    //                 color: '#fff',
    //             },
    //             axisPointer: {
    //                 type: 'shadow'
    //             }
    //         },
    //         grid: {
    //             left: '0',
    //             right: '0',
    //             top: '0',
    //             height: '100%',
    //             containLabel: true
    //         },
    //         xAxis: {
    //             show: false,
    //             type: 'value',
    //         },
    //         yAxis: [{
    //             type: 'category',
    //             data: ['A-Audlt/Higher Ed', 'C-Combined ', 'K12-Combined Elementary/Secondary', 'C-Combined Middle/High', 'E-Elementary', 'M-Middle', 'h-high'],
    //             axisLine: {
    //                 show: false,
    //             },
    //             axisTick: {
    //                 show: false,
    //             },

    //             axisLabel: {
    //                 inside: true,
    //                 color: '#fff',
    // padding:30
    //             },
    //             z: 10
    //         },
    //         {
    //             type: 'category',

    //             axisLine: {
    //                 show: false
    //             },
    //             axisTick: {
    //                 show: false
    //             },
    //             axisLabel: {
    //                 color: "#fff"
    //             },
    //         },

    //         ],
    //         series: [{
    //             type: 'bar',
    //             stack: 'total',
    //             barWidth: "25px",
    //             barGap: "20",
    //             itemStyle: {
    //                 normal: {
    //                     color: '#1f46ab',
    //                 }
    //             },
    //             label: {
    //                 show: true,
    //                 position: 'center',
    //                 color:'#fff',
    //                 padding:8
    //             },
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [80, 100, 100, 90, 90, 90, 90]
    //         },
    //         {
    //             type: 'bar',
    //             stack: 'total',
    //             barWidth: "25px",
    //             barGap: "20",
    //             itemStyle: {
    //                 normal: {
    //                     color: '#892c69',
    //                 }
    //             },
    //             avoidLabelOverlap: false,
    //             label: {
    //                 show: true,
    //                 position: 'center',
    //                 color:'#fff',
    //                 padding:8
    //             },
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [20, 0, 0, 10, 10, 10, 10]
    //         },

    //         ]
    //     };


    const SchoolDistrictEmployees = {

        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(50,50,50,0.7)',
            borderColor: "#333",
            textStyle: {
                fontSize: 12,
                fontWeight: "normal",
                color: '#fff',
            },
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '0',
            right: '0',
            top: '0',
            height: '100%',
            containLabel: true
        },
        xAxis: {
            show: false,
            type: 'value',
        },
        yAxis: [{
            type: 'category',
            data: ['Others(Early Childhood Centre,Etc)', 'High School', 'Middle School', 'Elementary'],
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },

            axisLabel: {
                inside: true,
                color: '#fff',
                padding: 16
            },
            z: 10
        },
        {
            type: 'category',

            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color: "#fff"
            },
        },

        ],
        series: [{
            type: 'bar',
            stack: 'total',
            barWidth: "25px",
            barGap: "20",
            itemStyle: {
                normal: {
                    color: '#1f46ab',
                }
            },
            label: {
                show: true,
                position: 'right',
                color: '#fff',
                // padding:6.5,
                // paddingLeft:40,

            },
            emphasis: {
                focus: 'series'
            },
            data: [43, 1329, 1088, 3031]
        },
            // {
            //     type: 'bar',
            //     stack: 'total',
            //     barWidth: "25px",
            //     barGap: "20",
            //     itemStyle: {
            //         normal: {
            //             color: '#892c69',
            //         }
            //     },
            //     avoidLabelOverlap: false,
            //     label: {
            //         show: true,
            //         position: 'center',
            //         color:'#fff',
            //         padding:8
            //     },
            //     emphasis: {
            //         focus: 'series'
            //     },
            //     data: [20, 0, 0, 10, 10, 10, 10]
            // },

        ]
    };

    const TeacherFTEChartData = TeacherFTE.reduce((acc,item)=>{
        acc['label'].push(item?.SCHOOL_TYPE)
        acc['value'].push(item?.VALUE)
        return acc
    },{label:[],value:[]})

    const sortedData = TeacherFTEChartData.label
    .map((label, index) => ({ label, value: TeacherFTEChartData.value[index] }))
    .sort((a, b) => a.label.localeCompare(b.label));

// Separate the sorted data back into `label` and `value`
const sortedTeacherFTEChartData = {
    label: sortedData.map(item => item.label),
    value: sortedData.map(item => item.value)
}

    const SchoolDistrictEmployeesnew = {
        tooltip: {
            trigger: 'axis',
            backgroundColor: DarkMode ? "black" : "white",
            borderColor: "#333",
            textStyle: {
                fontSize: 12,
                fontWeight: "normal",
                color: DarkMode ? "white" : "black",
            },
            axisPointer: {
                type: 'shadow'
            },
            confine: true
        },
        grid: {
            left: '6%',
            bottom: '0%',
            top: "0%",
            right: '10%',
            height: '100%',
            containLabel: true
        },

        yAxis: [
            {
                type: 'category',
                position: "left",
                inverse: true,
                data: sortedTeacherFTEChartData.label,//['Kindergarten', 'Elementary', 'Secondary', 'Ungraded'],
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#E3E3E3'
                    }
                },
                axisLabel: {
                    color: DarkMode ? "white" : "black",
                    margin: 10 || 0,
                    overflow: "truncate",
                    // color: '#fff',
                    fontSize: 12,
                    align: 'left',
                    width: 90,
                    fontFamily: 'Roboto, sans-serif',
                    padding: [0, 0, 0, -80],
                    fontWeight: "normal",
                    position: 'insideLeft',
                },
                axisTick: {
                    show: false,
                },
            },
        ],

        xAxis: {
            show: false,
            inverse: false,
            // min: 0,
            // max: 3500,
            // interval: 10,
        },

        series: [
            {
                label: {
                    normal: {
                        show: true,
                        position: "right",
                        fontSize: 10,
                        // padding: 10,
                        "formatter": function (params) {
                            // debugger
                            // return params.value.VALUE
                            return params.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        },
                        "textStyle": {
                            "color": DarkMode ? "white" : "black"
                        }
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: sortedTeacherFTEChartData.value, //[47, 396, 428, 11],
                type: 'bar',
                barWidth: "80%",
                color: '#1F46AB',
                itemStyle: {
                    normal: {
                        barBorderRadius: [3, 3, 3, 3],
                        color: '#1F46AB',
                    }
                }

            }
        ]
    };

    return (
        <div className="EnrollmentTile">

            <div className="EnrollmentTile">
                <div className="relative h-[130px]">
                    <div className=" h-[130px] ">
                        <img src={SchoolDistBannerImg} className="h-[130px] w-full " />
                    </div>
                    <div className="w-full absolute bottom-0 h-[27px] bg-[#1d192f9c] py-1 px-3">
                        <h3 className="text-[14px] font-[400] EnrollmentTitlee ">School District Employees</h3>
                    </div>

                </div>
                <div className="flex justify-around mt-1 mr-[129px]">
                    <div className="flex items-start gap-2 mb-1">
                        <img src={InterviewImg} className="h-[38px] w-[38px] EnrollmentTitle" />
                        <LoaderContainer loading = {TotalTeacherFTEloading}>
                        <div className="pt-2">
                            <p className="dark:text-white font-[400] text-[14px] mb-1">Total Teacher  FTE</p>
                            <h4 className="text-[16px] font-semibold dark:text-[#fff] pt-1">{TotalTeacherFTE[0]?.VALUE?.toLocaleString()}</h4>
                        </div>
                        </LoaderContainer>
                    </div>

                    {/* <div className="flex items-start gap-3">
                        <div className="pt-2">
                            <p className="dark:text-white font-[400] text-[14px] mb-1">
                                Student/Teacher  Ratio</p>
                            <h4 className="text-[16px]  font-semibold  dark:text-[#fff] pt-1">11.62</h4>
                        </div>
                    </div> */}
                </div>
              

                <div className="border-t border-[#ffffff26]">
                    <div className="px-2 mt-2">

                        <div className="flex justify-between">
                            <div className="dark:text-white text-[14px] font-[400] text-left mx-3 mb-2 EnrollmentTitle">Teacher FTE</div>
                            {/* <p className="text-[9.5px] text-[#ebebeb] font-light mb-1"> Administrators</p> */}
                        </div>
                    </div>

                </div>

                <LoaderContainer loading = {TeacherFTEloading}>
                <div className="px-2 mt-1">
                    <ReactEcharts option={SchoolDistrictEmployeesnew} style={{ height: '150px', maxWidth: screenWidth <= 1450 && screenWidth >= 865  ? 275 : 300 }} />
                </div>
                </LoaderContainer>
                <div className="border-t border-[#ffffff26]">
                    <div className="grid grid-cols-2 gap-6 px-3 mt-1 py-5">
                        <LoaderContainer loading ={StudentTeacherRatioloading}>
                        <div className="flex items-start gap-3">
                            <div className="">
                                <p className="text-[14px] font-[400] dark:text-white mb-1">Student/ Teacher Ratio</p>
                                <h4 className="text-[12px]  font-semibold ">{StudentTeacherRatio[0]?.VALUE}</h4>
                            </div>
                        </div>
                        </LoaderContainer>
                        <div className="flex items-start gap-3">
                            <div className="">
                                <p className="text-[14px] font-[400]  dark:text-white mb-2">
                                    Student/ Counselor Ratio</p>
                                <h4 className="text-[12px]">--</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
