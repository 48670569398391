import ReactECharts from 'echarts-for-react';


export default function VerticalChart({data,valueType,legends}){
  let valuesLength;
  const d1 = data?.map((item) => {
      return  valuesLength = Object.keys(item.values).length;
  });


    const ChartData = data
    let ChartSeriesData = (data?.map(x=>x?.values)).reduce((acc,item)=>{
      Object.entries(item).map(([key,value])=>{
      if(!acc[key]){
        acc[key]=[]
      }
      acc[key].push(value)
      // acc[key]?acc[key].push(value):acc[key]=[]
      })
      return acc;
    },{})
    console.log("ChartData",ChartSeriesData);

    const getDataZoom = (data) =>{
      const dataLength = Object.values(data).reduce((sum,value) => sum+value?.length,0);
      const visibleDataCount = 12; // Number of bars to be displayed without collision
  
      if (dataLength > visibleDataCount) {
        const zoomPercent = (visibleDataCount / dataLength) * 100;
        return {
          type: 'slider',
          show: true,
          xAxisIndex:[0],
          handleSize: '100%',
          brushSelect: false,
          height: 10,
          bottom: 50,
          zoomLock: true,
          start:0,
          end: zoomPercent?.toFixed(0),
          showDetail: false
        };

      } 
      else {
        return [];
      }
    }

    const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          }
        },
        grid:{
            top:'10%',
            bottom:'30%',
            left:'6%',
            right:'8%',
            // containLabel: true,
        },
        legend: valuesLength > 6 ? legends : {
          data: Object.keys(ChartSeriesData),//["Urban", "Rural", "Bus", "Rail"],
          bottom:0,
          width : '100%',
          textStyle:{
            color: '#f0f0f0',
            fontSize: 9,
          },
          // itemWidth: 8, 
          // itemHeight: 8
          
        //   left :'left',
          // itemGap : 20
        },
        xAxis: [
          {
            type: "category",
            data: ChartData.map(x=>x?.label),//["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"],
            axisPointer: {
              type: "shadow"
            },
            axisTick:{
                show: false
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            // max: 60,
            // interval: 10,
            axisLabel: {
              formatter: "{value}"
            },
            axisLine:{
                show:true
            },
            splitLine:{
              show:true,
                lineStyle: {
                    type: 'dashed',
                    width:"1",
                    color:'#353F4D',
                    dashOffset: ['5', '10']
                }
            }
            // splitLine:{
            //   show :true,
            //   lineStyle :{
            //       type:"dashed",
            //       width:"1",
            //       color:'#565A65',
            //   }
            // }
          },
          {
            type: "value",
            // min: 75,
            // max: 105,
            // interval: 5,
            axisLabel: {
              formatter: "{value}%"
              // formatter:function(value,index){
              //   if(value[index] === 0){
              //     return '';
              //   }
              //   else{
              //     return value;
              //   }
              // }
            },
            splitLine:{
              show:false,
                lineStyle: {
                    type: 'dashed',
                    opacity:'0.5',
                    dashOffset: ['1', '10']
                }
            }
          }
        ],
        // series: [
        //   {
        //     name: "Urban",
        //     type: "bar",
        //     barGap:0,
        //     data: ChartSeriesData?.[DropdownTitle]
        //     ,//[10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 50],
        //     label: {
        //       show: true,
        //       position: 'insideTop',
        //       color:"#E5E7EB",
        //       rotate :270,
        //       offset:[5,-3],
        //       fontSize:8
        //     },
        //     itemStyle : {
        //       color : "#1B4EB2"
        //     }
        //   },
        //   {
        //     name: "Rural",
        //     type: "bar",
        //     barGap:0,
        //     data: [9, 14, 19, 24, 29, 34, 39, 44, 49, 54, 59, 49],
        //     label: {
        //       show: true,
        //       position: 'insideTop',
        //       color:"#E5E7EB",
        //       rotate :270,
        //       offset:[5,-3],
        //       fontSize:8
        //     },
        //     itemStyle : {
        //       color : "#1861DD"
        //     }
        //   },
        //   {
        //     name: "Bus",
        //     type: "bar",
        //     barGap:0,
        //     data: [8, 13, 18, 23, 28, 33, 38, 43, 48, 53, 58, 48],
        //     label: {
        //       show: true,
        //       position: 'insideTop',
        //       color:"#E5E7EB",
        //       rotate :270,
        //       offset:[5,-3],
        //       fontSize:8

        //     },
        //     itemStyle : {
        //       color : "#1570EF"
        //     }
        //   },
        //   {
        //     name: "Rail",
        //     type: "bar",
        //     barGap:0,
        //     data: [7, 12, 17, 22, 27, 32, 37, 42, 47, 52, 57, 47],
        //     label: {
        //       show: true,
        //       position: 'insideTop',
        //       color:"#E5E7EB",
        //       rotate :270,
        //       offset:[5,-3],
        //       fontSize:8

        //     },
        //     itemStyle : {
        //       color : "#3696FB"
        //     }
        //   }
        // ]
        series: Object.entries(ChartSeriesData).map(([key,value])=>{
              return {
                  name: `${key}`,
                  type: "bar",
                  // barGap:0,
                  // barMinWidth:30,
                  data: value,
                  label: {
                    show: true,
                    position: 'insideTop',
                    color:"#E5E7EB",
                    rotate :90,
                    offset:[-14,-4],
                    fontSize:8,
                    formatter: params => valueType=='percentage' ? params.value?.toFixed(1) + "%" : Math.floor(params.value)
                  },
                  // itemStyle : {
                  //   color : "#1B4EB2"
                  // },
                  itemStyle : key == "White" ? { color : "#377ab8" } : "" 
                            || key == "Asian" ? { color : "#6f2e66" } : ""
                            || key == "Black/African-American" ? { color : "#2c4990" } : "" 
                            || key == "Hispanic/Latino" ? { color : "#41a5ac" } : "" 
                            || key == "Multi-Racial" ? { color : "#393469" } : "" 
                            || key == "American Indian/Alaska Native" ? { color : "#6f2e66" } : "" 
                            || key == 'Male' ? { color : "#41a5ac" } : ""
                            || key == 'Secondary Schools' ? { color : "#41a5ac" } : ""
                            ,
                  tooltip : {
                    valueFormatter: value => valueType=='percentage' ? value?.toFixed(1) + "%" : Math.floor(value)
                  }
          }
        }),
        dataZoom: getDataZoom(ChartSeriesData)
      }
    
    return(
        <>
        <ReactECharts 
        option={option}
        style={{width: '50vw',height:'22vw'}}
        notMerge={true}
        />
        </>
    )
}