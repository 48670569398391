

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import StudentCenter_bannerImg from "../../assets/images/school&center_banner.jpeg"
import SchoolDarkImg from "../../assets/images/school.png"
import SchoolLightImg from "../../assets/images/schoolLight.png"

import TargetImg from "../../assets/images/target.png"
import CareImg from "../../assets/images/care.png"
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSchool_And_Centers, fetchSchools_Centers_Report
} from '../../redux/slices/counter';
import LoaderContainer from "../../components/loaderContainer";


export default function SchoolsCenters() {
  const dispatch = useDispatch();
  const DarkMode = useSelector((state) => state.global.DarkMode);
  const SchoolImg = DarkMode ? SchoolDarkImg : SchoolLightImg;
  const School_And_Centersdata = useSelector(state => state.netsales.School_And_Centers);
  const School_And_Centersdataloading = useSelector(state => state.netsales.School_And_Centersloading);
  const Schools_Centers_Reportdata = useSelector(state => state.netsales.Schools_Centers_Report);
  useEffect(() => {
    dispatch(fetchSchool_And_Centers({
      "elasticQueryName": "School_And_Centers",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2024"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
    dispatch(fetchSchools_Centers_Report({
      "elasticQueryName": "Schools_Centers_Report",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2024"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
  }, [])

  const[screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
  const handleResize = () => {
  if (typeof window !== 'undefined') {
      const screenWidth = window.innerWidth;
      setScreenWidth(screenWidth);
      console.log(screenWidth,"setScreenWidth");
      }
  };
  window.addEventListener('resize', handleResize);
  handleResize();
  return () => {
  window.removeEventListener('resize', handleResize);
  };
  }, []); 

  const StudentCenters = {
    // tooltip: {
    //   trigger: 'item',
    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    //   axisPointer: {
    //     type: 'shadow'
    //   }
    // },
    tooltip: {
      trigger: "item",
      backgroundColor: DarkMode ? "black" : "white",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: DarkMode ? "white" : "black",
      },
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params) {
        return (
          params.name + " : " + params.percent.toFixed(2) + "%"
        );
      },
      confine: true
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '10%',
      top: "0%",
      height: "130%",
      containLabel: true
    },
    legend: {
      orient: 'vertical',
      center: 'center',
      textStyle: {
        padding: [4, 20, 4, 20],
        borderRadius: 4
      },
      // right: '5%',
      // left: "60%",
      itemWidth: 8.5,
      itemHeight: 6,
      bottom: 170,
      textStyle: {
        color: DarkMode ? "white" : "black",
        fontSize: '11',
        fontStyle: 'normal',
        fontFamily: 'Roboto, sans-serif',
      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: ['20%', '45%'],
        center: ['52%', '40%'],

        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'left',
          formatter: '{d}%',
          position: 'inside',
          textStyle: {
            fontSize: '8',
            color: "#fff"
          }
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 10,
        //     fontWeight: 'bold'
        //   }
        // },
        labelLine: {
          show: false
        },
        data: [
          {
            value: School_And_Centersdata[0]?.VALUE,
            name: School_And_Centersdata[0]?.LABEL,
            itemStyle:
            {
              color: '#377ab8'
            }
          },
          {
            value: School_And_Centersdata[1]?.VALUE,
            name: School_And_Centersdata[1]?.LABEL,
            itemStyle:
            {
              color: '#6f2e66'
            }
          },
          {
            value: School_And_Centersdata[2]?.VALUE,
            name: School_And_Centersdata[2]?.LABEL,
            itemStyle:
            {
              color: '#2c4990'
            }
          },
          {
            value: School_And_Centersdata[3]?.VALUE,
            name: School_And_Centersdata[3]?.LABEL,
            itemStyle:
            {
              color: '#41a5ac'
            }
          },
          {
            value: School_And_Centersdata[4]?.VALUE,
            name: School_And_Centersdata[4]?.LABEL,
            itemStyle:
            {
              color: '#393469'
            }
          },
        ]
      }
    ]
  };

  return (
    <div className="EnrollmentTile">
      <div className="relative h-[130px]">
        <div className=" h-[130px] ">
          <img src={StudentCenter_bannerImg} className="h-[130px] w-full " />
        </div>
        <div className="w-full absolute bottom-0 h-[27px] bg-[#1d192f9c] py-1 px-3">
          <h3 className="text-[14px] font-[400] text-[#fff]">Schools & Centers</h3>
        </div>

      </div>
      <div className="grid grid-cols-2 gap-10 p-[5px] mt-1 mb-[8px]">
        <div className="flex items-start gap-3">
          <img src={SchoolImg} className="h-[38px] w-[38px]" />
          <div className="pt-2">
            <p className="dark:text-[#fff] text-[14px] font-[400] mb-1">
              Total Schools</p>
            <h4 className="text-[16px]  font-semibold ">{Schools_Centers_Reportdata?.filter(item => item?.LABEL?.includes("Total"))[0]?.COUNT}</h4>
          </div>
        </div>

        {/* <div className="flex items-start gap-3">
          <div className="pt-2">
            <p className="dark:text-[#fff] text-[14px] font-[400] mb-1">
              Distinguished schools</p>
            <h4 className="text-[16px]  font-semibold ">{Schools_Centers_Reportdata?.filter(item => item?.LABEL?.includes("Distinguished"))[0]?.COUNT}</h4>
          </div>
        </div> */}
      </div>
      <div className="border-t border-[#ffffff26]">
        
        <div className="mt-5 mb-0 StudentCenters" style={{height:'395px'}}>
        <LoaderContainer loading={School_And_Centersdataloading}>
          <ReactEcharts style={{maxWidth:screenWidth <= 1450 && screenWidth >= 865  ? 250 : 300}} option={StudentCenters} />
          </LoaderContainer>
        </div>
       
      </div>

      {/* <div className="grid grid-cols-2 gap-10 px-5 border-t py-3 border-[#ffffff26] mt-10">
        <div className="flex items-start gap-3">
          <img src={TargetImg} className="h-[38px] w-[38px]" />
          <div className="pt-2">
            <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-1">Regular Classified People</p>
            <h4 className="text-[20px] text-[#fff]">459</h4>
          </div>
        </div>
        <div className="flex items-start gap-3">
          <img src={CareImg} className="h-[38px] w-[38px]" />
          <div className="pt-2">
            <p className="text-[9.5px] text-[#ebebeb] font-light opacity-80 mb-3">
              National Board Certified Teacher</p>
            <h4 className="text-[20px] text-[#fff]">197</h4>
          </div>
        </div>
      </div> */}
    </div>
  )
}