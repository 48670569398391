import React from 'react';
import PropTypes from 'prop-types';

export default class Schema extends React.Component {

    static metadata = {
        displayName: 'Tree Graph Component',
        container: false,
        visual: true,
        builderCompatible: true,
        thumbnail: undefined,
        categories: ['General']
    };

    static propTypes = {
        /**
         * @{label=Data}
         */
        data: PropTypes.array.isRequired,
        /**
         * @{label=Theme}
         */
        theme: PropTypes.bool
    };

    static defaultProps = {
        classes: ['is-hu-30'],
        data: [
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Proficiency",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Proficiency",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 4,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Proficiency",
                Periods: "International",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Proficiency",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Achievement",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Achievement",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 4,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Achievement",
                Periods: "International",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Achievement",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 1",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 40,
                days_value: 20,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Proficiency",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 30,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Proficiency",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 30,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 30,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Proficiency",
                Periods: "International",
                Types_value: 450,
                Routes_value: 30,
                days_value: 20,
                Periods_value: 10,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Proficiency",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 30,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 30,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Achievement",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 30,
                days_value: 10,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 30,
                days_value: 10,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Achievement",
                Periods: "International",
                Types_value: 450,
                Routes_value: 30,
                days_value: 10,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 2",
                Days: "Certification of Achievement",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 30,
                days_value: 10,
                Periods_value: 4,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Proficiency",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 70,
                days_value: 25,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Proficiency",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 70,
                days_value: 25,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 70,
                days_value: 25,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Proficiency",
                Periods: "International",
                Types_value: 450,
                Routes_value: 70,
                days_value: 25,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Proficiency",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 70,
                days_value: 25,
                Periods_value: 8,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 70,
                days_value: 25,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Achievement",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 70,
                days_value: 45,
                Periods_value: 10,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 70,
                days_value: 45,
                Periods_value: 12,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Achievement",
                Periods: "International",
                Types_value: 450,
                Routes_value: 70,
                days_value: 45,
                Periods_value: 8,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 3",
                Days: "Certification of Achievement",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 70,
                days_value: 45,
                Periods_value: 15,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Proficiency",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 50,
                days_value: 30,
                Periods_value: 10,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Proficiency",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 50,
                days_value: 30,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 50,
                days_value: 30,
                Periods_value: 5,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Proficiency",
                Periods: "International",
                Types_value: 450,
                Routes_value: 50,
                days_value: 30,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Proficiency",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 50,
                days_value: 30,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 50,
                days_value: 30,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Achievement",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 50,
                days_value: 20,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Achievement",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 50,
                days_value: 20,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 50,
                days_value: 20,
                Periods_value: 10,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Achievement",
                Periods: "International",
                Types_value: 450,
                Routes_value: 50,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Achievement",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 50,
                days_value: 20,
                Periods_value: 2,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 4",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 50,
                days_value: 20,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Proficiency",
                Periods: "Transfers",
                Types_value: 450,
                Routes_value: 20,
                days_value: 15,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Proficiency",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 20,
                days_value: 15,
                Periods_value: 4,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Proficiency",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 20,
                days_value: 15,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Proficiency",
                Periods: "International",
                Types_value: 450,
                Routes_value: 20,
                days_value: 15,
                Periods_value: 3,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Proficiency",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 20,
                days_value: 15,
                Periods_value: 4,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Achievement",
                Periods: "Out of State",
                Types_value: 450,
                Routes_value: 20,
                days_value: 5,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 20,
                days_value: 5,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Achievement",
                Periods: "International",
                Types_value: 450,
                Routes_value: 20,
                days_value: 5,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Achievement",
                Periods: "Neighborhood",
                Types_value: 450,
                Routes_value: 20,
                days_value: 5,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            },
            {
                label: "Students",
                Types: 'Local',
                Routes: "Campus 5",
                Days: "Certification of Achievement",
                Periods: "In State",
                Types_value: 450,
                Routes_value: 20,
                days_value: 5,
                Periods_value: 1,
                metricType: "number",
                actual: 700,
            }


        ],
        nestedFields: ['Routes', 'Days', 'Periods'],
        valueField: 'Actual',
        valueFields: ['Routes_value', 'days_value', 'Periods_value'],
        theme: true,
        modal: {
            visible: false, 
            width: 'var(--is-size-50)',
            data: [
                { id: "Level1", value: "Level1" },
                { id: "Level2", value: "Level2" },
                { id: "Level3", value: "Level3" }
            ]
        }

    };

    static i18n = {
        default: "en-US"
    };

}