import React from 'react'
import { useState,useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import "./index.css";
import { useDispatch, useSelector } from 'react-redux';
import {fetchStudent_Enrollment_Report,fetchStudent_Characteristics,fetchStudent_Group,fetchStudent_Enrollment_By_Year,
  fetchStudent_Enrollment_By_Grade,fetchSchool_And_Centers,fetchSchools_Centers_Report,fetchAt_A_Glance_Top_Tile,
  fetchTeacherFTE, fetchTotalTeacherFTE,fetchStudentTeacherRatio
  } from '../../redux/slices/counter';
  import { Dropdown } from 'primereact/dropdown';

  import {fetchEnrollment,fetchSpecial_Group,fetchEthnicity_Mix,fetchSPED_Enrollment,fetchNet_Movement,fetchGraduation_To_KG_Enrollment_Ratio,
    fetchInter_School_Conversion,fetchSchools_Biggest_Gainers,fetchSchools_Biggest_Losers,fetchTime_Analysis,fetchEnrollment_Time_Analysis,
    fetchEnrollment_Summary_By_Dimensions
} from '../../redux/slices/enrollment'; 

import {fetchDistrict_Climate_Rating,fetchstudent_behavior_poor_performing_schools,fetchstudent_behavior_top_performing_schools,
  fetchStudent_Behavior_Incident_Type,fetchStudent_Behavior_Chart
} from '../../redux/slices/studentbehavior';

import {fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART,fetchImprovement_Tile_Report,fetchStrength_Tile_Report,fetchWeakness_Tile_Report
} from '../../redux/slices/strengthweakness';

import {fetchstudent_performance_tile_report, fetchstudent_dimension_table_report, fetchstudent_dimension_report
} from '../../redux/slices/studentperformance';

import {fetchSchool_Performance_School_Type_Report,fetchSchool_Performance_Metric_Report,
  fetchSchool_Performance_Map_Report,fetchSchool_Performance_Pie_Chart_Report
} from '../../redux/slices/schoolperformance';

import { fetchIgniteLearning,fetchCultivateTrust} from '../../redux/slices/goalsandstrategy';

import { fetchMenu_School_Year,fetchMenu_School_Level,fetchMenu_School_Name,fetchMenu_Gender,fetchMenu_Grade,fetchMenu_Ethnicity,fetchMenu_Ell,fetchMenu_Disabilities,fetchMenu_Economically,fetchMenu_Homeless  } from '../../redux/slices/filter';


function FilterComponent() {
    var pagename = window.location.pathname;
    // const yearOptions=[
    //     {name:"2023",value:"2023"},
    //     {name:"2022",value:"2022"},
    //     {name:"2021",value:"2021"},
    //     {name:"2020",value:"2020"},
    //     {name:"2019",value:"2019"},
    // ]
    // let schoolTypeOptions=[
    //     {label:"High School",value:"High School"},
    //     {label:"Middle School",value:"Middle School"},
    //     {label:"Elementory School",value:"Elementory School"},
    //     {label:"Not Reported",value:"Not Reported"},
    //     {label:"Other Combination",value:"Other Combination"},
    // ]

    // let genderOptions=[
    //     {label:"Male",value:"Male"},
    //     {label:"Female",value:"Female"},
    //     {label:"Others",value:"Others"}
    // ]

    // let ethinOption=[
    //     {label:"Hispanic",value:"Hispanic"},
    //     {label:"Not Reported",value:"Not Reported"},
    //     {label:"Multi-racial",value:"Multi-racial"},
    //     {label:"Black Non-Hispanic",value:"Black Non-Hispanic"},
    //     {label:"White Non-Hispanic",value:"White Non-Hispanic"},
    //     {label:"Asian or pasific Islander",value:"Asian or pasific Islander"},
    //     {label:"Amer Indian or Alaskan Native",value:"Amer Indian or Alaskan Native"}
    // ]

    // let gradeOptions=[
    //     {label:"Preschool/Prekindergarten",value:"Preschool/Prekindergarten"},
    //     {label:"KinderGarten",value:"KinderGarten"},
    //     {label:"First Grade",value:"First Grade"},
    //     {label:"Second Grade",value:"Second Grade"},
    //     {label:"Third Grade",value:"Third Grade"},
    //     {label:"Fourth Grade",value:"Fourth Grade"},
    //     {label:"Fifth Grade",value:"Fifth Grade"},
    //     {label:"Sixth Grade",value:"Sixth Grade"},
    //     {label:"Seventh Grade",value:"Seventh Grade"},
    //     {label:"Eighth Grade",value:"Eighth Grade"},
    //     {label:"Ninth Grade",value:"Ninth Grade"},
    //     {label:"Tenth Grade",value:"Tenth Grade"},
    //     {label:"Eleventh Grade",value:"Eleventh Grade"},
    //     {label:"Twelfth Grade",value:"Twelfth Grade"},
    //     {label:"Early Education",value:"Early Education"}
    // ]

    const Menu_School_Yeardata = useSelector(state => state.filter.Menu_School_Year);
    const Menu_School_Leveldata = useSelector(state => state.filter.Menu_School_Level);
    const Menu_School_Namedata = useSelector(state => state.filter.Menu_School_Name);
    const Menu_Gender = useSelector(state => state.filter.Menu_Gender);
    const Menu_Grade = useSelector(state => state.filter.Menu_Grade);
    const Menu_Ethnicity = useSelector(state => state.filter.Menu_Ethnicity);
    const Menu_Ell = useSelector(state => state.filter.Menu_Ell);
    const Menu_Disabilities = useSelector(state => state.filter.Menu_Disabilities);
    const Menu_Economically = useSelector(state => state.filter.Menu_Economically);
    const Menu_Homeless = useSelector(state => state.filter.Menu_Homeless);


    const [selectedMenu_School_Year, setselectedMenu_School_Year] = useState(Menu_School_Yeardata)
    const [selectedMenu_School_Level, setSelectedMenu_School_Level] = useState(Menu_School_Leveldata)
    const [selectedMenu_School_Name, setSelectedMenu_School_Name] = useState(Menu_School_Namedata)
    const [selectedMenu_Gender, setSelectedMenu_Gender] = useState(Menu_Gender)
    const [selectedMenu_Grade, setSelectedMenu_Grade] = useState(Menu_Grade)
    const [selectedMenu_Ethnicity, setSelectedMenu_Ethnicity] = useState(Menu_Ethnicity)
    const [selectedMenu_Ell, setSelectedMenu_Ell] = useState(Menu_Ell)
    const [selectedMenu_Disabilities, setSelectedMenu_Disabilities] = useState(Menu_Disabilities)
    const [selectedMenu_Economically, setSelectedMenu_Economically] = useState(Menu_Economically)
    const [selectedMenu_Homeless, setSelectedMenu_Homeless] = useState(Menu_Homeless)

    var yearOptions = [];
    selectedMenu_School_Year?.forEach(year => {
      yearOptions.push({ 'name': year.SCHOOL_YEAR?.toString(), 'value': year.SCHOOL_YEAR?.toString() })
    })
    // console.log("yearOptions",yearOptions)

    var schoolTypeOptions = [];
    selectedMenu_School_Level?.forEach(year => {
      schoolTypeOptions.push({ 'label': year.SCHOOL_LEVEL, 'value': year.SCHOOL_LEVEL })
    })
    // console.log("schoolTypeOptions",schoolTypeOptions)


    var genderOptions = [];
    selectedMenu_Gender?.forEach(year => {
      genderOptions.push({ 'label': year.GENDER, 'value': year.GENDER })
    })


    var gradeOptions = [];
    selectedMenu_Grade?.forEach(year => {
      gradeOptions.push({ 'label': year.GRADE, 'value': year.GRADE })
    })

    var ethinOption = [];
    selectedMenu_Ethnicity?.forEach(year => {
      ethinOption.push({ 'label': year.ETHNICITY, 'value': year.ETHNICITY })
    })

    var schoolnameOption = [];
    selectedMenu_School_Name?.forEach(year => {
      schoolnameOption.push({ 'label': year.SCHOOL_NAME, 'value': year.SCHOOL_NAME })
    })

    var ellOption = [];
    selectedMenu_Ell?.forEach(year => {
      ellOption.push({ 'label': year.ENGLISH_LANGUAGE_LEARNERS, 'value': year.ENGLISH_LANGUAGE_LEARNERS })
    })

    var disabilitiesOption = [];
    selectedMenu_Disabilities?.forEach(year => {
      disabilitiesOption.push({ 'label': year.STUDENTS_WITH_DISABILITIES, 'value': year.STUDENTS_WITH_DISABILITIES })
    })

    var economicOption = [];
    selectedMenu_Economically?.forEach(year => {
      economicOption.push({ 'label': year.ECONOMICALLY_DISADVANTAGED, 'value': year.ECONOMICALLY_DISADVANTAGED })
    })

    var homelessOption = [];
    selectedMenu_Homeless?.forEach(year => {
      homelessOption.push({ 'label': year.HOMELESS, 'value': year.HOMELESS })
    })

    const dispatch = useDispatch();

   const [selectedYearreset, setSelectedYearreset] = useState({
    "columnName": "SCHOOL_YEAR",
    "columnValue": "2024",
    "excludeKeyword": false
   })
    const [selectedSchoolreset, setSelectedSchoolreset] = useState({
        "columnName": "SCHOOL_LEVEL",
        "columnValue": [],
        "excludeKeyword": false
    })

    const [selectedGenderreset, setSelectedGenderreset] = useState({
    "columnName": "STUDENT_GENDER",
    "columnValue": [],
    "excludeKeyword": false
    })

    const [selectedEthincityreset, setSelectedEthincityreset] = useState({
        "columnName": "STUDENT_ETHNICITY",
        "columnValue": [],
        "excludeKeyword": false
    }) 

    const [selectedGradereset, setSelectedGradereset] = useState({
        "columnName": "STUDENT_GRADE",
        "columnValue": [],
        "excludeKeyword": false
    }) 

    const [selectedschoolnamereset, setSelectedschoolnamereset] = useState({
      "columnName": "SCHOOL_OFFICIAL_NAME",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedEllreset, setSelectedEllreset] = useState({
      "columnName": "STUDENT_ELL",
      "columnValue": [],
      "excludeKeyword": false
    })

    const [selecteddisabilitiesreset, setSelecteddisabilitiesreset] = useState({
      "columnName": "STUDENT_SPECIAL_ABILITY",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedEconomicallyDisadvantagedreset, setSelectedEconomicallyDisadvantagedreset] = useState({
      "columnName": "STUDENT_ECONOMICALLY_DISADVANTAGED",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedHomelessreset, setSelectedHomelessreset] = useState({
      "columnName": "STUDENT_HOMELESS",
      "columnValue": [],
      "excludeKeyword": false
    }) 
    

    const resetFilterFunc = () => {
      // window.sessionStorage.removeItem('allfilter');
      setSelectedYeardata("2024")
      setSelectedSchooldata([])
      setSelectedGenderdata([])
      setSelectedEthincitydata([])
      setSelectedGradedata([])
      setSelectedSchoolnamedata([])
      setSelectedElldata([])
      setSelectedDisabilitiesdata([])
      setSelectedEconomicallyDisadvantageddata([])
      setSelectedHomelessdata([])

      const body = {
        "elasticQueryName": "",
        "filters":
          removeNullFun1()
        ,
        "dynamicColumns": [],
      };
      handleChange(body)
    }

    const removeNullFun1 = () => { 
      let j = [
          selectedYearreset.columnValue.length && selectedYearreset,
          selectedSchoolreset.columnValue.length && selectedSchoolreset,
          selectedGenderreset.columnValue.length && selectedGenderreset,
          selectedEthincityreset.columnValue.length && selectedEthincityreset,
          selectedGradereset.columnValue.length && selectedGradereset,
          selectedschoolnamereset.columnValue.length && selectedschoolnamereset,
          selectedEllreset.columnValue.length && selectedEllreset,
          selecteddisabilitiesreset.columnValue.length && selecteddisabilitiesreset,
          selectedEconomicallyDisadvantagedreset.columnValue.length && selectedEconomicallyDisadvantagedreset,
          selectedHomelessreset.columnValue.length && selectedHomelessreset,
         ]
        //  console.log("jjjjjjjjj",j)
        return j.filter(val => val !== 0);  
   }

// ---------------------------------------------------------------------------------------------------------
    const [selectedYear, setSelectedYear] = useState({
        "columnName": "SCHOOL_YEAR",
        "columnValue": [],
        "excludeKeyword": false
      })
    const [selectedSchool, setSelectedSchool] = useState({
        "columnName": "SCHOOL_LEVEL",
        "columnValue": [],
        "excludeKeyword": false
    })

    const [selectedGender, setSelectedGender] = useState({
    "columnName": "STUDENT_GENDER",
    "columnValue": [],
    "excludeKeyword": false
    })
    
    const [selectedEthincity, setSelectedEthincity] = useState({
        "columnName": "STUDENT_ETHNICITY",
        "columnValue": [],
        "excludeKeyword": false
    }) 

    const [selectedGrade, setSelectedGrade] = useState({
        "columnName": "STUDENT_GRADE",
        "columnValue": [],
        "excludeKeyword": false
    }) 

    const [selectedschoolname, setSelectedschoolname] = useState({
      "columnName": "SCHOOL_OFFICIAL_NAME",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedEll, setSelectedEll] = useState({
      "columnName": "STUDENT_ELL",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedDisabilities, setSelectedDisabilities] = useState({
      "columnName": "STUDENT_SPECIAL_ABILITY",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedEconomicallyDisadvantaged, setSelectedEconomicallyDisadvantaged] = useState({
      "columnName": "STUDENT_ECONOMICALLY_DISADVANTAGED",
      "columnValue": [],
      "excludeKeyword": false
    }) 

    const [selectedHomeless, setSelectedHomeless] = useState({
      "columnName": "STUDENT_HOMELESS",
      "columnValue": [],
      "excludeKeyword": false
    }) 

// ---------------------------------------------------------------------------------------------
      const [selectedYearFilter, setSelectedYearFilter] = useState({
        "columnName": "SCHOOL_YEAR",
        "columnValue": [],
        "excludeKeyword": false
      })
      const [selectedSchoolFilter, setSelectedSchoolFilter] = useState({
        "columnName": "SCHOOL_LEVEL",
        "columnValue": [],
        "excludeKeyword": false
      })
      const [selectedGenderFilter, setSelectedGenderFilter] = useState({
      "columnName": "GENDER",
      "columnValue": [],
      "excludeKeyword": false
      })

      const [selectedEthincityFilter, setSelectedEthincityFilter] = useState({
        "columnName": "ETHNICITY",
        "columnValue": [],
        "excludeKeyword": false
      }) 

      const [selectedGradeFilter, setSelectedGradeFilter] = useState({
        "columnName": "GRADE",
        "columnValue": [],
        "excludeKeyword": false
      }) 

      const [selectedschoolnameFilter, setSelectedschoolnameFilter] = useState({
      "columnName": "SCHOOL_NAME",
      "columnValue": [],
      "excludeKeyword": false
      }) 

      const [selectedEllFilter, setSelectedEllFilter] = useState({
        "columnName": "ENGLISH_LANGUAGE_LEARNERS",
        "columnValue": [],
        "excludeKeyword": false
      }) 
  
      const [selectedDisabilitiesFilter, setSelectedDisabilitiesFilter] = useState({
        "columnName": "STUDENTS_WITH_DISABILITIES",
        "columnValue": [],
        "excludeKeyword": false
      }) 
  
      const [selectedEconomicallyDisadvantagedFilter, setSelectedEconomicallyDisadvantagedFilter] = useState({
        "columnName": "ECONOMICALLY_DISADVANTAGED",
        "columnValue": [],
        "excludeKeyword": false
      }) 
  
      const [selectedHomelessFilter, setSelectedHomelessFilter] = useState({
        "columnName": "HOMELESS",
        "columnValue": [],
        "excludeKeyword": false
      }) 

// useEffect(()=>{
//     dispatch(fetchMenu_School_Year({
//       "elasticQueryName": "Menu_School_Year",
//       "filters": [],
//       "dynamicColumns": [],
//     }));

//     dispatch(fetchMenu_School_Level({
//       "elasticQueryName": "Menu_School_Level",
//       "filters": [{
//         "columnName": "SCHOOL_YEAR",
//         "columnValue": ["2022"],
//         "excludeKeyword": false
//       },
//     ],
//       "dynamicColumns": [],
//     }));

//     dispatch(fetchMenu_School_Name({
//       "elasticQueryName": "Menu_School_Name",
//       "filters": [{
//         "columnName": "SCHOOL_YEAR",
//         "columnValue": ["2022"],
//         "excludeKeyword": false
//       }],
//       "dynamicColumns": [],
//     }));

//     dispatch(fetchMenu_Gender({
//       "elasticQueryName": "Menu_Gender",
//       "filters": [{
//         "columnName": "SCHOOL_YEAR",
//         "columnValue": ["2022"],
//         "excludeKeyword": false
//       }],
//       "dynamicColumns": [],
//     }));

//     dispatch(fetchMenu_Grade({
//       "elasticQueryName": "Menu_Grade",
//       "filters": [{
//         "columnName": "SCHOOL_YEAR",
//         "columnValue": ["2022"],
//         "excludeKeyword": false
//       }],
//       "dynamicColumns": [],
//     }));

//     dispatch(fetchMenu_Ethnicity({
//       "elasticQueryName": "Menu_Ethnicity",
//       "filters": [{
//         "columnName": "SCHOOL_YEAR",
//         "columnValue": ["2022"],
//         "excludeKeyword": false
//       }],
//       "dynamicColumns": [],
//     }));
//   },[])

useEffect(() => {
    const handleBeforeUnload = () => {
      window.sessionStorage.removeItem('SchoolType');
      window.sessionStorage.removeItem('Year');
      window.sessionStorage.removeItem('Gender');
      window.sessionStorage.removeItem('Ethincity');
      window.sessionStorage.removeItem('Grade');
      window.sessionStorage.removeItem('enrollmentdimension');
      window.sessionStorage.removeItem('allfilter');
      window.sessionStorage.removeItem('EnglishLanguageLearners');
      window.sessionStorage.removeItem('StudentswithDisabilities');
      window.sessionStorage.removeItem('EconomicallyDisadvantaged');
      window.sessionStorage.removeItem('Homeless');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
    let SchoolType =JSON.parse(sessionStorage.getItem("SchoolType"))
    let Year =JSON.parse(sessionStorage.getItem("Year"))
    let Gender =JSON.parse(sessionStorage.getItem("Gender"))
    let Ethincity =JSON.parse(sessionStorage.getItem("Ethincity"))
    let Grade =JSON.parse(sessionStorage.getItem("Grade"))
    let Schoolname =JSON.parse(sessionStorage.getItem("Schoolname"))
    let EnglishLanguageLearners =JSON.parse(sessionStorage.getItem("EnglishLanguageLearners"))
    let StudentswithDisabilities =JSON.parse(sessionStorage.getItem("StudentswithDisabilities"))
    let EconomicallyDisadvantaged =JSON.parse(sessionStorage.getItem("EconomicallyDisadvantaged"))
    let Homeless =JSON.parse(sessionStorage.getItem("Homeless"))

    const[selectedYeardata,setSelectedYeardata]=useState(Year?Year:"2024")
    sessionStorage.setItem("Year", JSON.stringify(selectedYeardata));

    const[selectedSchooldata,setSelectedSchooldata]=useState(SchoolType?SchoolType:[])
    sessionStorage.setItem("SchoolType", JSON.stringify(selectedSchooldata));

    const[selectedGenderdata,setSelectedGenderdata]=useState(Gender?Gender:[])
    sessionStorage.setItem("Gender", JSON.stringify(selectedGenderdata));

    const[selectedEthincitydata,setSelectedEthincitydata]=useState(Ethincity?Ethincity:[])
    sessionStorage.setItem("Ethincity", JSON.stringify(selectedEthincitydata));

    const[selectedGradedata,setSelectedGradedata]=useState(Grade?Grade:[])
    sessionStorage.setItem("Grade", JSON.stringify(selectedGradedata));

    const[selectedSchoolnamedata,setSelectedSchoolnamedata]=useState(Schoolname?Schoolname:[])
    sessionStorage.setItem("Schoolname", JSON.stringify(selectedSchoolnamedata));

    const[selectedElldata,setSelectedElldata]=useState(EnglishLanguageLearners?EnglishLanguageLearners:[])
    sessionStorage.setItem("EnglishLanguageLearners", JSON.stringify(selectedElldata));

    const[selectedDisabilitiesdata,setSelectedDisabilitiesdata]=useState(StudentswithDisabilities?StudentswithDisabilities:[])
    sessionStorage.setItem("StudentswithDisabilities", JSON.stringify(selectedDisabilitiesdata));

    const[selectedEconomicdata,setSelectedEconomicallyDisadvantageddata]=useState(EconomicallyDisadvantaged?EconomicallyDisadvantaged:[])
    sessionStorage.setItem("EconomicallyDisadvantaged", JSON.stringify(selectedEconomicdata));

    const[selectedHomelessdata,setSelectedHomelessdata]=useState(Homeless?Homeless:[])
    sessionStorage.setItem("Homeless", JSON.stringify(selectedHomelessdata));

    useEffect(() => {
      setselectedMenu_School_Year(Menu_School_Yeardata)
        let selectedArray = []
        // selectedYeardata?.map((k) => {
        //   selectedArray.push(k.value)
        // })
        setSelectedYear({
          "columnName": "SCHOOL_YEAR",
          "columnValue": selectedYeardata,
          "excludeKeyword": false
        });
        setSelectedYearFilter({
          "columnName": "SCHOOL_YEAR",
          "columnValue": selectedYeardata,
          "excludeKeyword": false
        });
      }, [selectedYeardata,Menu_School_Yeardata.length]);

      useEffect(() => {
        setSelectedMenu_School_Level(Menu_School_Leveldata)
        let selectedArray = []
        selectedSchooldata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedSchool({
          "columnName": "SCHOOL_LEVEL",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });

        setSelectedSchoolFilter({
          "columnName": "SCHOOL_LEVEL",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedSchooldata,Menu_School_Leveldata.length]); 

      useEffect(() => {
        setSelectedMenu_Gender(Menu_Gender)
        let selectedArray = []
        selectedGenderdata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedGender({
          "columnName": "STUDENT_GENDER",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedGenderFilter({
          "columnName": "GENDER",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedGenderdata,Menu_Gender.length]); 

      useEffect(() => {
        setSelectedMenu_Ethnicity(Menu_Ethnicity)
        let selectedArray = []
        selectedEthincitydata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedEthincity({
          "columnName": "STUDENT_ETHNICITY",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedEthincityFilter({
          "columnName": "ETHNICITY",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedEthincitydata,Menu_Ethnicity.length]); 

      useEffect(() => {
        setSelectedMenu_Grade(Menu_Grade)
        let selectedArray = []
        selectedGradedata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedGrade({
          "columnName": "STUDENT_GRADE",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedGradeFilter({
          "columnName": "GRADE",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedGradedata,Menu_Grade.length]); 

      useEffect(() => {
        setSelectedMenu_School_Name(Menu_School_Namedata)
        let selectedArray = []
        selectedSchoolnamedata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedschoolname({
          "columnName": "SCHOOL_OFFICIAL_NAME",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });

        setSelectedschoolnameFilter({
          "columnName": "SCHOOL_NAME",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedSchoolnamedata,Menu_School_Namedata?.length]); 

      useEffect(() => {
        setSelectedMenu_Ell(Menu_Ell)
        let selectedArray = []
        selectedElldata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedEll({
          "columnName": "STUDENT_ELL",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedEllFilter({
          "columnName": "ENGLISH_LANGUAGE_LEARNERS",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedElldata,Menu_Ell.length]);

      useEffect(() => {
        setSelectedMenu_Disabilities(Menu_Disabilities)
        let selectedArray = []
        selectedDisabilitiesdata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedDisabilities({
          "columnName": "STUDENT_SPECIAL_ABILITY",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedDisabilitiesFilter({
          "columnName": "STUDENTS_WITH_DISABILITIES",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedDisabilitiesdata,Menu_Disabilities.length]);

      useEffect(() => {
        setSelectedMenu_Economically(Menu_Economically)
        let selectedArray = []
        selectedEconomicdata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedEconomicallyDisadvantaged({
          "columnName": "STUDENT_ECONOMICALLY_DISADVANTAGED",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedEconomicallyDisadvantagedFilter({
          "columnName": "ECONOMICALLY_DISADVANTAGED",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedEconomicdata,Menu_Economically.length]);

      useEffect(() => {
        setSelectedMenu_Homeless(Menu_Homeless)
        let selectedArray = []
        selectedHomelessdata?.map((k) => {
          selectedArray.push(k.value)
        })
        setSelectedHomeless({
          "columnName": "STUDENT_HOMELESS",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
        setSelectedHomelessFilter({
          "columnName": "HOMELESS",
          "columnValue": selectedArray,
          "excludeKeyword": false
        });
      }, [selectedHomelessdata,Menu_Homeless.length]);

      
    const applyFilterFunc = () => {

        const body = {
          "elasticQueryName": "",
          "filters":
            removeNullFun()
          ,
          "dynamicColumns": [],
        };
        handleChange(body)
      }
  
      useEffect(() => {
        const bodyFilter = {
          "elasticQueryName": "",
          "filters":
            removeNullFunFilter()
          ,
          "dynamicColumns": [],
        };
        handleChangeFilter(bodyFilter) 
      }, [selectedYearFilter,selectedSchoolFilter,selectedGenderFilter,selectedEthincityFilter,selectedGradeFilter,selectedschoolnameFilter,selectedEllFilter,selectedDisabilitiesFilter,selectedEconomicallyDisadvantagedFilter,selectedHomelessFilter]);
    
      const removeNullFunFilter = () => { 
        let j = [
            selectedYearFilter.columnValue.length && selectedYearFilter,
            selectedSchoolFilter.columnValue.length && selectedSchoolFilter,
            selectedGenderFilter.columnValue.length && selectedGenderFilter,
            selectedEthincityFilter.columnValue.length && selectedEthincityFilter,
            selectedGradeFilter.columnValue.length && selectedGradeFilter,
            selectedschoolnameFilter.columnValue.length && selectedschoolnameFilter,
            selectedEllFilter.columnValue.length && selectedEllFilter,
            selectedDisabilitiesFilter.columnValue.length && selectedDisabilitiesFilter,
            selectedEconomicallyDisadvantagedFilter.columnValue.length && selectedEconomicallyDisadvantagedFilter,
            selectedHomelessFilter.columnValue.length && selectedHomelessFilter,
           ]
          return j.filter(val => val !== 0);
     }
    let enrollmentdimension = sessionStorage.getItem("enrollmentdimension")
    let selectedoptions = sessionStorage.getItem("selectedoptions")
    let selectedoptions1 = sessionStorage.getItem("selectedoptions1")
    let selectedoptions2 = sessionStorage.getItem("selectedoptions2")
    let selectedoptions3 = sessionStorage.getItem("selectedoptions3")
    let selectedmetric = sessionStorage.getItem("selectedmetric")


    let performancesummary = sessionStorage.getItem("performancesummary")

      const handleChange = (body) => {
        // debugger
        var Yearremove = body;
        Yearremove = Yearremove?.filters?.filter((item) => item.columnName != "SCHOOL_YEAR");
        var data1 = {
          "elasticQueryName": "",
          "filters": Yearremove,
          "dynamicColumns": [],
        }
        var dataenrollmentdimension = {
          "elasticQueryName": "",
          "filters": body?.filters,
          "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [enrollmentdimension] }],
        }
        var filtereddata = body?.filters.concat([{ "columnName": "metric_id", "columnValue": [selectedmetric] }])
        // console.log("filtereddata",filtereddata)
        var datasankey= {
          "elasticQueryName": "",
          "filters": filtereddata,
          "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
          { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
          { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
          { columnName: "#{dimension_4}", columnValue: [selectedoptions3] },
        ],
        }

        var filtereddatastudent = body?.filters.concat([{ "columnName": "metric_id", "columnValue": [selectedmetric] }])
        var datastudent= {
          "elasticQueryName": "",
          "filters": filtereddatastudent,
          "dynamicColumns": [{ columnName: "#{dimension}", columnValue: [performancesummary] },
        ],
        }
        
        sessionStorage.setItem("allfilter", JSON.stringify(body));
        if(pagename == '/') {
         dispatch(fetchAt_A_Glance_Top_Tile(body));
         dispatch(fetchStudent_Enrollment_Report(body));
         dispatch(fetchStudent_Characteristics(body));
         dispatch(fetchStudent_Group(body));
         dispatch(fetchStudent_Enrollment_By_Year(data1));
         dispatch(fetchStudent_Enrollment_By_Grade(body));
         dispatch(fetchSchool_And_Centers(body));
         dispatch(fetchSchools_Centers_Report(body));
         let body1 = body
         body1.filters = body1.filters.filter(item=>!['STUDENT_GENDER','STUDENT_ETHNICITY','STUDENT_GRADE','STUDENT_ELL','STUDENT_SPECIAL_ABILITY','STUDENT_ECONOMICALLY_DISADVANTAGED','STUDENT_HOMELESS'].some(x=>x==item.columnName))
         dispatch(fetchTeacherFTE(body1));
         dispatch(fetchTotalTeacherFTE(body1));
         dispatch(fetchStudentTeacherRatio(body1));
        }else if(pagename == '/enrollment'){
          dispatch(fetchEnrollment(body));
          dispatch(fetchSpecial_Group(body));
          dispatch(fetchEthnicity_Mix(body));
          dispatch(fetchSPED_Enrollment(body));
          dispatch(fetchNet_Movement(body));
          dispatch(fetchGraduation_To_KG_Enrollment_Ratio(body));
          dispatch(fetchInter_School_Conversion(body));
          dispatch(fetchSchools_Biggest_Gainers(body));
          dispatch(fetchSchools_Biggest_Losers(body));
          dispatch(fetchTime_Analysis(data1));
          dispatch(fetchEnrollment_Time_Analysis(body));
          dispatch(fetchEnrollment_Summary_By_Dimensions(dataenrollmentdimension));
        }else if(pagename == '/studentbehaviour'){
          dispatch(fetchDistrict_Climate_Rating(body));
          dispatch(fetchstudent_behavior_poor_performing_schools(body));
          dispatch(fetchstudent_behavior_top_performing_schools(body));
          dispatch(fetchStudent_Behavior_Incident_Type(body));
          dispatch(fetchStudent_Behavior_Chart(data1));
        }else if(pagename == '/StrengthWeakness'){
          dispatch(fetchImprovement_Tile_Report(body));
          dispatch(fetchStrength_Tile_Report(body));
          dispatch(fetchWeakness_Tile_Report(body));
          dispatch(fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART(datasankey));
        }else if(pagename == '/StudentPerformance'){
          dispatch(fetchstudent_performance_tile_report(body));
          dispatch(fetchstudent_dimension_table_report(datastudent));
          dispatch(fetchstudent_dimension_report({...datastudent,['filters']: datastudent.filters.filter(x=>x.columnName != "SCHOOL_YEAR")}));
        }else if(pagename == '/schoolperformance'){
          dispatch(fetchSchool_Performance_School_Type_Report(body));
          dispatch(fetchSchool_Performance_Metric_Report(body));
          dispatch(fetchSchool_Performance_Map_Report(body));
          dispatch(fetchSchool_Performance_Pie_Chart_Report(body));
        }
        else if(pagename == '/goalsstrategy'){
          dispatch(fetchIgniteLearning(body));
          dispatch(fetchCultivateTrust({...body,['filters']: body.filters.filter(item => !['STUDENT_GENDER','STUDENT_ETHNICITY','STUDENT_GRADE'].some(x=>x==item.columnName) )}));
        }
      }

      const removeNullFun = () => { 
        let j = [
            selectedYear.columnValue.length && selectedYear,
            selectedSchool.columnValue.length && selectedSchool,
            selectedGender.columnValue.length && selectedGender,
            selectedEthincity.columnValue.length && selectedEthincity,
            selectedGrade.columnValue.length && selectedGrade,
            selectedschoolname.columnValue.length && selectedschoolname,
            selectedEll.columnValue.length && selectedEll,
            selectedDisabilities.columnValue.length && selectedDisabilities,
            selectedEconomicallyDisadvantaged.columnValue.length && selectedEconomicallyDisadvantaged,
            selectedHomeless.columnValue.length && selectedHomeless,
           ]
          return j.filter(val => val !== 0);
     }


    const handleChangeFilter = (body) => {
      var Yearremove = body;
      var removeschoollevel = body;
      var removeschoolname = body;
      var removegrade = body;
      var removegender = body;
      var removeethnicity = body;
      var removeell = body;
      var removedisabilities = body;
      var removeeconomic = body;
      var removehomeless = body;
      Yearremove = Yearremove?.filters?.filter((item) => item.columnName != "SCHOOL_YEAR");
      var data1 = {
        "elasticQueryName": "",
        "filters": Yearremove,
        "dynamicColumns": [],
      }
      removeschoollevel = removeschoollevel?.filters?.filter((item) => item.columnName != "SCHOOL_LEVEL" && item.columnName != "SCHOOL_NAME"
      && item.columnName != "GRADE" && item.columnName != "GENDER" && item.columnName != "ETHNICITY" && item.columnName != "ENGLISH_LANGUAGE_LEARNERS" 
      && item.columnName != "STUDENTS_WITH_DISABILITIES" && item.columnName != "ECONOMICALLY_DISADVANTAGED" && item.columnName != "HOMELESS"
      );
      var dataremoveschoollevel = {
        "elasticQueryName": "",
        "filters": removeschoollevel,
        "dynamicColumns": [],
      }
      removeschoolname = removeschoolname?.filters?.filter((item) => item.columnName != "SCHOOL_NAME");
      var dataremoveschoolname = {
        "elasticQueryName": "",
        "filters": removeschoolname,
        "dynamicColumns": [],
      }
      removegrade = removegrade?.filters?.filter((item) => item.columnName != "GRADE");
      var dataremovegrade = {
        "elasticQueryName": "",
        "filters": removegrade,
        "dynamicColumns": [],
      }
      removegender = removegender?.filters?.filter((item) => item.columnName != "GENDER");
      var dataremovegender = {
        "elasticQueryName": "",
        "filters": removegender,
        "dynamicColumns": [],
      }
      removeethnicity = removeethnicity?.filters?.filter((item) => item.columnName != "ETHNICITY");
      var dataremoveethnicity = {
        "elasticQueryName": "",
        "filters": removeethnicity,
        "dynamicColumns": [],
      }
      removeell = removeell?.filters?.filter((item) => item.columnName != "ENGLISH_LANGUAGE_LEARNERS");
      var dataremoveell = {
        "elasticQueryName": "",
        "filters": removeell,
        "dynamicColumns": [],
      }
      removedisabilities = removedisabilities?.filters?.filter((item) => item.columnName != "STUDENTS_WITH_DISABILITIES");
      var dataremovedisabilities = {
        "elasticQueryName": "",
        "filters": removedisabilities,
        "dynamicColumns": [],
      }
      removeeconomic = removeeconomic?.filters?.filter((item) => item.columnName != "ECONOMICALLY_DISADVANTAGED");
      var dataremoveeconomic = {
        "elasticQueryName": "",
        "filters": removeeconomic,
        "dynamicColumns": [],
      }
      removehomeless = removehomeless?.filters?.filter((item) => item.columnName != "HOMELESS");
      var dataremovehomeless = {
        "elasticQueryName": "",
        "filters": removehomeless,
        "dynamicColumns": [],
      }
       dispatch(fetchMenu_School_Year(data1));
       dispatch(fetchMenu_School_Level(dataremoveschoollevel));
       dispatch(fetchMenu_School_Name(dataremoveschoolname));
       dispatch(fetchMenu_Gender(dataremovegender));
       dispatch(fetchMenu_Grade(dataremovegrade));
       dispatch(fetchMenu_Ethnicity(dataremoveethnicity));
       dispatch(fetchMenu_Ell(dataremoveell));
       dispatch(fetchMenu_Disabilities(dataremovedisabilities));
       dispatch(fetchMenu_Economically(dataremoveeconomic));
       dispatch(fetchMenu_Homeless(dataremovehomeless));
    }


  return (
    <div className='text-black dark:text-white'>        
         <div className='p-10'>

                 <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">School Year</label>
                     {/* <MultiSelect
                         options={yearOptions}
                        value={selectedYeardata}
                         onChange={setSelectedYeardata}
                         overrideStrings={{
                           allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    /> */}
                    <Dropdown value={selectedYeardata} onChange={(e) => setSelectedYeardata(e.value)} options={yearOptions} optionLabel="name"
                                placeholder= {selectedYeardata} className="w-full md:w-14rem text-xs" style={{ width: 193 }} />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">School Type</label>
                     <MultiSelect
                         options={schoolTypeOptions}
                        value={selectedSchooldata}
                         onChange={setSelectedSchooldata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>

                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">School Name</label>
                     <MultiSelect
                         options={schoolnameOption}
                        value={selectedSchoolnamedata}
                         onChange={setSelectedSchoolnamedata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>

                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">Grade</label>
                     <MultiSelect
                         options={gradeOptions}
                        value={selectedGradedata}
                         onChange={setSelectedGradedata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">Ethnicity</label>
                     <MultiSelect
                         options={ethinOption}
                        value={selectedEthincitydata}
                         onChange={setSelectedEthincitydata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">Gender</label>
                     <MultiSelect
                         options={genderOptions}
                        value={selectedGenderdata}
                         onChange={setSelectedGenderdata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">English Language Learners</label>
                     <MultiSelect
                         options={ellOption}
                        value={selectedElldata}
                         onChange={setSelectedElldata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">Students with Disabilities</label>
                     <MultiSelect
                         options={disabilitiesOption}
                        value={selectedDisabilitiesdata}
                         onChange={setSelectedDisabilitiesdata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">Economically Disadvantaged</label>
                     <MultiSelect
                         options={economicOption}
                        value={selectedEconomicdata}
                         onChange={setSelectedEconomicallyDisadvantageddata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>
                <form className="form-floating my-2 px-1" style={{width:200}}>
                     <label className="filterTitle">Homeless</label>
                     <MultiSelect
                         options={homelessOption}
                        value={selectedHomelessdata}
                         onChange={setSelectedHomelessdata}
                         overrideStrings={{
                            allItemsAreSelected: "All",
                         }}
                        labelledBy="Type to Search or Select"
                    />
                    
                </form>


                <button className='filterBut py-2 px-1' style={{position: "relative",left: "20px"}} onClick={applyFilterFunc}>Apply</button>
                <button className='filterBut py-2 px-1' style={{position: "relative",left: "20px"}} onClick={resetFilterFunc}>Reset</button>
         </div>
    </div>
  )
}

export default FilterComponent
