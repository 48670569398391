
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import whiteLogoDarkImg from '../../assets/images/GreeceLogoDark.png';
import GreeceLogo2 from '../../assets/images/greece.png';
import whiteLogoLightImg from '../../assets/images/GreeceLogo1.png';
import whiteLogoImg from '../../assets/brands/Artboard.svg';
// import whiteLogoImgLight from '../../assets/brands/AISD_Logo_Color_horizontal.png';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import { TabView, TabPanel } from 'primereact/tabview';
// import AppLogo from '../AppLogo';
import HRProfile from "./profile.component";
import { Sidebar } from 'primereact/sidebar';
import FilterComponent from "../filterComponent";
import { setState } from '../../redux/slices/globalState';
import { useDispatch, useSelector } from "react-redux";
import { CiDark } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";



export default function TopNav(props) {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const DarkMode = useSelector((state) => state.global.DarkMode);
    const img = DarkMode ? GreeceLogo2 : whiteLogoDarkImg;
    // const img = DarkMode ? whiteLogoImg : whiteLogoDarkImg;

    var pagename = window.location.pathname
    // console.log("pagename",pagename)
    const handleClick = () => {
        window.sessionStorage.removeItem('SchoolType');
        window.sessionStorage.removeItem('Schoolname');
        window.sessionStorage.removeItem('Year');
        window.sessionStorage.removeItem('Gender');
        window.sessionStorage.removeItem('Ethincity');
        window.sessionStorage.removeItem('Grade');
        window.sessionStorage.removeItem('allfilter');
        window.sessionStorage.removeItem('selectedoptions');
        window.sessionStorage.removeItem('selectedoptions1');
        window.sessionStorage.removeItem('selectedoptions2');
        window.sessionStorage.removeItem('selectedoptions3');
        window.sessionStorage.removeItem('selectedmetric');
    };
    useEffect(() => {
        const handleBeforeUnload = () => {
            window.sessionStorage.removeItem('SchoolType');
            window.sessionStorage.removeItem('Schoolname');
            window.sessionStorage.removeItem('Year');
            window.sessionStorage.removeItem('Gender');
            window.sessionStorage.removeItem('Ethincity');
            window.sessionStorage.removeItem('Grade');
            window.sessionStorage.removeItem('allfilter');
            window.sessionStorage.removeItem('selectedoptions');
            window.sessionStorage.removeItem('selectedoptions1');
            window.sessionStorage.removeItem('selectedoptions2');
            window.sessionStorage.removeItem('selectedoptions3');
            window.sessionStorage.removeItem('selectedmetric');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const BarHeaderTemplate = (options) => {


        return (
            <div className={`${DarkMode ? 'DarkmodeBarIcon' : 'LightmodeBarIcon'} flex px-4 py-4 align-items-center`} style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <i className="pi pi-bars"></i>
            </div>
        )
    };
    const FilterHeaderTemplate = (options) => {
        return (
            <div className={`${DarkMode ? 'DarkmodeBarIcon' : 'LightmodeBarIcon'} flex px-4 py-4 align-items-center`} style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <i className="pi pi-sliders-v"></i>
            </div>
        )
    };
    const SettingHeaderTemplate = (options) => {
        return (
            <div className={`${DarkMode ? 'DarkmodeBarIcon' : 'LightmodeBarIcon'} flex px-4 py-4 align-items-center`} style={{ cursor: 'pointer' }} onClick={options.onClick}>
                <i className="pi pi-cog"></i>
            </div>
        )
    };

    useEffect(() => {

    }, []);
    const navigate = useNavigate();

    const hangleLogOut = () => {

        sessionStorage.removeItem("userInfo")
        props.setUserData(null)
        navigate("/")
    }



    return (
        <div className=" w-full text-gray-700 py-0 top-0 z-10 headar-wrapper dark:bg-[#232528] dark:shadow-md">
            <div x-data="{ open: false }" className="flex flex-col mx-auto md:items-center md:justify-between md:flex-row">
                <div className="flex flex-row items-center justify-between px-2">
                    <div className="logo">
                        <Link to="/" className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">

                            <div className='logo py-2 px-4 transform: matrix(1, 0, 0, -1, 0, 0); max-w-[220px]  overflow-hidden'>
                                {/* <AppLogo /> */}
                                <img src={img} width='200' className='max-w-none h-fit' />
                            </div>

                        </Link>
                    </div>
                    <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline">
                        <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                            <path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            <path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <nav className="flex-col flex-grow hidden h-14 pb-4 md:flex md:items-center md:pb-0 md:justify-between md:flex-row">
                    <div className="flex-col items-center justify-end">
                        <h2 className="title text-[#000] mt-[1vw] ">{props.pagename}</h2>
                        {/* <p className="subtitle text-[#000] display-none">{props?.pagenamedescription}</p> */}


                    </div>

                    <div className="relative flex items-center gap-3 ml-5 profile-block" x-data="{ open: false }">
                        {/* <div>
                            {
                                !DarkMode ? <h2 className="text-[28px] mb-2 cursor-pointer" onClick={() => dispatch(setState({ DarkMode: true }))}><MdDarkMode /></h2>
                                    : <h2 className="text-[28px] text-white mb-3 cursor-pointer" onClick={() => dispatch(setState({ DarkMode: false }))}><CiDark /></h2>
                            }
                        </div>
                        <div className={`${DarkMode ? "text-[#fff]" : " text-[#000]"}  text-[24px]  mb-2 font-semibold`} style={{ cursor: "pointer" }} onClick={hangleLogOut}><AiOutlineLogout /></div> */}


                        <div className="px-2 baricon"><button onClick={() => setVisibleRight(true)}><i className="pi pi-bars"></i></button></div>
                        <HRProfile />
                    </div>
                </nav>
            </div >
            <div>
                <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)} className={`${DarkMode ? 'DarkmodePopup' : 'LightmodePopup'}`}>


                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="  " headerTemplate={BarHeaderTemplate} headerClassName="flex align-items-center">
                            <div className="bg-[#0d234c] p-5 h-[120px] sidebar-panel">
                                <h2 className="text-[18px] text-[#fff] font-bold">Navigation Menu</h2>
                                <p className="text-[14px] text-[#fff]">Search through the list of Navigation menus</p>
                            </div>
                            <div className="px-3 py-3 menu">
                                <ul onClick={handleClick}>
                                    <Link to='/'><li className={pagename == "/" ? "active" : ''}><Link to='/'>District at a Glance</Link></li></Link>
                                    <Link to='/StrengthWeakness'><li className={pagename == "/StrengthWeakness" ? "active" : ''}><Link to='/StrengthWeakness'>Strength & Weakness</Link></li></Link>
                                    <Link to='/StudentPerformance'><li className={pagename == "/StudentPerformance" ? "active" : ''}><Link to='/StudentPerformance'>Student Performance & Progress</Link></li></Link>
                                    {/* <li><Link to='/collegecareerreadiness'>College and Career Readiness</Link></li> */}
                                    {/* <li><Link to='/humanResources'>Human Resources</Link></li> */}
                                    <Link to='/enrollment'><li className={pagename == "/enrollment" ? "active" : ''}><Link to='/enrollment'>Enrollment</Link></li></Link>
                                    {/* <li><Link to='/districtgoals'>District Goal & Strategy</Link></li> */}
                                    <Link to='/studentbehaviour'><li className={pagename == "/studentbehaviour" ? "active" : ''}><Link to='/studentbehaviour'>Student Behaviour</Link></li></Link>
                                    <Link to='/schoolperformance'><li className={pagename == "/schoolperformance" ? "active" : ''}><Link to='/schoolperformance'>School Performance</Link></li></Link>
                                    {/* <li><Link to='/GraduationRateAnalysis'>Graduation Rate Analysis</Link></li> */}
                                    <Link to='/goalsstrategy'><li className={pagename == "/goalsstrategy" ? "active" : ''}><Link to='/goalsstrategy'>Strategic Plan Dashboard</Link></li></Link>
                                    <Link to='/statebenchmarking'><li className={pagename == "/statebenchmarking" ? "active" : ''}><Link to='/statebenchmarking'>State Benchmarking</Link></li></Link>   
                                </ul>

                            </div>

                        </TabPanel>
                        <TabPanel header="" headerTemplate={FilterHeaderTemplate} headerClassName="flex align-items-center">
                            <div className={`${DarkMode ? 'bg-[#0d234c]' : 'bg-white'} p-5 h-[auto] sidebar-panel`}>

                                <h2 className={`${DarkMode ? "text - [#fff]" : " text - [#000]"} text-[18px] font-bold`}>Filters</h2>
                                <p className={`${DarkMode ? "text - [#fff]" : " text - [#000]"} text-[14px] text-semibold`}>Apply filters for Advanced Search</p>
                                <FilterComponent />

                            </div>


                        </TabPanel>
                        <TabPanel header="" headerTemplate={SettingHeaderTemplate} headerClassName="flex align-items-center">
                            <div className="bg-[#0d234c] p-5 h-[140px] sidebar-panel">

                                {/* <div className="text-[18px] text-[#fff] mb-3 font-semibold" style={{ cursor: "pointer" }} onClick={hangleLogOut}>LogOut</div> */}

                                <h2 className="text-[18px] text-[#fff] mb-3 font-semibold">Resources</h2>


                                {/* <p className="text-[14px] text-[#fff]">SApply filters for Advanced Search</p> */}
                            </div>
                        </TabPanel>
                    </TabView>



                </Sidebar>
            </div>
        </div >
    )

}