import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNetSales } from '../services/user'

const initialState = {
    IgniteLearningloading: false,
    IgniteLearning: [],

    CultivateTrustloading: false,
    CultivateTrust: [],

    GoalsAndStrategySchoolTypeloading: false,
    GoalsAndStrategySchoolType: [],

    Searchfilterloading: false,
    Searchfilter: [],

    TimeTrendloading: false,
    TimeTrend: [],

    DimensionViewloading: false,
    DimensionView: [],

    ExpandedOportunityloading: true,
    ExpandedOportunity: [],
}

export const fetchIgniteLearning = createAsyncThunk(
    'fetchIgniteLearning',
    async (IgniteLearning, thunkAPI) => {
        IgniteLearning = { ...IgniteLearning, elasticQueryName: "Ignite Learning" }
        const response = await getNetSales(IgniteLearning);
        return response.data
    }
)

export const fetchCultivateTrust = createAsyncThunk(
    'fetchCultivateTrust',
    async (CultivateTrust, thunkAPI) => {
        CultivateTrust = { ...CultivateTrust, elasticQueryName: "Cultivate Trust" }
        const response = await getNetSales(CultivateTrust);
        return response.data
    }
)

export const fetchGoalsAndStrategySchoolType = createAsyncThunk(
    'fetchGoalsAndStrategySchoolType',
    async (GoalsAndStrategySchoolType, thunkAPI) => {
        GoalsAndStrategySchoolType = { ...GoalsAndStrategySchoolType, elasticQueryName: "Goals & Strategy - School Type" }
        const response = await getNetSales(GoalsAndStrategySchoolType);
        return response.data
    }
)

export const fetchSearchfilter = createAsyncThunk(
    'fetchSearchfilter',
    async (Searchfilter, thunkAPI) => {
        Searchfilter = { ...Searchfilter, elasticQueryName: "Search filter" }
        const response = await getNetSales(Searchfilter);
        return response.data
    }
)

export const fetchTimeTrend = createAsyncThunk(
  'fetchTimeTrend',
  async (TimeTrend, thunkAPI) => {
      TimeTrend = { ...TimeTrend, elasticQueryName: "Time Trend" }
      const response = await getNetSales(TimeTrend);
      return response.data
  }
)

export const fetchDimensionView = createAsyncThunk(
  'fetchDimensionView',
    async (DimensionView, thunkAPI) => {
    DimensionView = { ...DimensionView, elasticQueryName: "Dimension View" }
      const response = await getNetSales(DimensionView);
      return response.data
  }
)

export const fetchExpandedOportunity = createAsyncThunk(
  'fetchExpandedOportunity',
    async (ExpandedOportunity, thunkAPI) => {
      ExpandedOportunity = { ...ExpandedOportunity, elasticQueryName: "Expanded Oportunity" }
      const response = await getNetSales(ExpandedOportunity);
      return response.data
  }
)

export const GoalsandStrategy = createSlice({
    name: 'GoalsandStrategy',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(fetchIgniteLearning.fulfilled, (state, action) => {
        state.IgniteLearning = action.payload;
        state.IgniteLearningloading = false;
      }).addCase(fetchIgniteLearning.pending, (state, action) => {
        state.IgniteLearningloading = true;
      })
      
      builder.addCase(fetchCultivateTrust.fulfilled, (state, action) => {
        state.CultivateTrust = action.payload;
        state.CultivateTrustloading = false;
      }).addCase(fetchCultivateTrust.pending, (state, action) => {
        state.CultivateTrustloading = true;
      })

      builder.addCase(fetchGoalsAndStrategySchoolType.fulfilled, (state, action) => {
        state.GoalsAndStrategySchoolType = action.payload;
        state.GoalsAndStrategySchoolTypeloading = false;
      }).addCase(fetchGoalsAndStrategySchoolType.pending, (state, action) => {
        state.GoalsAndStrategySchoolTypeloading = true;
      })

      builder.addCase(fetchSearchfilter.fulfilled, (state, action) => {
        state.Searchfilter = action.payload;
        state.Searchfilterloading = false;
      }).addCase(fetchSearchfilter.pending, (state, action) => {
        state.Searchfilterloading = true;
      })

      builder.addCase(fetchTimeTrend.fulfilled, (state, action) => {
        state.TimeTrend = action.payload;
        state.TimeTrendloading = false;
      }).addCase(fetchTimeTrend.pending, (state, action) => {
        state.TimeTrendloading = true;
      })

      builder.addCase(fetchDimensionView.fulfilled, (state, action) => {
        state.DimensionView = action.payload;
        state.DimensionViewloading = false;
      }).addCase(fetchDimensionView.pending, (state, action) => {
        state.DimensionViewloading = true;
      })

      builder.addCase(fetchExpandedOportunity.fulfilled, (state, action) => {
        state.ExpandedOportunity = action.payload;
        state.ExpandedOportunityloading = false;
      }).addCase(fetchExpandedOportunity.pending, (state, action) => {
        state.ExpandedOportunityloading = true;
      })
    }
  
  })

  export default GoalsandStrategy.reducer
