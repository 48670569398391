
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Card } from 'primereact/card';
// import ReactEcharts from "echarts-for-react";
import { Dropdown } from 'primereact/dropdown';
import DrillDown from "../assets/images/drilldown.png";
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { ProductService } from '../service/ProductService';
import thumb from '../assets/images/strength.png';
import thumbDark from '../assets/images/strength_dark.png';
import improvementimg from '../assets/images/improvement.png';
import improvementimgDark from '../assets/images/improvementimg_dark.png';
import caretup from "../assets/images/caret-up.png";
import caretdown from "../assets/images/caret-down.png";
import thumbdown from '../assets/images/weakness.png';
import thumbdownDark from '../assets/images/weakness_dark.png';
import TopNav from "../components/common/topnav.component";
import Complogo from '../assets/images/GoalsStrategyLogo.svg';
import ReactEcharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART, fetchImprovement_Tile_Report, fetchStrength_Tile_Report, fetchWeakness_Tile_Report
} from '../redux/slices/strengthweakness';
import TreeMapEcharts from '../components/tree-graph';
import LoaderContainer from "../components/loaderContainer";

export default function StrengthWeakness(props) {
    const dispatch = useDispatch();
    const STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata = useSelector(state => state.strengthweakness.STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART);
    const STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdataloading = useSelector(state => state.strengthweakness.STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading);
    const Improvement_Tile_Reportdata = useSelector(state => state.strengthweakness.Improvement_Tile_Report);
    const Strength_Tile_Reportdata = useSelector(state => state.strengthweakness.Strength_Tile_Report);
    const Strength_Tile_Reportdataloading = useSelector(state => state.strengthweakness.Strength_Tile_Reportloading);
    const Weakness_Tile_Reportdata = useSelector(state => state.strengthweakness.Weakness_Tile_Report);
    const DarkMode = useSelector((state) => state.global.DarkMode);
    const STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading = useSelector(state => state.netsales.STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading);
    // console.log("STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata",STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata)
    // console.log("Improvement_Tile_Reportdata",Improvement_Tile_Reportdata)
    // console.log("Strength_Tile_Reportdata",Strength_Tile_Reportdata)
    // console.log("Weakness_Tile_Reportdata",Weakness_Tile_Reportdata)
    const [selectedoptions, setSelectedoptions] = useState('school_level');
    const [selectedoptions1, setSelectedoptions1] = useState('student_ethnicity');
    const [selectedoptions2, setSelectedoptions2] = useState('student_grade');
    const [selectedoptions3, setSelectedoptions3] = useState('student_gender');
    const [nestedFields, setNestedFields] = useState(["DIMENSION_1", "DIMENSION_2", "DIMENSION_3", "DIMENSION_4"]);
    const [valueFields, setValueFields] = useState(["ACTUAL_D1", "ACTUAL_D2", "ACTUAL_D3", "ACTUAL_D4"]);

    sessionStorage.setItem("selectedoptions", selectedoptions);
    sessionStorage.setItem("selectedoptions1", selectedoptions1);
    sessionStorage.setItem("selectedoptions2", selectedoptions2);
    sessionStorage.setItem("selectedoptions3", selectedoptions3);


    const options = [
        { name: 'School Type', value: 'school_level' },
        { name: 'School Name', value: 'school_official_name' },
        { name: 'Grade Configuration', value: 'school_grade_configuration' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'English Language Learners', value: 'student_ell' },
        { name: 'Students with Disabilities', value: 'student_special_ability' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Homeless', value: 'student_homeless' },
        // { name: 'Special Ability', value: 'student_special_ability' },
        // { name: 'ELL', value: 'student_ell' },
        // { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const options1 = [
        { name: 'School Type', value: 'school_level' },
        { name: 'School Name', value: 'school_official_name' },
        { name: 'Grade Configuration', value: 'school_grade_configuration' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Grade Level', value: 'student_grade' },        
        { name: 'Gender', value: 'student_gender' },
        { name: 'English Language Learners', value: 'student_ell' },
        { name: 'Students with Disabilities', value: 'student_special_ability' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Homeless', value: 'student_homeless' },
        // { name: 'Special Ability', value: 'student_special_ability' },
        // { name: 'ELL', value: 'student_ell' },
        // { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const options2 = [
        { name: 'School Type', value: 'school_level' },
        { name: 'School Name', value: 'school_official_name' },
        { name: 'Grade Configuration', value: 'school_grade_configuration' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'English Language Learners', value: 'student_ell' },
        { name: 'Students with Disabilities', value: 'student_special_ability' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Homeless', value: 'student_homeless' },
        // { name: 'Special Ability', value: 'student_special_ability' },
        // { name: 'ELL', value: 'student_ell' },
        // { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const options3 = [
        { name: 'School Type', value: 'school_level' },
        { name: 'School Name', value: 'school_official_name' },
        { name: 'Grade Configuration', value: 'school_grade_configuration' },
        { name: 'Ethnicity', value: 'student_ethnicity' },
        { name: 'Grade Level', value: 'student_grade' },
        { name: 'Gender', value: 'student_gender' },
        { name: 'English Language Learners', value: 'student_ell' },
        { name: 'Students with Disabilities', value: 'student_special_ability' },
        { name: 'Economically Disadvantaged', value: 'student_economically_disadvantaged' },
        { name: 'Homeless', value: 'student_homeless' },
        // { name: 'Special Ability', value: 'student_special_ability' },
        // { name: 'ELL', value: 'student_ell' },
        // { name: 'Language Classification', value: 'student_language_classification' }
    ];

    const DynmaicOptions = options?.filter((item) => {
        const first = item?.value != selectedoptions1
        const second = item?.value != selectedoptions2
        const third = item?.value != selectedoptions3
        return first && second && third;
    }
    );

    const DynmaicOptions1 = options1?.filter((item) => {
        const first = item?.value != selectedoptions
        const second = item?.value != selectedoptions2
        const third = item?.value != selectedoptions3
        return first && second && third;
    }
    );
    const DynmaicOptions2 = options2?.filter((item) => {
        const first = item?.value != selectedoptions
        const second = item?.value != selectedoptions1
        const third = item?.value != selectedoptions3
        return first && second && third;
    }
    );
    const DynmaicOptions3 = options3?.filter((item) => {
       const first = item?.value != selectedoptions
       const second = item?.value != selectedoptions1
       const third = item?.value != selectedoptions2
       return first && second && third
    });
    
    useEffect(() => {
        // dispatch(fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART({
        //   "elasticQueryName": "STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART",
        //   "filters": [
        //   ],
        //   "dynamicColumns": [],
        // }));

        dispatch(fetchImprovement_Tile_Report({
            "elasticQueryName": "Improvement_Tile_Report",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2024"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
        }));

        dispatch(fetchStrength_Tile_Report({
            "elasticQueryName": "Strength_Tile_Report",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2024"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
        }));

        dispatch(fetchWeakness_Tile_Report({
            "elasticQueryName": "Weakness_Tile_Report",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2024"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
        }));
    }, [])



    const drilldown = {
        series: [{
            type: "tree",
            data: [{
                name: "Actual 76.9%",
                symbolSize: 10,
                itemStyle: {
                    color: '#4C80E4',
                    shadowColor: 'rgba(76, 128, 228, 1)',
                    shadowBlur: 10,
                    fill: true

                },
                children: [{
                    name: "Middle(81.4%)",
                    lineStyle: {
                        width: 20,
                        color: "rgb(164, 202, 84)"
                    },
                    symbolSize: 10,
                    label: {
                        show: true,
                        position: "left"
                    },

                },
                // second chart 
                {
                    name: "Elementrary(79.7%)",

                    lineStyle: {
                        width: 12,
                        color: "rgb(202, 84, 84)"
                    },
                    symbolSize: 10,

                }, {
                    name: "Combined Middle/High(56.9%)",
                    lineStyle: {
                        width: 20,
                        color: "rgb(164, 202, 84)"
                    },
                    symbolSize: 10,

                }, {
                    name: "Combined Elementry /Secondory(98.4%)",
                    lineStyle: {
                        width: 20,
                        color: "rgb(164, 202, 84)"
                    },
                    symbolSize: 10,
                }]
            }],
            label: {
                position: "left"
            }
        }]
    }


    const handleTreeOnClick = (node) => {
        console.log(node);
    }

    const handleNestedFieldChange = (values) => {
        setNestedFields(values);
    }


    const [products, setProducts] = useState(Strength_Tile_Reportdata);
    const [products1, setProducts1] = useState(Improvement_Tile_Reportdata);
    const [products2, setProducts2] = useState(Weakness_Tile_Reportdata);

    useEffect(() => {
        setProducts(Strength_Tile_Reportdata);
        setProducts1(Improvement_Tile_Reportdata);
        setProducts2(Weakness_Tile_Reportdata);
    }, [products, products1, products2]);

    const [selectedmetric, setSelectedmetric] = useState("10003");
    // "10084"
    sessionStorage.setItem("selectedmetric", selectedmetric);

    const [selectedmetricname, setSelectedmetricname] = useState("Chronic Absence Rate");
    // "% of student above STAAR Math benchmark"
    useEffect(() => {
        // setSelectedmetric(Strength_Tile_Reportdata[0]?.METRIC_ID)
        // setSelectedmetricname(Strength_Tile_Reportdata[0]?.METRIC_NAME)
        const handleBeforeUnload = () => {
            window.sessionStorage.removeItem('  allfilter');
            window.sessionStorage.removeItem('selectedoptions');
            window.sessionStorage.removeItem('selectedoptions1');
            window.sessionStorage.removeItem('selectedoptions2');
            window.sessionStorage.removeItem('selectedoptions3');
            window.sessionStorage.removeItem('selectedmetric');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    // }, [Strength_Tile_Reportdata[0]?.METRIC_ID, Strength_Tile_Reportdata[0]?.METRIC_NAME]);
    var defaultallfilter = JSON.parse(sessionStorage.getItem("allfilter"));

    useEffect(() => {
        // const body = {
        //     "elasticQueryName": "",
        //     "filters": [{ "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
        //     "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
        //     { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
        //     { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
        //     { columnName: "#{dimension_4}", columnValue: [selectedoptions3] }],
        //     "pivotConfig": [],

        // };
        let body;
        if (defaultallfilter) {
            body = {
                "elasticQueryName": "",
                "filters": defaultallfilter.filters.concat([{ "columnName": "metric_id", "columnValue": [selectedmetric] }]),
                "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
                { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
                { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
                { columnName: "#{dimension_4}", columnValue: [selectedoptions3] }],
            };
        } else {
            body = {
                "elasticQueryName": "",
                "filters": [{ "columnName": "SCHOOL_YEAR", "columnValue": ["2024"], "excludeKeyword": false }, { "columnName": "metric_id", "columnValue": [selectedmetric], "excludeKeyword": false }],
                "dynamicColumns": [{ columnName: "#{dimension_1}", columnValue: [selectedoptions] },
                { columnName: "#{dimension_2}", columnValue: [selectedoptions1] },
                { columnName: "#{dimension_3}", columnValue: [selectedoptions2] },
                { columnName: "#{dimension_4}", columnValue: [selectedoptions3] }],
            };
        }
        handleperformance(body)
    }, [selectedoptions, selectedoptions1, selectedoptions2, selectedoptions3, selectedmetric, DarkMode]);

    const handleperformance = (body) => {
        dispatch(fetchSTRENGTH_AND_WEAKNESS_DRILL_DOWN_CHART(body));
    }
    const handleTopTileFilterClick = (tile) => {
        setSelectedmetric(tile?.METRIC_ID)
        setSelectedmetricname(tile?.METRIC_NAME == '% of student above NWEA ELA benchmark' ? '% of student above ELA benchmark' : tile?.METRIC_NAME)
    }
    const strength = (Strength_Tile_Reportdata) => {
        return (
            <Strength_Tile_Report
                clicker={Strength_Tile_Reportdata}
                clickerFunc={handleTopTileFilterClick}
                METRIC_ID={Strength_Tile_Reportdata?.METRIC_ID}
                METRIC_NAME={Strength_Tile_Reportdata?.METRIC_NAME == '% of student above NWEA ELA benchmark' ? '% of student above ELA benchmark' : Strength_Tile_Reportdata?.METRIC_NAME} ACTUAL={Strength_Tile_Reportdata?.ACTUAL} TARGET={Strength_Tile_Reportdata?.TARGET}
                TARGET_VARIANCE={Strength_Tile_Reportdata?.TARGET_VARIANCE} LAST_PERIOD={Strength_Tile_Reportdata?.LAST_PERIOD} LAST_PERIOD_VARIANCE={Strength_Tile_Reportdata?.LAST_PERIOD_VARIANCE} METRIC_TYPE={Strength_Tile_Reportdata?.METRIC_TYPE}
            />
        );
    };
    const improvement = (Improvement_Tile_Reportdata) => {
        return (
            <Strength_Tile_Report clicker={Improvement_Tile_Reportdata} clickerFunc={handleTopTileFilterClick} METRIC_ID={Improvement_Tile_Reportdata?.METRIC_ID} METRIC_NAME={Improvement_Tile_Reportdata?.METRIC_NAME == '% of student above NWEA ELA benchmark' ? '% of student above ELA benchmark' : Improvement_Tile_Reportdata?.METRIC_NAME} ACTUAL={Improvement_Tile_Reportdata?.ACTUAL} TARGET={Improvement_Tile_Reportdata?.TARGET}
                TARGET_VARIANCE={Improvement_Tile_Reportdata?.TARGET_VARIANCE} LAST_PERIOD={Improvement_Tile_Reportdata?.LAST_PERIOD} LAST_PERIOD_VARIANCE={Improvement_Tile_Reportdata?.LAST_PERIOD_VARIANCE}
            />
        );
    };
    const weakness = (Weakness_Tile_Reportdata) => {
        return (
            <Strength_Tile_Report clicker={Weakness_Tile_Reportdata} clickerFunc={handleTopTileFilterClick} METRIC_ID={Weakness_Tile_Reportdata?.METRIC_ID} METRIC_NAME={Weakness_Tile_Reportdata?.METRIC_NAME} ACTUAL={Weakness_Tile_Reportdata?.ACTUAL} TARGET={Weakness_Tile_Reportdata?.TARGET}
                TARGET_VARIANCE={Weakness_Tile_Reportdata?.TARGET_VARIANCE} LAST_PERIOD={Weakness_Tile_Reportdata?.LAST_PERIOD} LAST_PERIOD_VARIANCE={Weakness_Tile_Reportdata?.LAST_PERIOD_VARIANCE} METRIC_TYPE={Weakness_Tile_Reportdata?.METRIC_TYPE}
            />
        );
    };
    const Strength_Tile_Report = (props) => {
        // const [selectedmetric, setSelectedmetric] = useState("10003");  
        return (
            <>
                <div id='cardtilesCustomer ' onClick={
                    () => props.clickerFunc(props.clicker)
                }>
                    {
                        props?.METRIC_ID == selectedmetric ? <div className=" text-center strength-vertical-slider">
                            <div className="">
                                <div className="strength-vertical-slider-active bg-[#27304a] w-full flex justify-between items-center px-2">
                                    <div className='w-full flex justify-between'>
                                        <div className=''><h4 className="mb-1 firstrowtext py-3 text-left" style={{ fontSize: "14px" }}>{props?.METRIC_NAME}</h4></div>
                                        <div className=''><h4 className="mb-1 strenth-pers py-3 text-right">{props?.METRIC_TYPE == "number" ? parseInt(props?.ACTUAL?.toFixed(0))?.toLocaleString() + "" : props?.METRIC_NAME == "Referrals" ? props?.ACTUAL?.toFixed(0) : props?.ACTUAL?.toFixed(1) + "%"}</h4></div>
                                    </div>
                                    <div className='w-full flex justify-between'>
                                        <div className=''><h4 className="mb-1 firstrowtext py-3 text-left" style={{ fontSize: "14px" }}>Target <span className='font-semibold'>{props?.TARGET}{props?.METRIC_NAME !== "Referrals" && props?.METRIC_TYPE !== "number" ? "%" : ""}</span>
                                            {
                                                props?.METRIC_NAME == "Refferal Rate" || props?.METRIC_NAME == "Referral Rate" ?
                                                    props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                        <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                    props?.METRIC_NAME == "Suspension Rate" ?
                                                        props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                        props?.METRIC_NAME == "Recidivism Rate" ?
                                                            props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                            props?.METRIC_NAME == "In School Suspensions" ?
                                                                props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                    <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                props?.METRIC_NAME == "Out of School Suspensions" ?
                                                                    props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                        <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                    props?.METRIC_NAME == "Referrals" ?
                                                                        props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                        props?.METRIC_NAME == "Chronic Absence Rate" ?
                                                                            props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                                <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                            props?.METRIC_NAME == "Expulsion Rate" ?
                                                                                props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                                    <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                                props?.TARGET_VARIANCE >= 0 ? <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span> :
                                                                                    <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.TARGET_VARIANCE.toFixed(1)}%</span>
                                            }
                                        </h4></div>
                                        <div className=''><h4 className="mb-1 firstrowtext py-3 text-left" style={{ fontSize: "14px" }}>LY Var <span className='font-semibold'>{props?.METRIC_TYPE == "number"
                                            ? parseInt(props?.LAST_PERIOD?.toFixed(0))?.toLocaleString()
                                            : props?.LAST_PERIOD?.toFixed(props?.METRIC_NAME == "Referrals" ? 0 : 1)}
                                            {props?.METRIC_NAME !== "Referrals" && props?.METRIC_TYPE !== "number" ? "%" : ""}</span>
                                            {
                                                props.LAST_PERIOD != 0 ?
                                                    props?.METRIC_NAME == "Refferal Rate" || props?.METRIC_NAME == "Referral Rate" ?
                                                        props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                        props?.METRIC_NAME == "Suspension Rate" ?
                                                            props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                            props?.METRIC_NAME == "Recidivism Rate" ?
                                                                props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                    <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                props?.METRIC_NAME == "In School Suspensions" ?
                                                                    props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                        <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                    props?.METRIC_NAME == "Out of School Suspensions" ?
                                                                        props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                            <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                        props?.METRIC_NAME == "Referrals" ?
                                                                            props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                                <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                            props?.METRIC_NAME == "Chronic Absence Rate" ?
                                                                                props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                                    <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                                props?.METRIC_NAME == "Expulsion Rate" ?
                                                                                    props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                                        <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                                    props?.LAST_PERIOD_VARIANCE >= 0 ? <span className="bg-[#1C6130] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span> :
                                                                                        <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretdown} width={5} height={5} className="inline-block mr-1" alt="" />{props?.LAST_PERIOD_VARIANCE.toFixed(1)}%</span>
                                                    : <span className="bg-[#6A1D25] text-xs text-white px-1 rounded-full w-11" style={{ fontSize: "14px", position: "relative", left: "5px" }}><img src={caretup} width={5} height={5} className="inline-block mr-1" alt="" />-%</span>
                                            }

                                        </h4></div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <div className=" text-center strength-vertical-slider">
                                <div className="w-full flex justify-between items-center px-4 ">
                                    <h4 className="mb-1 firstrowtext py-3 text-left">{props?.METRIC_NAME}</h4>
                                    <h4 className="mb-1 firstrowtext py-3 text-right">{props?.METRIC_TYPE == "number" ? parseInt(props?.ACTUAL.toFixed(0))?.toLocaleString() + "" : props?.METRIC_NAME === "Referrals" ? props?.ACTUAL?.toFixed(0) : props?.ACTUAL.toFixed(1) + "%"}</h4>
                                </div>
                            </div>
                    }
                </div>
            </>
        );
    };
    return (
        <div className="body">
            <TopNav pagename="Strength & Weakness" pagenamedescription="Key Facts about the District" setUserData={props.setUserData} />
            <LoaderContainer loading={Strength_Tile_Reportdataloading}>
                <div className="flex justify-between items-center mx-3 my-2 strength">

                    <div className="wrapper-card2 w-full mx-2">
                        <div className="streweak-vertical-carousel">
                            <div className="">
                                <div className='flex justify-between items-center h-[40px] pr-2'>
                                    <div className="px-3 flex items-center justify-around">
                                        <img src={DarkMode ? thumb : thumbDark} style={{ height: 18, width: 18 }} /><span className="xl font-[500] mx-2 EnrollmentTitle">Strengths</span>
                                    </div>
                                </div>
                            </div>
                            <Carousel
                                value={Strength_Tile_Reportdata}
                                numVisible={7}
                                numScroll={1}
                                orientation="vertical"
                                // verticalViewPortHeight="275px"
                                itemTemplate={strength}
                            />
                        </div>
                    </div>
                    <div className="wrapper-card2 w-full mx-2">
                        <div className="streweak-vertical-carousel">
                            <div className="">
                                <div className='flex justify-between items-center  h-[40px] pr-2'>
                                    <div className="px-3 flex items-center justify-around">
                                        <img src={DarkMode ? improvementimg : improvementimgDark} style={{ height: 20, width: 18 }} /><span className="xl font-[500] mx-2 EnrollmentTitle">Improvement Shown</span>
                                    </div>
                                </div>
                            </div>
                            {
                                Improvement_Tile_Reportdata.length > 0 ?
                                    <Carousel
                                        value={Improvement_Tile_Reportdata}
                                        numVisible={7}
                                        numScroll={1}
                                        orientation="vertical"
                                        // verticalViewPortHeight="275px"
                                        itemTemplate={improvement} /> :
                                    <div style={{ height: "275px", textAlign: "center", position: "relative", top: "5rem" }}>No Data</div>
                            }
                        </div>
                    </div>
                    <div className="wrapper-card2 w-full mx-2">
                        <div className="streweak-vertical-carousel">
                            <div className="">
                                <div className='flex justify-between items-center h-[40px] pr-2'>
                                    <div className="px-3 flex items-center justify-around">
                                        <img src={DarkMode ? thumbdown : thumbdownDark} style={{ height: 18, width: 18 }} /><span className="xl font-[500] mx-2 EnrollmentTitle">Weaknesses</span>
                                    </div>
                                </div>
                            </div>
                            {
                                Weakness_Tile_Reportdata.length > 0 ?
                                    <Carousel
                                        value={Weakness_Tile_Reportdata}
                                        numVisible={6}
                                        numScroll={1}
                                        orientation="vertical"
                                        // verticalViewPortHeight="275px"
                                        itemTemplate={weakness} /> :
                                    <div style={{ height: "275px", textAlign: "center", position: "relative", top: "5rem" }}>No Data</div>
                            }

                        </div>
                    </div>

                </div>
            </LoaderContainer>
            <LoaderContainer loading={STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdataloading}>
                <div className="drilldown_card card wrapper-card text-white mx-5 my-3">
                    <Card title="">
                        <div className="flex justify-between studenteoc-civi">
                            <div className="title-text flex items-center text-[16px] font-[500] ">{selectedmetricname}</div>
                            <div className="flex justify-center">
                                <div className="mx-1">
                                    <label htmlFor="dd-city" className="labeltext">Dimension 1</label>
                                    <Dropdown value={selectedoptions} onChange={(e) => setSelectedoptions(e.value)} options={DynmaicOptions} optionLabel="name"
                                        placeholder="School Type" className="w-full md:w-14rem dimension_dropdown" />
                                </div>
                                <div className="mx-1">
                                    <label htmlFor="dd-city" className="labeltext">Dimension 2</label>
                                    <Dropdown value={selectedoptions1} onChange={(e) => setSelectedoptions1(e.value)} options={DynmaicOptions1} optionLabel="name"
                                        placeholder="Ethinicity" className="w-full md:w-14rem dimension_dropdown" />
                                </div>
                                <div className="mx-1">
                                    <label htmlFor="dd-city" className="labeltext">Dimension 3</label>
                                    <Dropdown value={selectedoptions2} onChange={(e) => setSelectedoptions2(e.value)} options={DynmaicOptions2} optionLabel="name"
                                        placeholder="Grade Level" className="w-full md:w-14rem dimension_dropdown" />
                                </div>
                                <div className="mx-1">
                                    <label htmlFor="dd-city" className="labeltext">Dimension 4</label>
                                    <Dropdown value={selectedoptions3} onChange={(e) => setSelectedoptions3(e.value)} options={DynmaicOptions3} optionLabel="name"
                                        placeholder="Gender" className="w-full md:w-14rem dimension_dropdown" />
                                </div>
                                {/* <div className='text-[#fff] text-xs w-1'><Link><i class="pi pi-ellipsis-v"></i></Link></div> */}
                            </div>
                        </div>
                        {
                            STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata.length > 0 ?
                                <p className="m-0 text-white flex items-center justify-start">
                                    {console.log("DarkMode", DarkMode)}
                                    {/* <ReactEcharts option={drilldown} style={{ width: '70%',height:'500%' }} /> */}
                                    <TreeMapEcharts
                                        handleTreeOnClick={handleTreeOnClick}
                                        data={STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTdata}
                                        nestedFields={nestedFields} valueFields={valueFields}
                                        // loading={ STRENGTH_AND_WEAKNESS_DRILL_DOWN_CHARTloading }
                                        loading={false}
                                        DarkMode={DarkMode}
                                        labelColor={DarkMode ? '#fff' : '#000'}
                                    //  shareFields={shareFields}
                                    />
                                </p> :
                                <p className="m-0 text-white flex items-center justify-center">
                                    <div style={{ height: "12rem", position: "relative", top: "5rem" }}>No Data</div>
                                </p>

                        }

                    </Card>
                </div>
            </LoaderContainer>
            <div class="footer flex justify-between p-2 ">
                <div class="flex mt-2">
                    {/* <div class="mx-5 mt-2">Conditions of Use</div>
                    <div class="mx-5 mt-2">Privacy Notice</div>
                    <div class="mx-5 mt-2">@2024 Greece | All right reserved</div> */}
                </div>
                <div class="mr-3">
                    <img src={Complogo} height="50px" width="130px" alt="logo" className="mr-[2vw]" />
                </div>
            </div>
        </div>
    )
}