import React from 'react'
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import "./index.css";
import LoginFrame from '../../assets/images/loginLogo.jpg';
import Loginlogo from '../../assets/brands/logo.png';
import googleLogo from '../../assets/images/googleLogo.png';
import { useState } from 'react';
import GreeceLogo from '../../assets/images/greece.png';
import LandingFrame from '../../assets/images/GroupFrame.png';
import Complogo from '../../assets/images/k12logo.png';
import GreeceLogo2 from '../../assets/images/Greece Logo 2.png'


  


const LogIn = (props) => { 
 

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    // Add code here to handle form submission (e.g. sending login credentials to server)
  }
  
  

  
  return (
    <>
      <div className='grid grid-cols-12 loginLeft p-0 logindashboard' style={{ width: "100vw", height: '100vh' }}>
        <div className='col-span-6 flex'>
          <div className='loginmain_div'>
            <img className='Loginlogo mb-[2rem]' src={GreeceLogo2} />
            {/* <img className='Loginlogo mb-[2rem]' src={GreeceLogo} /> */}
            <h1 className='login_title'>Welcome To Greece<br></br>Executive Dashboard</h1>
            <h4 className='login_titlebottom mb-3 mt-3'>Empowering School Districts with Comprehensive Insights</h4>
            <img className='landingFrame' src={LandingFrame} />
          </div>
        </div>
        <div className='col-span-6'>
          <div className='login_action_div'>
            <p className='loginName'>Login</p>
            <p className='loginDes'>Provide your credentials to proceed, Please.</p>
            <div className="logoSignIn my-4">
              <button className="login-button-azure" onClick={() => { props.login() }}>
                <img src={googleLogo} className="googleLogo is-mar-right-2 " />
                <span className="sigin-text">Sign in with Google</span>
              </button>
            </div>
          </div>
        </div>
        <img className='complogo' src={Complogo} />
      </div>
    </>
  )
}
export default LogIn