import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getNetSales } from '../services/user'

const initialState = {
  Student_Characteristicsloading: false,
  Student_Characteristics: [],

  // Enrollmentloading: false,
  // Enrollment: [],

  School_And_Centersloading: false,
  School_And_Centers: [],

  Student_Grouploading: false,
  Student_Group: [],

  Student_Enrollment_By_Yearloading: false,
  Student_Enrollment_By_Year: [],

  Student_Enrollment_By_Gradeloading: false,
  Student_Enrollment_By_Grade: [],

  Student_Enrollment_Reportloading: false,
  Student_Enrollment_Report: [],

  At_A_Glance_Top_Tileloading: false,
  At_A_Glance_Top_Tile: [],

  Schools_Centers_Reportloading: false,
  Schools_Centers_Report: [],

  Authenticate_Usersloading: false,
  Authenticate_Users: [],

  TeacherFTEloading: false,
  TeacherFTE: [],

  TotalTeacherFTEloading: false,
  TotalTeacherFTE: [],
  
  StudentTeacherRatioloading: false,
  StudentTeacherRatio: [],
}

export const fetchStudent_Characteristics = createAsyncThunk(
  'fetchStudent_Characteristics',
  async (Student_Characteristics, thunkAPI) => {
    Student_Characteristics = { ...Student_Characteristics, elasticQueryName: "Student_Characteristics" }
    const response = await getNetSales(Student_Characteristics);
    return response.data
  }
)

export const fetchSchool_And_Centers = createAsyncThunk(
  'fetchSchool_And_Centers',
  async (School_And_Centers, thunkAPI) => {
    School_And_Centers = { ...School_And_Centers, elasticQueryName: "School_And_Centers" }
    const response = await getNetSales(School_And_Centers);
    return response.data
  }
)

export const fetchStudent_Group = createAsyncThunk(
  'fetchStudent_Group',
  async (Student_Group, thunkAPI) => {
    Student_Group = { ...Student_Group, elasticQueryName: "Student_Group" }
    const response = await getNetSales(Student_Group);
    return response.data
  }
)

export const fetchStudent_Enrollment_By_Year = createAsyncThunk(
  'fetchStudent_Enrollment_By_Year',
  async (Student_Enrollment_By_Year, thunkAPI) => {
    Student_Enrollment_By_Year = { ...Student_Enrollment_By_Year, elasticQueryName: "Student_Enrollment_By_Year" }
    const response = await getNetSales(Student_Enrollment_By_Year);
    return response.data
  }
)

export const fetchStudent_Enrollment_By_Grade = createAsyncThunk(
  'fetchStudent_Enrollment_By_Grade',
  async (Student_Enrollment_By_Grade, thunkAPI) => {
    Student_Enrollment_By_Grade = { ...Student_Enrollment_By_Grade, elasticQueryName: "Student_Enrollment_By_Grade" }
    const response = await getNetSales(Student_Enrollment_By_Grade);
    return response.data
  }
)


export const fetchStudent_Enrollment_Report = createAsyncThunk(
  'fetchStudent_Enrollment_Report',
  async (Student_Enrollment_Report, thunkAPI) => {
    Student_Enrollment_Report = { ...Student_Enrollment_Report, elasticQueryName: "Student_Enrollment_Report" }
    const response = await getNetSales(Student_Enrollment_Report);
    return response.data
  }
)

export const fetchAt_A_Glance_Top_Tile = createAsyncThunk(
  'fetchAt_A_Glance_Top_Tile',
  async (At_A_Glance_Top_Tile, thunkAPI) => {
    At_A_Glance_Top_Tile = { ...At_A_Glance_Top_Tile, elasticQueryName: "At_A_Glance_Top_Tile" }
    const response = await getNetSales(At_A_Glance_Top_Tile);
    return response.data
  }
)

export const fetchSchools_Centers_Report = createAsyncThunk(
  'fetchSchools_Centers_Report',
  async (Schools_Centers_Report, thunkAPI) => {
    Schools_Centers_Report = { ...Schools_Centers_Report, elasticQueryName: "Schools_Centers_Report" }
    const response = await getNetSales(Schools_Centers_Report);
    return response.data
  }
)

export const fetchAuthenticate_Users = createAsyncThunk(
  'fetchAuthenticate_Users',
  async (Authenticate_Users, thunkAPI) => {
    Authenticate_Users = { ...Authenticate_Users, elasticQueryName: "Authenticate_Users" }
    const response = await getNetSales(Authenticate_Users);
    return response.data
  }
)

export const fetchTeacherFTE = createAsyncThunk(
  'fetchTeacherFTE',
  async (TeacherFTE, thunkAPI) => {
    TeacherFTE = { ...TeacherFTE, elasticQueryName: "Teacher FTE" }
    const response = await getNetSales(TeacherFTE);
    return response.data
  }
)

export const fetchTotalTeacherFTE = createAsyncThunk(
  'fetchTotalTeacherFTE',
  async (TotalTeacherFTE, thunkAPI) => {
    TotalTeacherFTE = { ...TotalTeacherFTE, elasticQueryName: "Total Teacher FTE" }
    const response = await getNetSales(TotalTeacherFTE);
    return response.data
  }
)

export const fetchStudentTeacherRatio = createAsyncThunk(
  'fetchStudentTeacherRatio',
  async (StudentTeacherRatio, thunkAPI) => {
    StudentTeacherRatio = { ...StudentTeacherRatio, elasticQueryName: "Student/Teacher Ratio" }
    const response = await getNetSales(StudentTeacherRatio);
    return response.data
  }
)



export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStudent_Characteristics.fulfilled, (state, action) => {
      state.Student_Characteristics = action.payload;
      state.Student_Characteristicsloading = false;
    }).addCase(fetchStudent_Characteristics.pending, (state, action) => {
      state.Student_Characteristicsloading = true;
    })

    builder.addCase(fetchSchool_And_Centers.fulfilled, (state, action) => {
      state.School_And_Centers = action.payload;
      state.School_And_Centersloading = false;
    }).addCase(fetchSchool_And_Centers.pending, (state, action) => {
      state.School_And_Centersloading = true;
    })

    builder.addCase(fetchStudent_Group.fulfilled, (state, action) => {
      state.Student_Group = action.payload;
      state.Student_Grouploading = false;
    }).addCase(fetchStudent_Group.pending, (state, action) => {
      state.Student_Grouploading = true;
    })

    builder.addCase(fetchStudent_Enrollment_By_Year.fulfilled, (state, action) => {
      state.Student_Enrollment_By_Year = action.payload;
      state.Student_Enrollment_By_Yearloading = false;
    }).addCase(fetchStudent_Enrollment_By_Year.pending, (state, action) => {
      state.Student_Enrollment_By_Yearloading = true;
    })

    builder.addCase(fetchStudent_Enrollment_By_Grade.fulfilled, (state, action) => {
      state.Student_Enrollment_By_Grade = action.payload;
      state.Student_Enrollment_By_Gradeloading = false;
    }).addCase(fetchStudent_Enrollment_By_Grade.pending, (state, action) => {
      state.Student_Enrollment_By_Gradeloading = true;
    })

    builder.addCase(fetchStudent_Enrollment_Report.fulfilled, (state, action) => {
      state.Student_Enrollment_Report = action.payload;
    }).addCase(fetchStudent_Enrollment_Report.pending, (state, action) => {
      state.Student_Enrollment_Reportloading = true;
    })

    builder.addCase(fetchAt_A_Glance_Top_Tile.fulfilled, (state, action) => {
      state.At_A_Glance_Top_Tile = action.payload;
      state.At_A_Glance_Top_Tileloading = false;
    }).addCase(fetchAt_A_Glance_Top_Tile.pending, (state, action) => {
      state.At_A_Glance_Top_Tileloading = true;
    })

    builder.addCase(fetchSchools_Centers_Report.fulfilled, (state, action) => {
      state.Schools_Centers_Report = action.payload;
    }).addCase(fetchSchools_Centers_Report.pending, (state, action) => {
      state.At_A_Glance_Top_Tileloading = true;
    })


    builder.addCase(fetchAuthenticate_Users.fulfilled, (state, action) => {
      state.Authenticate_Users = action.payload;
      state.Authenticate_Usersloading = false;
    }).addCase(fetchAuthenticate_Users.pending, (state, action) => {
      state.Authenticate_Usersloading = true;
    })
    
    builder.addCase(fetchTeacherFTE.fulfilled, (state, action) => {
      state.TeacherFTE = action.payload;
      state.TeacherFTEloading = false;
    }).addCase(fetchTeacherFTE.pending, (state, action) => {
      state.TeacherFTEloading = true;
    })

    builder.addCase(fetchTotalTeacherFTE.fulfilled, (state, action) => {
      state.TotalTeacherFTE = action.payload;
      state.TotalTeacherFTEloading = false;
    }).addCase(fetchTotalTeacherFTE.pending, (state, action) => {
      state.TotalTeacherFTEloading = true;
    })

    builder.addCase(fetchStudentTeacherRatio.fulfilled, (state, action) => {
      state.StudentTeacherRatio = action.payload;
      state.StudentTeacherRatioloading = false;
    }).addCase(fetchStudentTeacherRatio.pending, (state, action) => {
      state.StudentTeacherRatioloading = true;
    })
  }

})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer