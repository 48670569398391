

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import ArrowUp from "../../assets/images/arrow-up.png"
import ArrowDown from "../../assets/images/arrow-down.png"
import EnrollmentImg from "../../assets/images/Enrollment_banner.jpeg";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudent_Enrollment_By_Year,
  fetchStudent_Enrollment_By_Grade, fetchStudent_Enrollment_Report
} from '../../redux/slices/counter';
import LoaderContainer from "../../components/loaderContainer";

export default function Enrollment() {
  const dispatch = useDispatch();
  const Student_Enrollment_By_Yeardata = useSelector(state => state.netsales.Student_Enrollment_By_Year);
  const Student_Enrollment_By_Yeardataloading = useSelector(state => state.netsales.Student_Enrollment_By_Yearloading);
  const Student_Enrollment_By_Gradedata = useSelector(state => state.netsales.Student_Enrollment_By_Grade);
  const Student_Enrollment_By_Gradeloading = useSelector(state => state.netsales.Student_Enrollment_By_Gradeloading);
  const Student_Enrollment_Reportdata = useSelector(state => state.netsales.Student_Enrollment_Report);
  const DarkMode = useSelector((state) => state.global.DarkMode);

  // const At_A_Glance_Top_Tiledata = useSelector(state => state.netsales.At_A_Glance_Top_Tile);
  console.log("Student_Enrollment_Reportdata", Student_Enrollment_Reportdata)
  // console.log("At_A_Glance_Top_Tiledata",At_A_Glance_Top_Tiledata)
  var Student_Enrollment_By_Gradedatasort;
  if (Student_Enrollment_By_Gradedata) {
    Student_Enrollment_By_Gradedatasort = Student_Enrollment_By_Gradedata.slice().sort((a, b) => {
      return a.GRADE_SORT_ORDER - b.GRADE_SORT_ORDER
    })
  }

  const[screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
  const handleResize = () => {
  if (typeof window !== 'undefined') {
      const screenWidth = window.innerWidth;
      setScreenWidth(screenWidth);
      console.log(screenWidth,"setScreenWidth");
      }
  };
  window.addEventListener('resize', handleResize);
  handleResize();
  return () => {
  window.removeEventListener('resize', handleResize);
  };
  }, []);

  useEffect(() => {
    dispatch(fetchStudent_Enrollment_By_Year({
      "elasticQueryName": "Student_Enrollment_By_Year",
      "filters": [
      ],
      "dynamicColumns": [],
    }));
    dispatch(fetchStudent_Enrollment_By_Grade({
      "elasticQueryName": "Student_Enrollment_By_Grade",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2024"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
    dispatch(fetchStudent_Enrollment_Report({
      "elasticQueryName": "Student_Enrollment_Report",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2024"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
    // dispatch(fetchAt_A_Glance_Top_Tile({
    //   "elasticQueryName": "At_A_Glance_Top_Tile",
    //   "filters": [
    //   ],
    //   "dynamicColumns": [],
    // }));
  }, [])

  const StudentEnrollmentbyGrade = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: DarkMode ? "black" : "white",
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: DarkMode ? "white" : "black",
      },
      axisPointer: {
        type: 'shadow'
      }

    },
    grid: {
      tooltip: {
        padding: [100, 100, 100, 100],
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderColor: "#333",
        color: DarkMode ? "white" : "black"

      }
    },
    // title: {
    //   text: 'Student Enrollment by Grade',
    //   left: 5,
    //   textStyle: {
    //     fontSize: 14,
    //     fontWeight: 400,
    //     color: '#fff',

    //   },
    // },

    grid: {
      left: '5%',
      right: '1%',
      bottom: '5%',
      top: "-50%",
      containLabel: true
    },
    yAxis: [
      {
        type: 'value',
        axisLine: {
          show: false,
          lineStyle: {
            color: DarkMode ? "white" : "black"
          }
        },
        min: 0,
        max: 2000,
        interval: 1000,
        axisLabel: {
          show: false,
          color: '#fff'
        },
        splitLine: {
          show: false,
          lineStyle: {
            show: false,
            type: "dashed"
          },
        },
      },
    ],
    xAxis: {
      type: 'category',
      data: Student_Enrollment_By_Gradedatasort.map(itr => itr.KEY === null ? '' : itr.KEY),

      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#E0E0E0'
        }
      },
      axisLabel: {
        color: DarkMode ? "white" : "black",
        // rotate:-45,
        fontSize: 10,
        interval: 0,
        rotate:50,
      },
    },
    series: [
      {
        // name: 'Revenue 2021',
        type: 'bar',
        stack: 'Profit',
        barWidth: "10px",
        color: '#2c4089',

        data: Student_Enrollment_By_Gradedatasort.map(itr => itr.KEY === null ? '' : itr.GROUPBY_METRIC_NUMERATOR),
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          normal: {
            barBorderRadius: [2, 2, 2, 2],
          }
        }
      },

      // {
      //   name: 'Revenue 2022',
      //   type: 'line',
      //   stack: 'Expenses 2022',
      //   color: '#FFA600',
      //   symbolSize: 0,
      //   // data: [21000, 20000, 21000, 23000, 20000, 23000, 26000,24000,25000],
      //   // lineStyle: {
      //   //   width: 3,
      //   // },
      // },

    ]
  };

  const StudentEnrollmentbyYear = {
    // title: {
    //   text: 'Student Enrollment by Year',
    //   left: 10,
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    // },
    color: ['#892c69'],
    tooltip: {
      backgroundColor: DarkMode ? "black" : "white",
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: DarkMode ? "white" : "black",
      },
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          type: "line"
        }
      }
    },
    grid: {
      tooltip: {
        padding: [100, 100, 100, 100],
        backgroundColor: DarkMode ? "black" : "white",
        borderColor: "#333",
        color: DarkMode ? "white" : "black"
      },
      // '-25%',
      right: '0%',
      bottom: '25%',
      // top: '-15%',
      // width: '150%',
      left: '0%',
      height : "80%"
    },

    yAxis: [
      {
        type: 'value',
        show: false,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#E0E0E0'
          }
        },
        axisLabel: {
          show: false,
          color: '#4F4F4F'
        },
        splitLine: {
          lineStyle: {
            show: false,
            type: "dashed"
          },
        },
        min: 0,
        max: 25000,
        interval: 5000,
      },
    ],
    xAxis: {
      type: 'category',
      data: Student_Enrollment_By_Yeardata.map(itr => itr.LABEL),
      position: "bottom",
      // offset: -30,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#E0E0E0'
        }
      },
      axisLabel: {
        inside: false,
        color: DarkMode ? "white" : "black",
        fontSize: 10
      },
      z: 10
    },
    series: [
      // ?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      {
        data: Student_Enrollment_By_Yeardata.map(itr => itr.VALUE),
        type: 'line',
        areaStyle: {},
        label: {
          show: true,
          position: 'top',
          fontSize: 10,
          textShadowBlur: false,
          color: DarkMode ? "white" : "black",
          "formatter": function (params) {
            return params.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          },
        },

      },
    ]
  };
  // #1d192f9c
  // bg-[#00000080] 
  return (
    <div className="EnrollmentTile">
      <div className="relative h-[130px]">
        <div className=" h-[130px] ">
          <img src={EnrollmentImg} className="h-[130px] w-full " />
        </div>
        <div className="w-full absolute bottom-0 h-[27px] bg-[#1d192f9c] py-1 px-3">
          <h3 className="text-[15px] text-[#fff] font-[400]">Enrollment</h3>
        </div>
      </div>
      <div className="mt-1  flex ml-[0.5vw] ">
        <div className="">
          <p className="text-[14px] font-[400] ">Total</p>
          <div className="flex">

            <h4 className="text-[16px] font-semibold">{Student_Enrollment_Reportdata[2]?.METRIC_NUMERATOR?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
            <div className="ml-[5px] mt-[2px]">

              {
                Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE >= 0 ? <div className="smallarrowbtn" style={{ fontSize: "12px", fontWeight: 550 }}>
                  {Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE?.toFixed(1)}%
                  <img src={ArrowUp} className="h-2.5 w-2.5" />
                </div> :
                  <div className="smallarrowbtndown" style={{ fontSize: "12px", fontWeight: 550 }}>
                    {Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE?.toFixed(1)}%
                    <img src={ArrowDown} className="h-2.5 w-2.5" />
                  </div>
              }
            </div>

          </div>

        </div>



        {/* <div className="d-flex">
          <p className="mb-[5px] font-[500]" style={{ fontSize: "16px" }}>Last Year Variance Comparison</p>
          <div style={{ position: "relative" }}>
            {
              Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE >= 0 ? <div className="smallarrowbtn" style={{ fontSize: "12px", fontWeight: 550 }}>
                {Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE?.toFixed(1)}%
                <img src={ArrowUp} className="h-2.5 w-2.5" />
              </div> :
                <div className="smallarrowbtndown" style={{ fontSize: "12px", fontWeight: 550 }}>
                  {Student_Enrollment_Reportdata[2]?.GROUPBY_VARIANCE?.toFixed(1)}%
                  <img src={ArrowDown} className="h-2.5 w-2.5" />
                </div>
            }

          </div>
        </div> */}
      </div>
      <div className="grid grid-cols-2 gap-4 p-2 mt-1 mb-0 border-t  border-[#ffffff26]">
        <div className="flex-col">
          <p className="text-[14px] font-[400] leading-5">New</p>
          <p className="text-[16px] font-semibold leading-6">{Student_Enrollment_Reportdata[0]?.METRIC_NUMERATOR?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </div>
        <div className="flex-col">
          <p className="text-[14px] font-[400] leading-5">Dropout</p>
          <p className="text-[16px] font-semibold leading-6">{Student_Enrollment_Reportdata[1]?.METRIC_NUMERATOR?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </div>
      </div>
      <div className="border-t  border-[#ffffff26]">
        <div className="m-2">
          <LoaderContainer loading={Student_Enrollment_By_Gradeloading}>
          <div className="EnrollmentTitle text-[14px] font-[400] text-left mx-0">Student Enrollment by Grade</div>
          <div className="w-full flex items-center justify-center student_enrollmentbygrade">
          <ReactEcharts style={{ width:screenWidth <= 1650 && screenWidth >= 865  ? 250 : 320, height: screenWidth <= 1450 && screenWidth >= 865  ? 80 : 100}} option={StudentEnrollmentbyGrade} />
          </div>
          </LoaderContainer>
        </div>
      </div>
      <div className="border-t  border-[#ffffff26]">
        <div className="StudentEnrollmentbyYear mt-0 mx-2">
          <LoaderContainer loading={Student_Enrollment_By_Yeardataloading}>
          <div className="EnrollmentTitle  text-[14px] font-[400] text-left mx-0 my-2">Student Enrollment by Year</div>
          <div className="w-full flex items-center justify-center student_enrollmentbyyear">
          <ReactEcharts style={{ width: 320, height: screenWidth <= 1450 && screenWidth >= 865  ? 90 : 100 }} option={StudentEnrollmentbyYear} />
          </div>
          </LoaderContainer>
        </div>
      </div>
    </div>
  )
}